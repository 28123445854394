import React, { useState } from "react";
import { Col, Form, Row,InputGroup } from "react-bootstrap";

const ThemeSpecificVariable = () => {
  const [hideHeaderSection, setHideHeaderSection] = useState(false);
  const [secondaryBackgroundColor, setSecondaryBackgroundColor] =
    useState("#813636");
  const [thirdBackgroundColor, setThirdBackgroundColor] = useState("#813636");
  const [backgroundImageOverlay, setBackgroundImageOverlay] =
    useState("#fffafa");
  const [inputIconBackground, setInputIconBackground] = useState("#fffafa");
  const [inputIconColor, setInputIconColor] = useState("#fffafa");
  const [headerLogoWidth, setHeaderLogoWidth] = useState("200px");
  const [headerLogoHeight, setHeaderLogoHeight] = useState("70px");
  const [headerLogo, setHeaderLogo] = useState("");

  const handleLogoUpload = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setHeaderLogo(imageURL);
    }
  };

  return (
    <>
      <Row className="mb-3">
        <Col>
          <div
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Label>Hide Header Section (Theme E, F)</Form.Label>
            <div>
              <Form.Check
                type="switch"
                id="hideHeaderSection"
                name="hideHeaderSection"
                checked={hideHeaderSection}
                onChange={(e) => setHideHeaderSection(e.target.checked)}
              />
            </div>
          </div>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={12}>
              Secondary Background Color (for Ribbon) (Themes G, H, I)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={secondaryBackgroundColor}
                  name="secondaryBackgroundColor"
                  onChange={(e) => setSecondaryBackgroundColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={secondaryBackgroundColor}
                  onChange={(e) => setSecondaryBackgroundColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={12}>
              Third Background Color (for Ribbon) (Themes H, I)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={thirdBackgroundColor}
                  name="thirdBackgroundColor"
                  onChange={(e) => setThirdBackgroundColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={thirdBackgroundColor}
                  onChange={(e) => setThirdBackgroundColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={12}>
              Background Image Overlay (has to be transparent) (Theme A)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={backgroundImageOverlay}
                  name="backgroundImageOverlay"
                  onChange={(e) => setBackgroundImageOverlay(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={backgroundImageOverlay}
                  onChange={(e) => setBackgroundImageOverlay(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={12}>
              Input Icon Background (Theme J)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={inputIconBackground}
                  name="inputIconBackground"
                  onChange={(e) => setInputIconBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={inputIconBackground}
                  onChange={(e) => setInputIconBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={12}>
              Input Icon Color (Themes I, J)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={inputIconColor}
                  name="inputIconColor"
                  onChange={(e) => setInputIconColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={inputIconColor}
                  onChange={(e) => setInputIconColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={12}>
              Header Logo Width (Themes D, E)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <Form.Control
                  type="text"
                  name="headerLogoWidth"
                  value={headerLogoWidth}
                  onChange={(e) => setHeaderLogoWidth(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={12}>
              Header Logo Height (Themes D, E)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <Form.Control
                  type="text"
                  name="headerLogoHeight"
                  value={headerLogoHeight}
                  onChange={(e) => setHeaderLogoHeight(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
      <Col>
        <Form.Group
         as={Row}
         style={{
           border: "1px solid #ccc",
           padding: "10px",
           borderRadius: "5px",
           margin: "10px",
         }}
        >
          <h3 className="fs-6">Header Logo</h3>
          <div className="custom-file-container">
            <Form.Label htmlFor="headerLogo" className="cursor-pointer">
              Upload
            </Form.Label>
            <Form.Control
              type="file"
              id="headerLogo"
              name="headerLogo"
              accept="image/*"
              className="bg-transparent border-none outline-none"
              onChange={handleLogoUpload}
            />
          </div>
          <InputGroup className="mt-3">
            <InputGroup.Text>URL</InputGroup.Text>
            <Form.Control
              name="headerLogo"
              value={headerLogo}
              onChange={(e) => setHeaderLogo(e.target.value)}
              aria-label="Header Logo URL"
            />
          </InputGroup>
        </Form.Group>
      </Col>
      </Row>
    </>
  );
};

export default ThemeSpecificVariable;
