import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

const MainLoginColors = () => {
  const [backgroundColor, setBackgroundColor] = useState("#813636");
  const [loginBoxBackground, setLoginBoxBackground] = useState("#813636");
  const [loginHeadingColor, setLoginHeadingColor] = useState("#813636");
  const [inputBackground, setInputBackground] = useState("#813636");
  const [inputBorder, setInputBorder] = useState("#813636");
  const [inputColor, setInputColor] = useState("#813636");
  const [errorColor, setErrorColor] = useState("#813636");
  const [forgotPasswordColor, setForgotPasswordColor] = useState("#813636");
  const [loginButtonBackground, setLoginButtonBackground] = useState("#813636");
  const [loginButtonColor, setLoginButtonColor] = useState("#813636");
  const [loginButtonBackgroundHover, setLoginButtonBackgroundHover] =
    useState("#813636");
  const [loginButtonColorHover, setLoginButtonColorHover] = useState("#813636");
  const [footerNoteColor, setFooterNoteColor] = useState("#813636");
  const [footerNoteLinkColor, setFooterNoteLinkColor] = useState("#813636");
  const [submitButtonRoundedBorders, setSubmitButtonRoundedBorders] =
    useState("30px");
  const [blackCheckMark, setBlackCheckMark] = useState(false);

  return (
    <>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={4}>
              Background Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={backgroundColor}
                  name="backgroundColor"
                  onChange={(e) => setBackgroundColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={backgroundColor}
                  onChange={(e) => setBackgroundColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={4}>
              Login Box Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={loginBoxBackground}
                  name="loginBoxBackground"
                  onChange={(e) => setLoginBoxBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={loginBoxBackground}
                  onChange={(e) => setLoginBoxBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={6}>
              Login Heading Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={loginHeadingColor}
                  name="loginHeadingColor"
                  onChange={(e) => setLoginHeadingColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={loginHeadingColor}
                  onChange={(e) => setLoginHeadingColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={4}>
              Input Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={inputBackground}
                  name="inputBackground"
                  onChange={(e) => setInputBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={inputBackground}
                  onChange={(e) => setInputBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={6}>
              Input Border
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={inputBorder}
                  name="inputBorder"
                  onChange={(e) => setInputBorder(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={inputBorder}
                  onChange={(e) => setInputBorder(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={4}>
              Input Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={inputColor}
                  name="inputColor"
                  onChange={(e) => setInputColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={inputColor}
                  onChange={(e) => setInputColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={6}>
              Error Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={errorColor}
                  name="errorColor"
                  onChange={(e) => setErrorColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={errorColor}
                  onChange={(e) => setErrorColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={6}>
              Forgot Password Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={forgotPasswordColor}
                  name="forgotPasswordColor"
                  onChange={(e) => setForgotPasswordColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={forgotPasswordColor}
                  onChange={(e) => setForgotPasswordColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={6}>
              Login Button Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={loginButtonBackground}
                  name="loginButtonBackground"
                  onChange={(e) => setLoginButtonBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={loginButtonBackground}
                  onChange={(e) => setLoginButtonBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={6}>
              Login Button Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={loginButtonColor}
                  name="loginButtonColor"
                  onChange={(e) => setLoginButtonColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={loginButtonColor}
                  onChange={(e) => setLoginButtonColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={6}>
              Login Button Background (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={loginButtonBackgroundHover}
                  name="loginButtonBackgroundHover"
                  onChange={(e) =>
                    setLoginButtonBackgroundHover(e.target.value)
                  }
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={loginButtonBackgroundHover}
                  onChange={(e) =>
                    setLoginButtonBackgroundHover(e.target.value)
                  }
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={6}>
              Login Button Color (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={loginButtonColorHover}
                  name="loginButtonColorHover"
                  onChange={(e) => setLoginButtonColorHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={loginButtonColorHover}
                  onChange={(e) => setLoginButtonColorHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={6}>
              Footer Note Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={footerNoteColor}
                  name="footerNoteColor"
                  onChange={(e) => setFooterNoteColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={footerNoteColor}
                  onChange={(e) => setFooterNoteColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={6}>
              Footer Note Link Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={footerNoteLinkColor}
                  name="footerNoteLinkColor"
                  onChange={(e) => setFooterNoteLinkColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={footerNoteLinkColor}
                  onChange={(e) => setFooterNoteLinkColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={6}>
              Submit Button Rounded Borders
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <Form.Control
                  type="text"
                  name="submitButtonRoundedBorders"
                  value={submitButtonRoundedBorders}
                  onChange={(e) =>
                    setSubmitButtonRoundedBorders(e.target.value)
                  }
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={6}>
              Footer Note Link Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={footerNoteLinkColor}
                  name="footerNoteLinkColor"
                  onChange={(e) => setFooterNoteLinkColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={footerNoteLinkColor}
                  onChange={(e) => setFooterNoteLinkColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Label>Black Check Mark</Form.Label>
            <div>
              <Form.Check
                type="switch"
                id="blackCheckMark"
                name="blackCheckMark"
                checked={blackCheckMark}
                onChange={(e) => setBlackCheckMark(e.target.checked)}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MainLoginColors;
