import React, { useState } from "react";
import {
  Accordion,
  Card,
  useAccordionButton,
  Form,
  Button,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DarkTheme from "./components/DarkTheme";
import CustomLogoForm from "./components/Logo";
import Dashboard from "./components/Dashboard";
import CardHeaders from "./components/CardHeaders";
import LocationSwitcher from "./components/LocationSwitcher";
import LeftSideBar from "./components/LeftSideBar";
import InsideWrapper from "./components/InsideWrapper";
import TopHeader from "./components/TopHeader";
import SecondaryTopNavigation from "./components/SecondaryTopNavigation";
import DatePicker from "./components/DatePicker";
import Buttons from "./components/Buttons";
import Tags from "./components/Tags";

const CustomizationPanel = () => {
  const [customization, setCustomization] = useState({
    DarkTheme: false,
    LocationSwitcherQuickActions: false,
    CustomLogoforLocation: false,
    Dashboard: false,
    LeftSideBar: false,
    InsideWrapper: false,
    TopHeader: false,
    SecondaryTopNavigation: false,
    CardHeaders: false,
    DatePicker: false,
    Buttons: false,
    Tags: false,
  });

  const [darkThemeEnabled, setDarkThemeEnabled] = useState(false);
  const [locationSwitcherEnabled, setLocationSwitcherEnabled] = useState(false);
  const [customLogoEnabled, setCustomLogoEnabled] = useState(false);
  const [dashboardEnabled, setDashboardEnabled] = useState(false);
  const [leftSidebarEnabled, setLeftSidebarEnabled] = useState(false);
  const [insideWrapperEnabled, setInsideWrapperEnabled] = useState(false);
  const [topHeaderEnabled, setTopHeaderEnabled] = useState(false);
  const [secondaryTopNavigationEnabled, setSecondaryTopNavigationEnabled] =
    useState(false);
  const [cardHeadersEnabled, setCardHeadersEnabled] = useState(false);
  const [datePickerEnabled, setDatePickerEnabled] = useState(false);
  const [buttonsEnabled, setButtonsEnabled] = useState(false);
  const [tagsEnabled, setTagsEnabled] = useState(false);

  const handleToggle = (feature) => {
    setCustomization((prevCustomization) => ({
      ...prevCustomization,
      [feature]: !prevCustomization[feature],
    }));

    if (feature === "DarkTheme") {
      setDarkThemeEnabled((prevDarkThemeEnabled) => !prevDarkThemeEnabled);
    } else if (feature === "LocationSwitcherQuickActions") {
      setLocationSwitcherEnabled(
        (prevLocationSwitcherEnabled) => !prevLocationSwitcherEnabled
      );
    } else if (feature === "CustomLogoforLocation") {
      setCustomLogoEnabled((prevCustomLogoEnabled) => !prevCustomLogoEnabled);
    } else if (feature === "Dashboard") {
      setDashboardEnabled((prevDashboardEnabled) => !prevDashboardEnabled);
    } else if (feature === "LeftSideBar") {
      setLeftSidebarEnabled(
        (prevLeftSidebarEnabled) => !prevLeftSidebarEnabled
      );
    } else if (feature === "InsideWrapper") {
      setInsideWrapperEnabled(
        (prevInsideWrapperEnabled) => !prevInsideWrapperEnabled
      );
    } else if (feature === "TopHeader") {
      setTopHeaderEnabled((prevTopHeaderEnabled) => !prevTopHeaderEnabled);
    } else if (feature === "SecondaryTopNavigation") {
      setSecondaryTopNavigationEnabled(
        (prevSecondaryTopNavigationEnabled) =>
          !prevSecondaryTopNavigationEnabled
      );
    } else if (feature === "CardHeaders") {
      setCardHeadersEnabled(
        (prevCardHeadersEnabled) => !prevCardHeadersEnabled
      );
    } else if (feature === "DatePicker") {
      setDatePickerEnabled((prevDatePickerEnabled) => !prevDatePickerEnabled);
    } else if (feature === "Buttons") {
      setButtonsEnabled((prevButtonsEnabled) => !prevButtonsEnabled);
    } else if (feature === "Tags") {
      setTagsEnabled((prevTagsEnabled) => !prevTagsEnabled);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Dashboad Customization
    const dashboardEnable = customization.DarkTheme;
    const dashboardCustomization = customization.Dashboard;
    const darkThemeToggle = e.target.darkThemeToggle.checked;
    const saasDashboardCards = e.target.saasDashboardCards.checked;
    const statusColor = e.target.statusColor.value;
    const cardValueBackground = e.target.cardValueBackground.value;
    const cardValueColor = e.target.cardValueColor.value;
    const closedStatsBackground = e.target.closedStatsBackground.value;
    const closedStatsColor = e.target.closedStatsColor.value;
    const openStatsBackground = e.target.openStatsBackground.value;
    const openStatsColor = e.target.openStatsColor.value;
    const lostStatsBackground = e.target.lostStatsBackground.value;
    const lostStatsColor = e.target.lostStatsColor.value;
    const wonStatsBackground = e.target.wonStatsBackground.value;
    const wonStatsColor = e.target.wonStatsColor.value;
    const statsValueBackground = e.target.statsValueBackground.value;
    const statsValueColor = e.target.statsValueColor.value;
    const customButtonsBackground = e.target.customButtonsBackground.value;
    const customButtonsBackgroundHover =
      e.target.customButtonsBackgroundHover.value;
    const customButtonsBorder = e.target.customButtonsBorder.value;
    const customButtonsBorderHover = e.target.customButtonsBorderHover.value;
    const customButtonsColor = e.target.customButtonsColor.value;
    const customButtonsColorHover = e.target.customButtonsColorHover.value;

    // LocationSwitcher
    const locationSwitcherEnable = customization.LocationSwitcherQuickActions;
    const inputBackground = e.target.inputBackground.value;
    const inputBorder = e.target.inputBorder.value;
    const inputColor = e.target.inputColor.value;
    const quickActionbuttonBackground =
      e.target.quickActionbuttonBackground.value;
    const quickActionbuttonBorder = e.target.quickActionbuttonBorder.value;
    const quickActionbuttonHoverBackground =
      e.target.quickActionbuttonHoverBackground.value;

    // LogoCustomization
    const customLogoEnable = customization.CustomLogoforLocation;
    const customLogoURL = e.target.customLogoURL.value;
    const logoHeight = e.target.logoHeight.value;
    const logoWidth = e.target.logoWidth.value;

    // inside Wrapper
    const isideWrapperCustomization = customization.InsideWrapper;
    const appBackgroundColor = e.target.appBackgroundColor.value;
    const appBorderColor = e.target.appBorderColor.value;
    const headerTitleColor = e.target.headerTitleColor.value;
    const headerDescriptionColor = e.target.headerDescriptionColor.value;
    const tableHeaderTextColor = e.target.tableHeaderTextColor.value;
    const tableHeaderBackground = e.target.tableHeaderBackground.value;
    const quickActionbuttonHoverBorder =
      e.target.quickActionbuttonHoverBorder.value;
    const quickActionBlackBolt = e.target.quickActionBlackBolt.checked;
    const quickActionWhiteBolt = e.target.quickActionWhiteBolt.checked;
    const quickActionHoverBlackBolt =
      e.target.quickActionHoverBlackBolt.checked;
    const quickActionHoverWhiteBolt =
      e.target.quickActionHoverWhiteBolt.checked;

    // LeftSideBar Customization
    const leftSidebarEnable = customization.LeftSideBar;
    const sideBarBackground = e.target.sideBarBackground.value;
    const subHeadingColor = e.target.subHeadingColor.value;
    const backButtonBackground = e.target.backButtonBackground.value;
    const backButtonColor = e.target.backButtonColor.value;
    const menuColor = e.target.menuColor.value;
    const menuColorHover = e.target.menuColorHover.value;
    const menuBackground = e.target.menuBackground.value;
    const menuBackgroundHover = e.target.menuBackgroundHover.value;
    const menuIconBackground = e.target.menuIconBackground.value;
    const menuIconBackgroundHover = e.target.menuIconBackgroundHover.value;
    const menuDivider = e.target.menuDivider.value;
    const subMenuBackground = e.target.subMenuBackground.value;
    const subMenuBackgroundHover = e.target.subMenuBackgroundHover.value;
    const subMenuColor = e.target.subMenuColor.value;
    const subMenuColorHover = e.target.subMenuColorHover.value;
    const navbarTogglerBackground = e.target.navbarTogglerBackground.value;
    const navbarTogglerForeground = e.target.navbarTogglerForeground.value;
    const iconColorWhite = e.target.iconColorWhite.checked;
    const iconColorWhiteHover = e.target.iconColorWhiteHover.checked;
    const iconColorBlack = e.target.iconColorBlack.checked;
    const iconColorBlackHover = e.target.iconColorBlackHover.checked;

    // TopHeader Customization
    const topHeaderEnable = customization.TopHeader;
    const headerBackground = e.target.headerBackground.value;
    const headerControlButtonsBorder =
      e.target.headerControlButtonsBorder.value;
    const headerControlButtonsShadow =
      e.target.headerControlButtonsShadow.value;

    // SecondaryTopNavigation
    const secondaryTopNavigationEnable = customization.SecondaryTopNavigation;
    const insideTopMenuItemColor = e.target.insideTopMenuItemColor.value;
    const insideTopMenuItemActiveColor =
      e.target.insideTopMenuItemActiveColor.value;
    const insideTopMenuItemBorder = e.target.insideTopMenuItemBorder.value;
    const insideTopMenuItemActiveBorder =
      e.target.insideTopMenuItemActiveBorder.value;
    const insideTopMenuItemIconBackground =
      e.target.insideTopMenuItemIconBackground.value;
    const insideTopSubmenuItemColor = e.target.insideTopSubmenuItemColor.value;
    const insideTopSubmenuItemColorActive =
      e.target.insideTopSubmenuItemColorActive.value;

    const topHeaderCustomization = {
      topHeaderEnable: topHeaderEnable,
      headerBackground: headerBackground,
      headerControlButtonsBorder: headerControlButtonsBorder,
      headerControlButtonsShadow: headerControlButtonsShadow,
    };
    const SecondaryTopNavigationCustomization = {
      secondaryTopNavigationEnable: secondaryTopNavigationEnable,
      insideTopMenuItemColor: insideTopMenuItemColor,
      insideTopMenuItemActiveColor: insideTopMenuItemActiveColor,
      insideTopMenuItemBorder: insideTopMenuItemBorder,
      insideTopMenuItemActiveBorder: insideTopMenuItemActiveBorder,
      insideTopMenuItemIconBackground: insideTopMenuItemIconBackground,
      insideTopSubmenuItemColor: insideTopSubmenuItemColor,
      insideTopSubmenuItemColorActive: insideTopSubmenuItemColorActive,
    };

    //card Headers
    const cardHeadersEnable = customization.CardHeaders;
    const cardBackground = e.target.cardBackground.value;
    const cardColor = e.target.cardColor.value;
    const cardDatePickerBackground = e.target.cardDatePickerBackground.value;
    const cardDatePickerColor = e.target.cardDatePickerColor.value;

    // Date Picker
    const datePickerEnable = customization.DatePicker;
    const datePickerbackground = e.target.datePickerbackground.value;
    const datePickercolor = e.target.datePickercolor.value;
    const datePickerborder = e.target.datePickerborder.value;

    // buttons Customization
const buttonsCustomizationEnable=customization.Buttons;
    const primaryButtonsBackground = e.target.primaryButtonsBackground.value;
    const primaryButtonsBackgroundHover =
      e.target.primaryButtonsBackgroundHover.value;
    const primaryButtonsBorder = e.target.primaryButtonsBorder.value;
    const primaryButtonsBorderHover = e.target.primaryButtonsBorderHover.value;
    const primaryButtonsColor = e.target.primaryButtonsColor.value;
    const primaryButtonsColorHover = e.target.primaryButtonsColorHover.value;

    // tags Customization 
    const tagsBackground = e.target.tagsBackground.value;
    const tagsBorder = e.target.tagsBorder.value;
    const tagsColor = e.target.tagsColor.value;

    const tagsCustomization ={
      tagsBackground:tagsBackground,
      tagsBorder:tagsBorder,
      tagsColor:tagsColor
    }

    const DashboardCustomizations = {
      dashboardCustomization: dashboardCustomization,
      saasDashboardCards: saasDashboardCards,
      statusColor: statusColor,
      cardValueBackground: cardValueBackground,
      cardValueColor: cardValueColor,
      closedStatsBackground: closedStatsBackground,
      closedStatsColor: closedStatsColor,
      openStatsBackground: openStatsBackground,
      openStatsColor: openStatsColor,
      lostStatsBackground: lostStatsBackground,
      lostStatsColor: lostStatsColor,
      wonStatsBackground: wonStatsBackground,
      wonStatsColor: wonStatsColor,
      statsValueBackground: statsValueBackground,
      statsValueColor: statsValueColor,
      customButtonsBackground: customButtonsBackground,
      customButtonsBackgroundHover: customButtonsBackgroundHover,
      customButtonsBorder: customButtonsBorder,
      customButtonsBorderHover: customButtonsBorderHover,
      customButtonsColor: customButtonsColor,
      customButtonsColorHover: customButtonsColorHover,
    };
    const DarkThemeCustomizations = {
      dashboardEnable: dashboardEnable,
      darkThemeToggle: darkThemeToggle,
    };
    const LogoCustomizations = {
      customLogoEnable: customLogoEnable,
      customLogoURL: customLogoURL,
      logoHeight: logoHeight,
      logoWidth: logoWidth,
    };

    const LocationSwitcherCustomizations = {
      locationSwitcherEnable: locationSwitcherEnable,
      inputBackground: inputBackground,
      inputBorder: inputBorder,
      inputColor: inputColor,
      quickActionbuttonBackground: quickActionbuttonBackground,
      quickActionbuttonBorder: quickActionbuttonBorder,
      quickActionbuttonHoverBackground: quickActionbuttonHoverBackground,
      quickActionbuttonHoverBorder: quickActionbuttonHoverBorder,
      quickActionBlackBolt: quickActionBlackBolt,
      quickActionWhiteBolt: quickActionWhiteBolt,
      quickActionHoverBlackBolt: quickActionHoverBlackBolt,
      quickActionHoverWhiteBolt: quickActionHoverWhiteBolt,
    };

    const insideWrapperColorCustomization = {
      isideWrapperCustomization: isideWrapperCustomization,
      appBackgroundColor: appBackgroundColor,
      appBorderColor: appBorderColor,
      headerTitleColor: headerTitleColor,
      headerDescriptionColor: headerDescriptionColor,
      tableHeaderTextColor: tableHeaderTextColor,
      tableHeaderBackground: tableHeaderBackground,
    };

    const LeftSideBarCustomization = {
      leftSidebarEnable: leftSidebarEnable,
      sideBarBackground: sideBarBackground,
      subHeadingColor: subHeadingColor,
      backButtonBackground: backButtonBackground,
      backButtonColor: backButtonColor,
      menuColor: menuColor,
      menuColorHover: menuColorHover,
      menuBackground: menuBackground,
      menuBackgroundHover: menuBackgroundHover,
      menuIconBackground: menuIconBackground,
      menuIconBackgroundHover: menuIconBackgroundHover,
      menuDivider: menuDivider,
      subMenuBackground: subMenuBackground,
      subMenuBackgroundHover: subMenuBackgroundHover,
      subMenuColor: subMenuColor,
      subMenuColorHover: subMenuColorHover,
      navbarTogglerBackground: navbarTogglerBackground,
      navbarTogglerForeground: navbarTogglerForeground,
      iconColorWhite: iconColorWhite,
      iconColorWhiteHover: iconColorWhiteHover,
      iconColorBlack: iconColorBlack,
      iconColorBlackHover: iconColorBlackHover,
    };

    const cardHeaderCustomization = {
      cardHeadersEnable: cardHeadersEnable,
      cardBackground: cardBackground,
      cardColor: cardColor,
      cardDatePickerBackground: cardDatePickerBackground,
      cardDatePickerColor: cardDatePickerColor,
    };

    const datePickerCustomization = {
      datePickerEnable: datePickerEnable,
      datePickerbackground: datePickerbackground,
      datePickercolor: datePickercolor,
      datePickerborder: datePickerborder,
    };

    const ButtonCustomization = {
      buttonsCustomizationEnable:buttonsCustomizationEnable,
      primaryButtonsBackground: primaryButtonsBackground,
      primaryButtonsBackgroundHover: primaryButtonsBackgroundHover,
      primaryButtonsBorder: primaryButtonsBorder,
      primaryButtonsBorderHover: primaryButtonsBorderHover,
      primaryButtonsColor: primaryButtonsColor,
      primaryButtonsColorHover: primaryButtonsColorHover,
    };

    const payload = {
      DarkThemeCustomizations: DarkThemeCustomizations,
      LogoCustomizations: LogoCustomizations,
      LocationSwitcherCustomizations: LocationSwitcherCustomizations,
      insideWrapperColorCustomization: insideWrapperColorCustomization,
      DashboardCustomizations: DashboardCustomizations,
      LeftSideBarCustomization: LeftSideBarCustomization,
      topHeaderCustomization: topHeaderCustomization,
      SecondaryTopNavigationCustomization: SecondaryTopNavigationCustomization,
      cardHeaderCustomization: cardHeaderCustomization,
      datePickerCustomization: datePickerCustomization,
      ButtonCustomization: ButtonCustomization,
      tagsCustomization:tagsCustomization
    };
    console.log(payload, "payload");
  };

  const CustomToggle = ({ children, eventKey, value }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {});

    return (
      <Button
        type="button"
        onClick={decoratedOnClick}
        variant="text"
        style={{ width: '100%', textAlign: 'left', boxShadow: "none" ,outline:"none",border:"none"}}
      >
        {children}
      </Button>
    );
  };

  return (
    <div className="card p-3">
      <form action="" onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center p-3">
          <h6 className="fs-4">Side Navigation Theme Customization</h6>
          <Button type="submit">Save</Button>
        </div>
        <Accordion>
          {Object.entries(customization).map(([key, value], index) => (
            <Card key={key}>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <CustomToggle eventKey={String(index)} value={value}>
                {key.replace(/([A-Z])/g, " $1").trim()}
                </CustomToggle>
                <div className="d-flex align-items-center gap-2">
                  <Form.Check
                    type="switch"
                    id={`custom-switch-${index}`}
                    checked={value}
                    onChange={() => handleToggle(key)}
                    style={{ marginLeft: "10px" }}
                  />
                  <Button
                    size="sm"
                    style={{ float: "right" }}
                    variant={value ? "success" : "danger"}
                  >
                    {value ? "On" : "Off"}
                  </Button>
                </div>
              </Card.Header>
              {key === "DarkTheme" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <DarkTheme enabled={darkThemeEnabled} />
                </Accordion.Collapse>
              )}
              {key === "LocationSwitcherQuickActions" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <LocationSwitcher enabled={locationSwitcherEnabled} />
                </Accordion.Collapse>
              )}
              {key === "CustomLogoforLocation" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <CustomLogoForm enabled={customLogoEnabled} />
                </Accordion.Collapse>
              )}

              {key === "Dashboard" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <Dashboard enabled={dashboardEnabled} />
                </Accordion.Collapse>
              )}
              {key === "LeftSideBar" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <LeftSideBar enabled={leftSidebarEnabled} />
                </Accordion.Collapse>
              )}
              {key === "InsideWrapper" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <InsideWrapper enabled={insideWrapperEnabled} />
                </Accordion.Collapse>
              )}
              {key === "SecondaryTopNavigation" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <SecondaryTopNavigation
                    enabled={secondaryTopNavigationEnabled}
                  />
                </Accordion.Collapse>
              )}
              {key === "TopHeader" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <TopHeader enabled={topHeaderEnabled} />
                </Accordion.Collapse>
              )}

              {key === "CardHeaders" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <CardHeaders enabled={cardHeadersEnabled} />
                </Accordion.Collapse>
              )}
              {key === "DatePicker" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <DatePicker enabled={datePickerEnabled} />
                </Accordion.Collapse>
              )}
              {key === "Buttons" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <Buttons enabled={buttonsEnabled} />
                </Accordion.Collapse>
              )}
              {key === "Tags" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <Tags enabled={tagsEnabled} />
                </Accordion.Collapse>
              )}
            </Card>
          ))}
        </Accordion>
      </form>
    </div>
  );
};

export default CustomizationPanel;
