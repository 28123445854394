import React, { useEffect, useState } from "react";
import AppHeader from "../../AppHeader";
import { useAppServices } from "../../../hook/services";
import AgencyLevelMenu from "./components/Modals/AgencyLevelMenu";
import LocationLevelMenu from "./components/Modals/LocationLevelMenu";
import CustomLevelMenu from "./components/Modals/CustomLevelMenu";
import NewLevelModal from "./components/Modals/NewLevelModal";
import HeaderButtons from "./components/Modals/HeaderButtons";
import TopNavigationMenu from "./components/Modals/TopNavigationModal";
import MenuEditorModal from "./components/Modals/EditMenus";
import DashboardButtonModal from "./components/Modals/DashboardButtons";
import FeaturesModal from "./components/Modals/Features"
import DashboardModal from "./components/Modals/DashboardModal";
import QuickActionsModal from "./components/Modals/QuickActions";
import OpportunityTabsModal from "./components/Modals/Opportunity";
import TranslationModal from "./components/Modals/TranslationModal";
import SettingsModal from "./components/Modals/UserSettingsModal";
import LaunchpadModal from "./components/Modals/LaunchPad";
import ContactButtons from "./components/Modals/ContactButtons"
import LocationMenuEdits from "./components/Modals/LocationMenuEdits";
import NewLevelEditModal from "./components/Modals/NewLevelModal/Edit";
import UserLimitModal from "./components/Modals/UserLimitModal";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import SSOHandler from "../ssoHandler";
import Loader from "../../../components/Loader";

const SidnavCustomizer = () => {
  const AppService = useAppServices();
  const { SSO, checkSSO } = SSOHandler();
  const { app_id } = useParams()
  const [customLevel, setCustomLevel] = useState([]);
  const [headerButtons, setheaderButtons] = useState([]);
  const [agencyLevel, setagencyLevel] = useState({});
  const [locationLevel, setlocationLevel] = useState({});
  const [refresh, setrefresh] = useState(false);
  const [ghl_locations, setGhl_locations] = useState([]);
  const [sso_cred, setsso_cred] = useState({});
  const [app_data, setapp_data] = useState({});
  const [loader, setloader] = useState(true);
  const [main_ghl_locations, setMain_Ghl_locations] = useState([]);
  const getAppData = async () => {
    const { response } = await AppService.apps.filter({
      query: `app_id=${app_id}`,
    });
    if (response) {
      console.log(response.data)
      setapp_data(response.data)
      checkSSO(response.data)
    }
  }

  const getCustomizerData = async (companyId) => {
    const { response } = await AppService.sidenav_customizer_main_level.get({
      query: `companyId=${companyId}`,
    });
    console.log('responseresponse', response)
    if (response) {
      // console.log('responseresponse', response)
      setGhl_locations(response.ghl_locations.map((location) => ({
        ...location,
        label: location.name,
        value: location.id,
      })))
      const temp_agency_level = response.data.filter(function (item) {
        return item.type == "agency";
      });
      const temp_location_level = response.data.filter(function (item) {
        return item.type == "location";
      });
      console.log(temp_location_level[0], 'temp_location_level[0]')
      setagencyLevel(temp_agency_level[0] || {});
      setlocationLevel(temp_location_level[0] || {});
      setCustomLevel(response.customlevel);
      setMain_Ghl_locations(response.ghl_locations)
      setheaderButtons(response.header_buttons);
      const temp_ghl_locations = response.ghl_locations
      for (let index_c = 0; index_c < response.customlevel.length; index_c++) {
        const element_c = response.customlevel[index_c];
        for (let index_l = 0; index_l < temp_ghl_locations.length; index_l++) {
          const element = temp_ghl_locations[index_l];
          if (element_c.locations.filter((location) => location.id == element.id)[0]) {
            temp_ghl_locations.splice(index_l, 1);
          }

        }

      }
      // console.log(response.ghl_locations, 'response.ghl_locations')

      setloader(false)
    } else {
      setloader(false)
    }
  };
  const onLoad = async () => {
    if (sso_cred?.companyId) {
      getCustomizerData(sso_cred?.companyId)
    } else {

      getAppData();
    }
  };
  useEffect(() => {
    onLoad();
  }, []);
  useEffect(() => {
    if (SSO != '' && SSO != undefined) {
      var data = JSON.parse(SSO);
      if (data?.companyId) {
        getCustomizerData(data?.companyId)
        setsso_cred(data)
      }
      // console.log(data, 'datadata');

    }
    // console.log(SSO, 'SSOSSO');
  }, [SSO]);

  const saveMenu = async (menu, type) => {
    // console.log(menu, "menu");
    // console.log(type, "type");
    var activeType = type == "agency" ? agencyLevel : locationLevel;
    // console.log(activeType, "activeType");
    if (activeType?._id) {
      var menus =
        type == "agency"
          ? [...agencyLevel?.menus, menu]
          : [...locationLevel?.menus, menu];
    } else {
      var menus = menu;
    }
    const payload = {
      type: type,
      companyId: sso_cred?.companyId,
      // locationId: locationId,
      menus: menus,
    };
    if (activeType?._id) {
      payload._id = activeType?._id;
    }
    // console.log(payload, "payload");
    const { response } = await AppService.sidenav_customizer_main_level[
      activeType?._id ? "update" : "create"
    ]({
      payload: payload,
    });
    if (response) {
      if (type == "agency") {
        setagencyLevel(response.data);
      } else {
        setlocationLevel(response.data);
      }
    }
    // console.log(response, "response");
  };
  const saveLocation = async (newLocation) => {
    const payload = {
      ...newLocation,
      name: newLocation.name,
      enable_menu_edits: newLocation.enable_menu_edits,
      locations: newLocation.locations,
      companyId: sso_cred?.companyId,
    };
    // console.log(payload, "payload");
    if (newLocation?._id) {
      payload._id = newLocation?._id
    }
    const { response } =
      await AppService.sidenav_customizer_custom_level[newLocation?._id ? "update" : "create"]({
        payload: payload,
      });
    // console.log(response, "response");
    if (response) {
      onLoad()
      // if (newLocation?._id) {
      //   const data = customLevel
      //   var index = data.findIndex(function (obj) {
      //     return obj._id === newLocation?._id;
      //   });
      //   data[index] = response.data
      //   console.log(data, "data");

      //   setCustomLevel(data);
      //   const temp_ghl_locations = main_ghl_locations
      //   for (let index_c = 0; index_c < data.length; index_c++) {
      //     const element_c = data[index_c];
      //     for (let index_l = 0; index_l < temp_ghl_locations.length; index_l++) {
      //       const element = temp_ghl_locations[index_l];
      //       if (element_c.locations.filter((location) => location.id == element.id)[0]) {
      //         temp_ghl_locations.splice(index_l, 1);
      //       }

      //     }

      //   }
      //   setGhl_locations(temp_ghl_locations)
      //   setrefresh(!refresh)
      // } else {
      //   const data = [...customLevel, response.data]
      //   setCustomLevel(data);
      //   const temp_ghl_locations = main_ghl_locations
      //   for (let index_c = 0; index_c < data.length; index_c++) {
      //     const element_c = data[index_c];
      //     for (let index_l = 0; index_l < temp_ghl_locations.length; index_l++) {
      //       const element = temp_ghl_locations[index_l];
      //       if (element_c.locations.filter((location) => location.id == element.id)[0]) {
      //         temp_ghl_locations.splice(index_l, 1);
      //       }

      //     }

      //   }
      //   setGhl_locations(temp_ghl_locations)
      // }
    }
  };
  const saveLocationMenu = async (menu, locationId) => {
    const data = customLevel;
    var index = data.findIndex(function (obj) {
      return obj._id === locationId;
    });
    // console.log(index, 'index')
    // console.log(data[index]?.menus, 'data[index]?.menus')

    if (data[index]?.menus) {
      var menus = [...data[index]?.menus, menu];
    } else {
      var menus = [menu];
    }
    // If the object with the specified id is found
    if (index !== -1) {
      // Update the object's properties
      Object.assign(data[index], { ...data[index], menus: menus });
    } else {
    }
    const payload = {
      _id: locationId,
      menus: menus,
    };
    const { response } =
      await AppService.sidenav_customizer_custom_level.update({
        payload: payload,
      });
    if (response) {
      setCustomLevel(data);
      setrefresh(!refresh)
    }
  };
  const deleteMainMenus = async (menuIndex, Id, type) => {
    var menus =
      type == "agency" ? [...agencyLevel?.menus] : [...locationLevel?.menus];
    menus.splice(menuIndex, 1);
    const payload = {
      _id: Id,
      menus: menus,
    };
    const { response } = await AppService.sidenav_customizer_main_level.update({
      payload: payload,
    });
    if (response) {
      if (type == "agency") {
        setagencyLevel(response.data);
      } else {
        setlocationLevel(response.data);
      }
    }
  };
  const DeleteNewLevel = async (locationId, locationIndex) => {
    const updatedNewLevelMenus = customLevel;
    updatedNewLevelMenus.splice(locationIndex, 1);

    const { response } =
      await AppService.sidenav_customizer_custom_level.delete({
        query: `_id=${locationId}`,
      });
    if (response) {
      setCustomLevel(updatedNewLevelMenus);
      setrefresh(!refresh);
    }
  };
  const deleteNewLevelMenu = async (menuIndex, locationId) => {
    const updatedNewLevelMenus = [...customLevel];
    var index = updatedNewLevelMenus.findIndex(function (obj) {
      return obj._id === locationId;
    });
    var menus = [...updatedNewLevelMenus[index]?.menus];
    menus.splice(menuIndex, 1);
    if (index !== -1) {
      // Update the object's properties
      Object.assign(updatedNewLevelMenus[index], {
        ...updatedNewLevelMenus[index],
        menus: menus,
      });
    } else {
    }
    const payload = {
      _id: locationId,
      menus: menus,
    };
    const { response } =
      await AppService.sidenav_customizer_custom_level.update({
        payload: payload,
      });
    if (response) {
      setCustomLevel(updatedNewLevelMenus);
    }
  };
  const handleAllLocationEditMenu = () => {

  }
  const handleCustomEditMenu = () => {

  }

  return (
    <div>
     {
        loader ?
          <Loader />
          :
          <>
      <AppHeader app_data={app_data}/>
      <div className="main main-app p-lg-4">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="d-flex gap-3 flex-wrap">
              <div>
                <HeaderButtons headerButtons={headerButtons} />
              </div>
              {/* <div>
                <TopNavigationMenu />
              </div>
              <div>
                <MenuEditorModal />
              </div> */}
              <div>
                {/* <DashboardButtonModal /> */}
                <DashboardButtonModal sso_cred={sso_cred} />
              </div>
              {/* <div>
                <FeaturesModal />
              </div> */}
              <div>
                {/* <DashboardModal /> */}
              </div>
              {/* <div>
                <QuickActionsModal />
              </div> */}
              <div>
                <OpportunityTabsModal sso_cred={sso_cred}/>
              </div>
              {/* <div>
                <TranslationModal />
              </div> */}
              {/* <div>
                <SettingsModal />
              </div>
              <div>
                <UserLimitModal />
              </div> */}
              <div>
                {/* <LaunchpadModal /> */}
                <LaunchpadModal sso_cred={sso_cred} />
              </div>
              <div>
                <ContactButtons  sso_cred={sso_cred}/>
              </div>
            </div>
          </div>
        </div>
        <div className="row gy-4">
                <div className="col-md-3">
                  <div
                    className="shadow-sm p-3 mb-4 bg-white rounded "
                    style={{ minHeight: "270px", height: '100%' }}
                  >
                    <div className="d-flex h-100 flex-column align-items-space-between">
                      <div className="d-flex flex-column gap-3">
                        <span className="fw-bold text-muted">Agency Menu</span>
                        <span className="mb-2">#agency location</span>
                        {agencyLevel?.menus?.length ? (
                          agencyLevel.menus.map((menu, index) => (
                            <div className="mb-1 d-flex justify-content-between align-items-center w-100">
                              <span>{menu.name}</span>
                              <span
                                onClick={() =>
                                  deleteMainMenus(index, agencyLevel._id, "agency")
                                }
                                style={{ cursor: "pointer", fontSize: "18px" }}
                              >
                                <i className="ri-close-circle-line"></i>
                              </span>
                            </div>
                          ))
                        ) : (
                          <span>You haven't added any menu yet</span>
                        )}
                      </div>
                      <div className="mt-auto">
                        <AgencyLevelMenu saveMenu={saveMenu} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="shadow-sm p-3 mb-4 bg-white rounded "
                    style={{ minHeight: "270px", height: '100%' }}
                  >
                    <div className="d-flex h-100 flex-column align-items-space-between">
                      <div className="d-flex flex-column gap-3">
                        <span className="fw-bold text-muted">
                          Account Location Menu
                        </span>
                        <span className="mb-2">#all location</span>
                        {locationLevel?.menus?.length ? (
                          locationLevel.menus.map((menu, index) => (
                            <div className="mb-1 d-flex justify-content-between align-items-center w-100">
                              <span>{menu.name}</span>
                              <span
                                onClick={() =>
                                  deleteMainMenus(
                                    index,
                                    locationLevel._id,
                                    "location"
                                  )
                                }
                                style={{ cursor: "pointer", fontSize: "18px" }}
                              >
                                <i className="ri-close-circle-line"></i>
                              </span>
                            </div>
                          ))
                        ) : (
                          <span>You haven't added any menu yet</span>
                        )}
                      </div>
                      <div className="mt-auto">
                        <LocationLevelMenu saveMenu={saveMenu} sso={sso_cred} />
                        <div className="mt-2">

                          <LocationMenuEdits handleSave={handleAllLocationEditMenu}
                            locationId={"#locationmenu"}
                            sso={sso_cred}
                            type="all_location"
                            data={locationLevel}

                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {customLevel.map((location, locationIndex) => (
                  <div className="col-md-3" key={locationIndex}>
                    <div
                      className="shadow-sm p-3 mb-4 bg-white rounded "
                      style={{ minHeight: "270px", height: '100%' }}
                    >
                      <div className="d-flex h-100 flex-column align-items-space-between">
                        <div className="d-flex flex-column gap-3">
                          <div className="d-flex justify-content-between">

                            <span className="fw-bold text-muted">{location.name}</span>
                            <NewLevelEditModal edit_data={location} ghl_locations={ghl_locations} saveLocation={saveLocation} DeleteNewLevel={() => DeleteNewLevel(location._id, locationIndex)} />
                          </div>
                          <span className="mb-2">
                            {location.locations.map((location) => <p>{`${location.name},`}</p>)}
                          </span>
                          {location?.menus?.length ? (
                            location.menus.map((menu, index) => (
                              <div className="mb-1 d-flex justify-content-between align-items-center w-100">
                                <span>{menu.name}</span>
                                <span
                                  onClick={() =>
                                    deleteNewLevelMenu(index, location._id)
                                  }
                                  style={{ cursor: "pointer", fontSize: "18px" }}
                                >
                                  <i className="ri-close-circle-line"></i>
                                </span>
                              </div>
                            ))
                          ) : (
                            <span>You haven't added any menu yet</span>
                          )}
                        </div>
                        <div className="mt-auto d-flex justify-content-between">
                          {/* <Button
                          variant="danger"
                          onClick={() =>
                            DeleteNewLevel(location._id, locationIndex)
                          }
                        >
                          Remove
                        </Button> */}
                          <CustomLevelMenu
                            saveMenu={saveLocationMenu}
                            locationId={location._id}
                          />
                        </div>
                        {
                          location.enable_menu_edits &&
                          <div className="mt-2">

                            <LocationMenuEdits handleSave={handleCustomEditMenu}
                              type="custom_location"
                              sso={sso_cred}

                              data={location}

                            />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-md-3">
                  <div
                    className="shadow-sm p-3 mb-4 bg-white rounded "
                    style={{ minHeight: "270px", height: '100%' }}
                  >
                    <div className="d-flex h-100 flex-column align-items-space-between">
                      <div className="d-flex flex-column gap-3">
                        <span className="fw-bold text-muted">Group Name</span>
                        {/* <span>#all location</span>
                                    <span className='mt-2'>You haven't added any menu yet</span> */}
                      </div>
                      <div className="mt-auto">
                        <NewLevelModal saveLocation={saveLocation} ghl_locations={ghl_locations} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      </div>
      </>
     }
    </div>
  );
};

export default SidnavCustomizer;
