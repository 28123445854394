import React, { useEffect, useState } from "react";
import AppHeader from "../../AppHeader";
import { useAppServices } from "../../../hook/services";

import ContactButtons from "./components/Modals/ContactButtons";
// import updateContactButton from "./components/Modals/UpdateContactbutton";

import { useParams } from "react-router-dom";
import SSOHandler from "../ssoHandler";
import {
  Modal,
  Button,
  Form,
  Tab,
  Tabs,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FaBell } from "react-icons/fa";

const SidnavCustomizer = () => {
  const AppService = useAppServices();
  const { SSO, checkSSO } = SSOHandler();
  const { app_id } = useParams();
  const [formData, setFormData] = useState({
    text: "",
    color: false,
    colorPicker: "",
    linkOrCustomValue: "",
    location: "",
  });
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    const updatedLinkOrCustomValue = formData.linkOrCustomValue + selectedValue;
    setFormData((prevState) => ({
      ...prevState,
      linkOrCustomValue: updatedLinkOrCustomValue,
    }));
  };
  const handleSelectLocation = (e) => {
    const selectedValue = e.target.value;

    setFormData((prevState) => ({
      ...prevState,
      location: selectedValue,
    }));
  };
  // function setButtonData() {
  //   console.log(buttonData, "buttonData");
  //   if (buttonData) {
  //     setFormData(buttonData.button_data);
  //     console.log(formData, "formData");
  //   }
  // }
  const [selectedContact, setSelectedContact] = useState("");

  const [sso_cred, setsso_cred] = useState({});
  const [buttonData, setButtonData] = useState([]);
  const fetchData = async () => {
    const { response } = await AppService.contactButtonLink.get({
      query: `company_id=${sso_cred.companyId}`,
    });

    setButtonData(response?.data);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const deleteButton = async (id) => {
    console.log("delete");
    const { success, message, data } =
      await AppService.contactButtonLink.delete({ query: `_id=${id}` });
    if (success) {
      fetchData();
      // Perform any additional actions after successful deletion
    } else {
      console.log(message);
      fetchData();
      // Handle error case
    }
  };

  return (
    <>
      <AppHeader />
      <div className="main main-app p-lg-4">
        <h1 className="text-center">Create Contact</h1>
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="d-flex gap-3 flex-wrap">
              <div>
                <ContactButtons sso_cred={sso_cred} fetchData={fetchData} />
                <updateContactButton
                  sso_cred={sso_cred}
                  fetchData={fetchData}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-12">
            <table className="table table-striped table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Button Text</th>
                  <th scope="col">Color</th>
                  <th scope="col">Link</th>
                  <th scope="col">Location</th>
                </tr>
              </thead>
              <tbody>
                {buttonData?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{data.button_text}</td>
                      <td>{data.colorPicker}</td>
                      <td>{data.linkOrCustomValue}</td>
                      <td>{data.location}</td>
                      <td className="text-center">
                        <ContactButtons
                          sso_cred={sso_cred}
                          fetchData={fetchData}
                          data={data}
                          isupdate={true}
                        />
                        <button
                          className="btn-danger btn ms-2"
                          onClick={() => deleteButton(data._id)}
                        >
                          detete
                        </button>
                        {/* <button>🔺</button> */}
                      </td>
                    </tr>
                  );
                })}

                {/* Add more rows as needed */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidnavCustomizer;
