import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PencilFill, PaintBucket } from "react-bootstrap-icons"
import { useAgencyInfo } from "../../../../../context/agency"
import data from "./Data";
import View from "./View";
import SettingHandler from "./SettingHandler";
import { Button } from "react-bootstrap";
import { useAppServices } from "../../../../../hook/services";
function getRandomInt() {
  const min = Math.ceil(1);
  const max = Math.floor(100);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const Widget = () => {
  const navigate = useNavigate();
  const { state } = useLocation()
  const [agency] = useAgencyInfo()
  const AppService = useAppServices()
  const settings = SettingHandler();
  const { fields, title, button } = data;
  const [formFields, setFormFields] = useState(fields);
  const [formTitle, setformTitle] = useState(title);
  const [refresh, setrefresh] = useState(false);
  const [customCss, setcustomCss] = useState('');

  const [formButton, setformButton] = useState(button);
  const [showSettings, setShowSettings] = useState("BuildForm");
  const DynamicComponent = settings[showSettings];
  const handleFields = ({ index, field, value }) => {
    console.log(index, "index");
    console.log(field, "field");
    console.log(value, "value");
    setFormFields((prevFormFields) =>
      prevFormFields.map((formField, i) =>
        i === index ? { ...formField, [field]: value } : formField
      )
    );
    setrefresh(!refresh);
  };
  const handleDeleteField = useCallback((id) => {
    setFormFields(prevFields => prevFields.filter((field) => field.id !== id));
    setrefresh(!refresh);
  }, [refresh]);
  const handleDuplicateField = useCallback((id) => {
    const fieldToDuplicate = formFields.find((field) => field.id === id);
    if (fieldToDuplicate) {
      const duplicatedField = { ...fieldToDuplicate, id: getRandomInt() };
      setFormFields(prevFields => [...prevFields, duplicatedField]);
    }
    setrefresh(!refresh);

  }, [refresh]);
  const handleAddField = () => {
    const newField = {
      id: getRandomInt(),
      label: 'New Field',
      type: 'text',
      placeholder: "Enter Your Text",
    };
    setFormFields((prevFields) => [...prevFields, newField]);
    // setFormFields([...formFields, {
    //   id: formFields.length+1,
    //   label: "Name",
    //   type: "text",
    //   placeholder: "Enter Your Name",
    // }]);
    setrefresh(!refresh);
  };
  const handleSave = async (type, value) => {
    let html = ` 
  <div class="form-wrapper">
  <div class="form-container">
  <div class="logo-container">
  ${formTitle}
  </div>

  <form class="form">`;
    formFields.forEach((field, index) => {
      html += `
    <div class="form-group" key=${index}>

    <label for="email">${field.label}</label>
    <input type=${field.type} id=${field.id} name="email" placeholder=${field.placeholder} />
  </div>
    `;
    });
    html += ` <button class="form-submit-btn" type="submit">${formButton}</button>
  </form>

</div>
</div>`;
    let css = `.form-wrapper{
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-container {
    width: 400px;
    background-color: #fff;
    padding: 32px 24px;
    font-size: 14px;
    font-family: inherit;
    color: #212121;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  }
  
  .form-container button:active {
    scale: 0.95;
  }
  
  .form-container .logo-container {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
  }
  
  .form-container .form {
    display: flex;
    flex-direction: column;
  }
  
  .form-container .form-group {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 10px;
  }
  
  .form-container .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-container .form-group input {
    width: 100%;
    padding: 12px 16px;
    border-radius: 6px;
    font-family: inherit;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  .form-container .form-group input::placeholder {
    opacity: 0.5;
  }
  
  .form-container .form-group input:focus {
    outline: none;
    border-color: #1778f2;
  }
  
  .form-container .form-submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: inherit;
    color: #fff;
    background-color: #212121;
    border: none;
    width: 100%;
    padding: 12px 16px;
    font-size: inherit;
    gap: 8px;
    margin: 12px 0;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  }
  
  .form-container .form-submit-btn:hover {
    background-color: #313131;
  }
${customCss}`;

    const payload = {
      html: html,
      css: css,
      name: "test",
      settings: {
        formFields: formFields,
      },
      agency_id: agency._id,
      widget_data: {},
    };
    console.log(payload, "payload");

    const { response } = await AppService.agency_app.create({
      payload: payload,
    });
    if (response) {
      navigate("/myapps", { state: { data: state } });
    }
  };
  const handleChange = (type, value) => { };
  const menus = [
    {
      label: "Build Form",
      key: "BuildForm",
      icon: <PencilFill />,
    },
    {
      label: "Layout",
      key: "Layout",
      icon: <span>&#xF598;</span>,
    },
    {
      label: "Appearance",
      key: "Appearance",
      icon: <PaintBucket />,
    },
  ];
  return (
    <>
      <div className="widget-header-main px-3 px-lg-4 d-flex justify-content-end">
        <Button onClick={handleSave}>
          Save
        </Button>
      </div>
      <div className="widget-setting-sidebar py-3 px-2">
        <div className="d-flex flex-column align-items-center">
          {menus.map((item) => (
            <div
              style={{ cursor: "pointer", color: item.key == showSettings ? "#2b7bd2" : 'white' }}
              className={`mt-2 d-flex flex-column align-items-center `}
              onClick={() => setShowSettings(item.key)}
            >
              <span>

                {item.icon}
              </span>
              <span style={{ fontSize: '12px' }}>

                {item.label}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="widget-setting-content d-flex justify-content-center align-items-center ">
        <View
          menus={menus}
          formFields={formFields}
          formTitle={formTitle}
          formButton={formButton}
          refresh={refresh}
        />
      </div>
      {showSettings && (
        <div className="settings-sidebar">
          {/* <textarea onChange={(e) => applyCss(e)}>

          </textarea> */}
          <DynamicComponent

            formFields={formFields}
            handleCustomCss={(value) => setcustomCss(value)}
            handleFields={handleFields}
            handleChange={handleChange}
            handleAddField={handleAddField}
            handleDeleteField={handleDeleteField}
            handleDuplicateField={handleDuplicateField}
            refresh={refresh} />
        </div>
      )}
    </>
  );
};

export default Widget;
