import React, { useState } from "react";
import {
  Accordion,
  Card,
  useAccordionButton,
  Form,
  Button,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ImagesCustomization from "./components/ImagesCustomization";
import MainLoginColors from "./components/MainLoginColors";
import LabelandInput from "./components/LabelandInput";
import ExtraFooterHeader from "./components/ExtraFooterHeader";
import ThemeSpecificVariable from "./components/ThemeSpecificVariable"

const LoginCustomization = () => {
  const [customization, setCustomization] = useState({
    ImagesCustomization: false,
    MainLoginColors: false,
    LabelsInputButtonBorderRadius:false,
    ExtraFooterandorHeaderInstructions:false,
    ThemeSpecificVariable:false,
  });

  const [imagesCustomizationEnabled, setImagesCustomizationEnabled] = useState(false);
  const [mainLoginColorsEnabled, setMainLoginColorsEnabled] = useState(false);
  const [labelandInputEnabled, setLabelandInputEnabled] = useState(false);
  const [extraFooterandorHeaderInstructionsEnabled, setExtraFooterandorHeaderInstructionsEnabled] = useState(false);
  const [themeSpecificVariableEnabled, setThemeSpecificVariableEnabled] = useState(false);


  const handleToggle = (feature) => {
    setCustomization((prevCustomization) => ({
      ...prevCustomization,
      [feature]: !prevCustomization[feature],
    }));

    if (feature === "ImagesCustomization") {
      setImagesCustomizationEnabled((previmagesCustomizationEnabled) => !previmagesCustomizationEnabled);
    }else if (feature === "MainLoginColors") {
        setMainLoginColorsEnabled((mainLoginColorsEnabled) => !mainLoginColorsEnabled);
    }else if (feature === "LabelandInput") {
        setLabelandInputEnabled((labelandInputEnabled) => !labelandInputEnabled);
    }
    else if (feature === "ExtraFooterandorHeaderInstructions") {
        setExtraFooterandorHeaderInstructionsEnabled((extraFooterandorHeaderInstructions) => !extraFooterandorHeaderInstructions);
    }
    else if (feature === "ThemeSpecificVariable") {
        setThemeSpecificVariableEnabled((themeSpecificVariableEnabled) => !themeSpecificVariableEnabled);
    }
    
  };
 

  const handleSubmit = (e) => {
    e.preventDefault();

    const mainLoginColorCustomization = customization.MainLoginColors;
    const backgroundColor = e.target.backgroundColor.value;
    const loginBoxBackground = e.target.loginBoxBackground.value;
    const loginHeadingColor = e.target.loginHeadingColor.value;
    const inputBackground = e.target.inputBackground.value;
    const inputBorder = e.target.inputBorder.value;
    const inputColor = e.target.inputColor.value;
    const errorColor = e.target.errorColor.value;
    const forgotPasswordColor = e.target.forgotPasswordColor.value;
    const loginButtonBackground = e.target.loginButtonBackground.value;
    const loginButtonColor = e.target.loginButtonColor.value;
    const loginButtonBackgroundHover = e.target.loginButtonBackgroundHover.value;
    const loginButtonColorHover = e.target.loginButtonColorHover.value;
    const footerNoteColor = e.target.footerNoteColor.value;
    const footerNoteLinkColor = e.target.footerNoteLinkColor.value;
    const submitButtonRoundedBorders = e.target.submitButtonRoundedBorders.value;
    const blackCheckMark = e.target.blackCheckMark.checked;
    // Label Input Buttons
    const labelsInputCustomization = customization.LabelsInputButtonBorderRadius;
    const labelColor = e.target.labelColor.value;
    const inputFieldsBorderRadius = e.target.inputFieldsBorderRadius.value;
    const submitButtonBorderRadius = e.target.submitButtonBorderRadius.value;
    const showEmailandPasswordLabels = e.target.showEmailandPasswordLabels.checked;

    const footerHeaderCustomization = customization.ExtraFooterandorHeaderInstructions;
    const headerNote = e.target.headerNote.value;
    const headerNoteColor = e.target.headerNoteColor.value;
    const footerNote = e.target.footerNote.value;
    const footerNoteColors = e.target.footerNoteColors.value;

    const imagesCustomization = customization.ImagesCustomization
    const bodyImageUpload = e.target.bodyImageUpload.value;
    const loginBoxLogoURL = e.target.loginBoxLogoURL.value;
    const loginBoxLogoWidth = e.target.loginBoxLogoWidth.value;
    const loginBoxLogoHeight = e.target.loginBoxLogoHeight.value;
    const logoTopMargin = e.target.logoTopMargin.value;

    const themeSpecificVariables = customization.ThemeSpecificVariable;
    const hideHeaderSection = e.target.hideHeaderSection.checked;
    const secondaryBackgroundColor = e.target.secondaryBackgroundColor.value;
    const thirdBackgroundColor = e.target.thirdBackgroundColor.value;
    const backgroundImageOverlay = e.target.backgroundImageOverlay.value;
    const inputIconBackground = e.target.inputIconBackground.value;
    const inputIconColor = e.target.inputIconColor.value;
    const headerLogo = e.target.headerLogo.value;
    const headerLogoWidth = e.target.headerLogoWidth.value;
    const headerLogoHeight = e.target.headerLogoHeight.value;

    const LoginColorCustomization = {
        mainLoginColorCustomization,
        backgroundColor,
        loginBoxBackground,
        loginHeadingColor,
        inputBackground,
        inputBorder,
        inputColor,
        errorColor,
        forgotPasswordColor,
        loginButtonBackground,
        loginButtonColor,
        loginButtonBackgroundHover,
        loginButtonColorHover,
        footerNoteColor,
        footerNoteLinkColor,
        submitButtonRoundedBorders,
        blackCheckMark,
    };
    const LabelInputCustomization = {
        labelsInputCustomization,
        showEmailandPasswordLabels,
        labelColor,
        inputFieldsBorderRadius,
        submitButtonBorderRadius,
    };

    const ExtraFooterandHeader = {
        footerHeaderCustomization,
        headerNote,
        headerNoteColor,
        footerNote,
        footerNoteColors,
    };
    const ImagesCustomization = {
        imagesCustomization,
        bodyImageUpload,
        loginBoxLogoURL,
        loginBoxLogoWidth,
        loginBoxLogoHeight,
        logoTopMargin,
    };

    const themeVariablesCustomization = {
        themeSpecificVariables,
        hideHeaderSection,
        secondaryBackgroundColor,
        thirdBackgroundColor,
        backgroundImageOverlay,
        inputIconBackground,
        inputIconColor,
        headerLogo,
        headerLogoWidth,
        headerLogoHeight,
    };
    const payload = {
        LoginColorCustomization: LoginColorCustomization,
        ImagesCustomization: ImagesCustomization,
        LabelInputCustomization: LabelInputCustomization,
        ExtraFooterandHeader: ExtraFooterandHeader,
        themeVariablesCustomization: themeVariablesCustomization,
    };
    console.log(payload, 'payload');
};
 

  const CustomToggle = ({ children, eventKey, value }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {});

    return (
      <Button
        type="button"
        onClick={decoratedOnClick}
        variant="text"
        style={{ width: '100%', textAlign: 'left', boxShadow: "none" ,outline:"none",border:"none"}}
      >
        {children}
      </Button>
    );
  };

  return (
    <div className="card p-3">
      <form action="" onSubmit={handleSubmit}>
      <div className="d-flex justify-content-between align-items-center p-3">
        <h6 className="fs-4">Login Theme Customization</h6>
          <Button type="submit">Save</Button>
        </div>
        <Accordion>
          {Object.entries(customization).map(([key, value], index) => (
            <Card key={key}>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <CustomToggle eventKey={String(index)} value={value}>
                {key.replace(/([A-Z])/g, " $1").trim()}
                </CustomToggle>
                <div className="d-flex align-items-center gap-2">
                  <Form.Check
                    type="switch"
                    id={`custom-switch-${index}`}
                    checked={value}
                    onChange={() => handleToggle(key)}
                    style={{ marginLeft: "10px" }}
                  />
                  <Button
                    size="sm"
                    style={{ float: "right" }}
                    variant={value ? "success" : "danger"}
                  >
                    {value ? "On" : "Off"}
                  </Button>
                </div>
              </Card.Header>
              {key === "ImagesCustomization" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <ImagesCustomization enabled={imagesCustomizationEnabled} />
                </Accordion.Collapse>
              )}
              {key === "MainLoginColors" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <MainLoginColors enabled={mainLoginColorsEnabled} />
                </Accordion.Collapse>
              )}
              {key === "LabelsInputButtonBorderRadius" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <LabelandInput enabled={labelandInputEnabled} />
                </Accordion.Collapse>
              )}
              {key === "ExtraFooterandorHeaderInstructions" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <ExtraFooterHeader enabled={extraFooterandorHeaderInstructionsEnabled} />
                </Accordion.Collapse>
              )}
              {key === "ThemeSpecificVariable" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <ThemeSpecificVariable enabled={themeSpecificVariableEnabled} />
                </Accordion.Collapse>
              )}
             
            </Card>
          ))}
        </Accordion>
      </form>
    </div>
  );
};

export default LoginCustomization;
