import React, { useState } from "react";
import {
  Accordion,
  Card,
  useAccordionButton,
  Form,
  Button,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DarkTheme from "./components/DarkTheme";
import CustomLogoForm from "./components/Logo";
import Header from "./components/Header";
import Dashboard from "./components/Dashboard";
import SecondaryNavigation from "./components/SecondaryNavigation";
import MenuBackgrounds from "./components/MenuBackgrounds";
import MenuElements from "./components/MenuElements";
import Search from "./components/Search";
import CardHeaders from "./components/CardHeaders";
import Tabs from "./components/Tabs";

const CustomizationPanel = () => {
  const [customization, setCustomization] = useState({
    DarkTheme: false,
    Logo: false,
    Header: false,
    Dashboard: false,
    SecondaryNavigation: false,
    MenuBackgrounds: false,
    MenuElements: false,
    CardHeaders: false,
    Tabs: false,
    Search: false,
  });

  const [darkThemeEnabled, setDarkThemeEnabled] = useState(false);
  const [logoEnabled, setLogoEnabled] = useState(false);
  const [headerEnabled, setHeaderEnabled] = useState(false);
  const [dashboardEnabled, setDashboardEnabled] = useState(false);
  const [secondaryNavigationEnabled, setSecondaryNavigationEnabled] =
    useState(false);
  const [menuBackgroundsEnabled, setMenuBackgroundsEnabled] = useState(false);
  const [menuElementsEnabled, setMenuElementsEnabled] = useState(false);
  const [cardHeadersEnabled, setCardHeadersEnabled] = useState(false);
  const [tabsEnabled, setTabsEnabled] = useState(false);
  const [searchEnabled, setSearchEnabled] = useState(false);

  const handleToggle = (feature) => {
    setCustomization((prevCustomization) => ({
      ...prevCustomization,
      [feature]: !prevCustomization[feature],
    }));

    switch (feature) {
      case "DarkTheme":
        setDarkThemeEnabled((prevDarkThemeEnabled) => !prevDarkThemeEnabled);
        break;
      case "Logo":
        setLogoEnabled((prevLogoEnabled) => !prevLogoEnabled);
        break;
      case "Header":
        setHeaderEnabled((prevHeaderEnabled) => !prevHeaderEnabled);
        break;
      case "Dashboard":
        setDashboardEnabled((prevDashboardEnabled) => !prevDashboardEnabled);
        break;
      case "SecondaryNavigation":
        setSecondaryNavigationEnabled(
          (prevSecondaryNavigationEnabled) => !prevSecondaryNavigationEnabled
        );
        break;
      case "MenuBackgrounds":
        setMenuBackgroundsEnabled(
          (prevMenuBackgroundsEnabled) => !prevMenuBackgroundsEnabled
        );
        break;
      case "MenuElements":
        setMenuElementsEnabled(
          (prevMenuElementsEnabled) => !prevMenuElementsEnabled
        );
        break;
      case "CardHeaders":
        setCardHeadersEnabled(
          (prevCardHeadersEnabled) => !prevCardHeadersEnabled
        );
        break;
      case "Tabs":
        setTabsEnabled((prevTabsEnabled) => !prevTabsEnabled);
        break;
      case "Search":
        setSearchEnabled((prevSearchEnabled) => !prevSearchEnabled);
        break;
      default:
        break;
    }
  };

  const CustomToggle = ({ children, eventKey, value }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {});

    return (
      <Button
        type="button"
        onClick={decoratedOnClick}
        variant="text"
        style={{ width: '100%', textAlign: 'left', boxShadow: "none" ,outline:"none",border:"none"}}
      >
        {children}
      </Button>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const darkThemeEnable = customization.DarkTheme;
    const logoEnable = customization.Logo;
    const headerEnable = customization.Header;
    const dashboardEnable = customization.Dashboard;
    const secondaryNavigationEnable = customization.SecondaryNavigation;
    const menuBackgroundsEnable = customization.MenuBackgrounds;
    const menuElementsEnable = customization.MenuElements;
    const cardHeadersEnable = customization.CardHeaders;
    const tabsEnable = customization.Tabs;
    const searchEnable = customization.Search;   


    const darkThemeToggle = e.target.darkThemeToggle.checked;
    const imageUpload = e.target.imageUpload.value;
    const logoHeight = e.target.logoHeight.value;
    const logoWidth = e.target.logoWidth.value;
    const headerLogoHeight = e.target.headerLogoHeight.value;
    const headerLogoWidth = e.target.headerLogoWidth.value;
    const headerBackgroundColor = e.target.headerBackgroundColor.value;
    const headerForegroundColor = e.target.headerForegroundColor.value;
    const headerButtonBorder = e.target.headerButtonBorder.value;
    const headerButtonShadow = e.target.headerButtonShadow.value;
    const headerMenuFont = e.target.headerMenuFont.value;
    const saasyDashboardCards = e.target.saasyDashboardCards.checked;
    const statusColor = e.target.statusColor.value;
    const cardValueBackground = e.target.cardValueBackground.value;
    const cardValueColor = e.target.cardValueColor.value;
    const closedStatsBackground = e.target.closedStatsBackground.value;
    const closedStatsColor = e.target.closedStatsColor.value;
    const openStatsBackground = e.target.openStatsBackground.value;
    const openStatsColor = e.target.openStatsColor.value;
    const lostStatsBackground = e.target.lostStatsBackground.value;
    const lostStatsColor = e.target.lostStatsColor.value;
    const wonStatsBackground = e.target.wonStatsBackground.value;
    const wonStatsColor = e.target.wonStatsColor.value;
    const statsValueBackground = e.target.statsValueBackground.value;
    const statsValueColor = e.target.statsValueColor.value;
    const customButtonsBackground = e.target.customButtonsBackground.value;
    const customButtonsBackgroundHover =
      e.target.customButtonsBackgroundHover.value;
    const customButtonsBorder = e.target.customButtonsBorder.value;
    const customButtonsBorderHover = e.target.customButtonsBorderHover.value;
    const customButtonsColor = e.target.customButtonsColor.value;
    const customButtonsColorHover = e.target.customButtonsColorHover.value;
    const EnableLeftSidebarNavigation =
      e.target.EnableLeftSidebarNavigation.checked;
    const DomainsSettingsLabel = e.target.DomainsSettingsLabel.value;
    const settingsIconBrightnessHover =
      e.target.settingsIconBrightnessHover.value;
    const customFieldSettingsLabel = e.target.customFieldSettingsLabel.value;
    const settingsIconBrightness = e.target.settingsIconBrightness.value;
    const mainMenuBackground = e.target.mainMenuBackground.value;
    const menuItemShadowColor = e.target.menuItemShadowColor.value;
    const menuIconWhite = e.target.menuIconWhite.checked;
    const menuIconBlack = e.target.menuIconBlack.checked;
    const menuIconWhiteHover = e.target.menuIconWhiteHover.checked;
    const menuIconBlackHover = e.target.menuIconBlackHover.checked;
    const menuItemBackGround = e.target.menuItemBackGround.value;
    const menuItemBorder = e.target.menuItemBorder.value;
    const menuItemColor = e.target.menuItemColor.value;
    const menuItemShadowColorHover = e.target.menuItemShadowColorHover.value;
    const menuItemBackGroundHover = e.target.menuItemBackGroundHover.value;
    const menuItemBorderHover = e.target.menuItemBorderHover.value;
    const menuItemColorHover = e.target.menuItemColorHover.value;
    const subMenuItemBackground = e.target.subMenuItemBackground.value;
    const subMenuItemColor = e.target.subMenuItemColor.value;
    const subMenuItemBackgroundHover =
      e.target.subMenuItemBackgroundHover.value;
    const submenuItemColorHover = e.target.submenuItemColorHover.value;
    const quickActionBackground = e.target.quickActionBackground.value;
    const quickActionBorderColor = e.target.quickActionBorderColor.value;
    const quickActionColor = e.target.quickActionColor.value;
    const quickActionBackgroundHover =
      e.target.quickActionBackgroundHover.value;
    const quickActionBorderColorHover =
      e.target.quickActionBorderColorHover.value;
    const quickActionColorHover = e.target.quickActionColorHover.value;
    const locationSwitcherColor = e.target.locationSwitcherColor.value;
    const subHeadingColor = e.target.subHeadingColor.value;
    const tooltipBackground = e.target.tooltipBackground.value;
    const tooltipColor = e.target.tooltipColor.value;
    const backButtonBackground = e.target.backButtonBackground.value;
    const backButtonColor = e.target.backButtonColor.value;
    const hideSettingsLabels = e.target.hideSettingsLabels.checked;
    const hideMenuDividers = e.target.hideMenuDividers.checked;
    const cardHeaderBackground = e.target.cardHeaderBackground.value;
    const cardHeaderColor = e.target.cardHeaderColor.value;
    const datepickerBackground = e.target.datepickerBackground.value;
    const datepickerColor = e.target.datepickerColor.value;
    const tabsBackground = e.target.tabsBackground.value;
    const tabsBorder = e.target.tabsBorder.value;
    const tabsColor = e.target.tabsColor.value;
    const tabsBorderRadius = e.target.tabsBorderRadius.value;
    const tabsBackgroundHover = e.target.tabsBackgroundHover.value;
    const tabsBorderHover = e.target.tabsBorderHover.value;
    const tabsColorHover = e.target.tabsColorHover.value;
    const SearchBackground = e.target.SearchBackground.value;
    const SearchBorderColor = e.target.SearchBorderColor.value;
    const SearchColor = e.target.SearchColor.value;

    const darkThemeCustomization = {
      darkThemeEnable: darkThemeEnable,
      darkThemeToggle: darkThemeToggle,
    };

    const logoCustomization = {
      logoEnable:logoEnable,
      imageUpload: imageUpload,
      logoHeight: logoHeight,
      logoWidth: logoWidth,
    };
    const headerCustomization = {
      headerEnable:headerEnable,
      headerLogoHeight: headerLogoHeight,
      headerLogoWidth: headerLogoWidth,
      headerBackgroundColor: headerBackgroundColor,
      headerForegroundColor: headerForegroundColor,
      headerButtonBorder: headerButtonBorder,
      headerButtonShadow: headerButtonShadow,
      headerMenuFont: headerMenuFont,
    };
    const dashboardCustomization = {
      dashboardEnable:dashboardEnable,
      saasyDashboardCards: saasyDashboardCards,
      statusColor: statusColor,
      cardValueBackground: cardValueBackground,
      cardValueColor: cardValueColor,
      closedStatsBackground: closedStatsBackground,
      closedStatsColor: closedStatsColor,
      openStatsBackground: openStatsBackground,
      openStatsColor: openStatsColor,
      lostStatsBackground: lostStatsBackground,
      lostStatsColor: lostStatsColor,
      wonStatsBackground: wonStatsBackground,
      wonStatsColor: wonStatsColor,
      statsValueBackground: statsValueBackground,
      statsValueColor: statsValueColor,
      customButtonsBackground: customButtonsBackground,
      customButtonsBackgroundHover: customButtonsBackgroundHover,
      customButtonsBorder: customButtonsBorder,
      customButtonsBorderHover: customButtonsBorderHover,
      customButtonsColor: customButtonsColor,
      customButtonsColorHover: customButtonsColorHover,
    };

    const SecondaryNavigation = {
      secondaryNavigationEnable:secondaryNavigationEnable,
      EnableLeftSidebarNavigation: EnableLeftSidebarNavigation,
      DomainsSettingsLabel: DomainsSettingsLabel,
      settingsIconBrightnessHover: settingsIconBrightnessHover,
      customFieldSettingsLabel: customFieldSettingsLabel,
      settingsIconBrightness: settingsIconBrightness,
    };

    const MenuBackgroundsCustomization = {
      menuBackgroundsEnable:menuBackgroundsEnable,
      mainMenuBackground: mainMenuBackground,
      menuItemShadowColor: menuItemShadowColor,
      menuIconWhite: menuIconWhite,
      menuIconWhiteHover: menuIconWhiteHover,
      menuIconBlackHover: menuIconBlackHover,
      menuItemBorder: menuItemBorder,
      menuIconBlack: menuIconBlack,
      menuItemBackGround: menuItemBackGround,
      menuItemColor: menuItemColor,
      menuItemShadowColorHover: menuItemShadowColorHover,
      menuItemBackGroundHover: menuItemBackGroundHover,
      menuItemBorderHover: menuItemBorderHover,
      menuItemColorHover: menuItemColorHover,
      subMenuItemBackground: subMenuItemBackground,
      subMenuItemColor: subMenuItemColor,
      subMenuItemBackgroundHover: subMenuItemBackgroundHover,
      submenuItemColorHover: submenuItemColorHover,
    };

    const MenuElementsCustomization = {
      menuElementsEnable:menuElementsEnable,
      quickActionBackground: quickActionBackground,
      quickActionBorderColor: quickActionBorderColor,
      quickActionColor: quickActionColor,
      quickActionBackgroundHover: quickActionBackgroundHover,
      quickActionBorderColorHover: quickActionBorderColorHover,
      quickActionColorHover: quickActionColorHover,
      locationSwitcherColor: locationSwitcherColor,
      subHeadingColor: subHeadingColor,
      tooltipBackground: tooltipBackground,
      tooltipColor: tooltipColor,
      backButtonBackground: backButtonBackground,
      backButtonColor: backButtonColor,
      hideSettingsLabels: hideSettingsLabels,
      hideMenuDividers: hideMenuDividers,
    };

    const CardHeadersCustomization = {
      cardHeadersEnable:cardHeadersEnable,
      cardHeaderBackground: cardHeaderBackground,
      cardHeaderColor: cardHeaderColor,
      datepickerBackground: datepickerBackground,
      datepickerColor: datepickerColor,
    };

    const TabsCustomization = {
      tabsEnable:tabsEnable,
      tabsBackground: tabsBackground,
      tabsBorder: tabsBorder,
      tabsColor: tabsColor,
      tabsBorderRadius: tabsBorderRadius,
      tabsBackgroundHover: tabsBackgroundHover,
      tabsBorderHover: tabsBorderHover,
      tabsColorHover: tabsColorHover,
    };
    const SearchCustomization = {
      searchEnable:searchEnable,
      SearchBackground: SearchBackground,
      SearchBorderColor: SearchBorderColor,
      SearchColor: SearchColor,
    };

    const payload = {
      darkThemeCustomization: darkThemeCustomization,
      logoCustomization: logoCustomization,
      headerCustomization: headerCustomization,
      dashboardCustomization: dashboardCustomization,
      SecondaryNavigation: SecondaryNavigation,
      MenuBackgroundsCustomization: MenuBackgroundsCustomization,
      MenuElementsCustomization: MenuElementsCustomization,
      CardHeadersCustomization: CardHeadersCustomization,
      TabsCustomization: TabsCustomization,
      SearchCustomization: SearchCustomization,
    };
    console.log(payload, "payload");
  };

  return (
    <div className="card p-3">
      <form action="" onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center p-3">
        <h6 className="fs-4">Top Navigation Theme Customization</h6>
          <Button type="submit">Save</Button>
        </div>

        <Accordion>
         
          {Object.entries(customization).map(([key, value], index) => (
            <Card key={key}>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <CustomToggle eventKey={String(index)} value={value}>
                {key.replace(/([A-Z])/g, " $1").trim()}
                </CustomToggle>
                <div className="d-flex align-items-center gap-2">
                  <Form.Check
                    type="switch"
                    id={`custom-switch-${index}`}
                    label=""
                    checked={value}
                    onChange={() => handleToggle(key)}
                    style={{ marginLeft: "10px" }}
                  />
                  <Button
                    size="sm"
                    style={{ float: "right" }}
                    variant={value ? "success" : "danger"}
                  >
                    {value ? "On" : "Off"}
                  </Button>
                </div>
              </Card.Header>
              {key === "DarkTheme" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <DarkTheme enabled={darkThemeEnabled} />
                </Accordion.Collapse>
              )}
              {key === "Logo" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <CustomLogoForm enabled={logoEnabled} />
                </Accordion.Collapse>
              )}
              {key === "Header" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <Header enabled={headerEnabled} />
                </Accordion.Collapse>
              )}
              {key === "Dashboard" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <Dashboard enabled={dashboardEnabled} />
                </Accordion.Collapse>
              )}
              {key === "SecondaryNavigation" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <SecondaryNavigation enabled={secondaryNavigationEnabled} />
                </Accordion.Collapse>
              )}
              {key === "MenuBackgrounds" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <MenuBackgrounds enabled={menuBackgroundsEnabled} />
                </Accordion.Collapse>
              )}
              {key === "MenuElements" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <MenuElements enabled={menuElementsEnabled} />
                </Accordion.Collapse>
              )}
              {key === "CardHeaders" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <CardHeaders enabled={cardHeadersEnabled} />
                </Accordion.Collapse>
              )}
              {key === "Tabs" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <Tabs enabled={tabsEnabled} />
                </Accordion.Collapse>
              )}
              {key === "Search" && (
                <Accordion.Collapse eventKey={String(index)}>
                  <Search enabled={searchEnabled} />
                </Accordion.Collapse>
              )}
            </Card>
          ))}
        </Accordion>
      </form>
    </div>
  );
};

export default CustomizationPanel;
