import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

const CardHeaders = () => {
    const [cardHeaderBackground, setCardHeaderBackground] = useState("#ffffff");
    const [cardHeaderColor, setCardHeaderColor] = useState("#ffffff");
    const [datepickerBackground, setDatepickerBackground] = useState("#ffffff");
    const [datepickerColor, setDatepickerColor] = useState("#ffffff");

  return (
    <>
     <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Card Header Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={cardHeaderBackground}
                  name="cardHeaderBackground"
                  onChange={(e) => setCardHeaderBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={cardHeaderBackground}
                  onChange={(e) => setCardHeaderBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Card Header Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={cardHeaderColor}
                  name="cardHeaderColor"
                  onChange={(e) => setCardHeaderColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={cardHeaderColor}
                  onChange={(e) => setCardHeaderColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
       
      </Row>
     <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Date Picker Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={datepickerBackground}
                  name="datepickerBackground"
                  onChange={(e) => setDatepickerBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={datepickerBackground}
                  onChange={(e) => setDatepickerBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Datepicker Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={datepickerColor}
                  name="datepickerColor"
                  onChange={(e) => setDatepickerColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={datepickerColor}
                  onChange={(e) => setDatepickerColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
       
      </Row>
    
    </>
  )
}

export default CardHeaders