import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";

const LabelandInput = () => {
  const [labelColor, setLabelColor] = useState("#813636");
  const [inputFieldsBorderRadius, setInputFieldsBorderRadius] = useState("5px");
  const [submitButtonBorderRadius, setSubmitButtonBorderRadius] =
    useState("5px");
  const [showEmailandPasswordLabels, setShowEmailandPasswordLabels] =
    useState(false);
  return (
    <>
      <Row className="mb-3">
        <Col>
          <div
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Label>Show Email and Password Labels</Form.Label>
            <div>
              <Form.Check
                type="switch"
                id="showEmailandPasswordLabels"
                name="showEmailandPasswordLabels"
                checked={showEmailandPasswordLabels}
                onChange={(e) =>
                  setShowEmailandPasswordLabels(e.target.checked)
                }
              />
            </div>
          </div>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={4}>
              Label Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={labelColor}
                  name="labelColor"
                  onChange={(e) => setLabelColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={labelColor}
                  onChange={(e) => setLabelColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Input Fields Border Radius
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                
                <Form.Control
                  type="text"
                  name="inputFieldsBorderRadius"
                  value={inputFieldsBorderRadius}
                  onChange={(e) => setInputFieldsBorderRadius(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Submit Button Border Radius
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                
                <Form.Control
                  type="text"
                  name="submitButtonBorderRadius"
                  value={submitButtonBorderRadius}
                  onChange={(e) => setSubmitButtonBorderRadius(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default LabelandInput;
