// import { v4 as uuidv4 } from 'uuid';
function getRandomInt() {
  const min = Math.ceil(1);
  const max = Math.floor(100);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const fields = [
  {
    id: getRandomInt(),
    label: 'Name',
    type: 'text',
    placeholder: 'Enter Your Name',
  },
  {
    id: getRandomInt(),
    label: 'Email',
    type: 'email',
    placeholder: 'Enter Your Email',
  },
];
const title = 'Contact Us';
const button = 'Contact Us';
const data = {
  fields,
  title,
  button,
};
export default data;
