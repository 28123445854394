import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import AddButton from "./components/AddButton";
import UpdateContactButton from "./components/UpdateButton";

function ContactButtons() {
  const [show, setShow] = useState(false);
  const [headerFolders, setHeaderFolders] = useState([]);
  const [contactFolders, setContactFolders] = useState([]);
  const [isAddButtonVisible, setIsAddButtonVisible] = useState(false);
  const [showUpdateButton, setShowUpdateButton] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleAddHeaderFolder = () => {
    const newFolders = [...headerFolders, {}]; 
    setHeaderFolders(newFolders);
  };

  const handleAddContactFolder = () => {
    const newFolders = [...contactFolders, {}]; 
    setContactFolders(newFolders);
  };

  const handleAddButton = () => {
    setIsAddButtonVisible(!isAddButtonVisible);
    setShowUpdateButton(false);
  };

  const handleUpdateButtonClick = () => {
    setShowUpdateButton(true);
    setIsAddButtonVisible(false);
  };

  const handleReturn = () => {
    setIsAddButtonVisible(false);
    setShowUpdateButton(false);
  };

  const handleDragStart = (e, folderType, folderIndex) => {
    e.dataTransfer.setData("folderType", folderType);
    e.dataTransfer.setData("folderIndex", folderIndex.toString());
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetFolderType) => {
    const draggedFolderType = e.dataTransfer.getData("folderType");
    const draggedFolderIndex = parseInt(e.dataTransfer.getData("folderIndex"));

    if (draggedFolderType === targetFolderType) return;

    const draggedFolder =
      draggedFolderType === "header" ? headerFolders[draggedFolderIndex] : contactFolders[draggedFolderIndex];

    if (targetFolderType === "header") {
      const newContactFolders = [...contactFolders];
      newContactFolders.splice(draggedFolderIndex, 1);
      setContactFolders(newContactFolders);
      setHeaderFolders([...headerFolders, draggedFolder]);
    } else {
      const newHeaderFolders = [...headerFolders];
      newHeaderFolders.splice(draggedFolderIndex, 1);
      setHeaderFolders(newHeaderFolders);
      setContactFolders([...contactFolders, draggedFolder]);
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Contact Buttons
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Contact Buttons</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "100vh", overflowY: "auto" }}>
          {!isAddButtonVisible && !showUpdateButton && (
            <div className="row">
            
              <div className="col-lg-6" onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e, "header")}>
                <div className="text-center">
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "8pt",
                      padding: "4px",
                      fontWeight: "bold",
                      background: "rgb(226, 229, 235)",
                      border: "none",
                      color: "rgb(53, 90, 195)",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    Header
                  </div>
                  {headerFolders.map((folder, index) => (
                    <div
                      key={index}
                      className="d-flex flex-column gap-3 align-items-start mt-3 mb-3"
                      draggable
                      onDragStart={(e) => handleDragStart(e, "header", index)}
                    >
                      <div className="d-flex gap-3">
                        <FaBars size={20} className="mr-2" onClick={handleUpdateButtonClick} />
                        <span>Type: folder</span>
                        <span className="ml-2">Name: Folder name</span>
                      </div>
                      <div
                        onClick={handleAddButton}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        Add Button
                      </div>
                    </div>
                  ))}
                  {headerFolders.length === 0 && <div>No Folders Yet</div>}
                  <div className="mt-3 d-flex justify-content-center align-items-center gap-2">
                    <div
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                      onClick={handleAddHeaderFolder}
                    >
                      Add Folder
                    </div>
                    <div
                      onClick={handleAddButton}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      Add Button
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6"
                onDragOver={(e) => handleDragOver(e)}
                onDrop={(e) => handleDrop(e, "contact")}
              >
                <div className="text-center">
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "8pt",
                      padding: "4px",
                      fontWeight: "bold",
                      background: "rgb(226, 229, 235)",
                      border: "none",
                      color: "rgb(53, 90, 195)",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    Contact/Conversation Page
                  </div>
                  {contactFolders.map((folder, index) => (
                    <div
                      key={index}
                      className="d-flex flex-column gap-3 align-items-start mt-3 mb-3"
                      draggable
                      onDragStart={(e) => handleDragStart(e, "contact", index)}
                    >
                      <div className="d-flex gap-3">
                        <FaBars size={20} className="mr-2" onClick={handleUpdateButtonClick} />
                        <span>Type: folder</span>
                        <span className="ml-2">Name: Folder name</span>
                      </div>
                      <div
                        onClick={handleAddButton}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        Add Button
                      </div>
                    </div>
                  ))}
                  {contactFolders.length === 0 && <div>No Folders Yet</div>}
                  <div className="mt-3 d-flex justify-content-center align-items-center gap-2">
                    <div
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                      onClick={handleAddContactFolder}
                    >
                      Add Folder
                    </div>
                    <div
                      onClick={handleAddButton}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      Add Button
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isAddButtonVisible && <AddButton onReturn={handleReturn} />}
          {showUpdateButton && <UpdateContactButton onReturn={handleReturn} />}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ContactButtons;

