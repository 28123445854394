import React, { useState } from 'react'
import { Form, Row, Col } from "react-bootstrap";


const Tabs = () => {
    const [tabsBackground, setTabsBackground] = useState("#ffffff");
    const [tabsBorder, setTabsBorder] = useState("#ffffff");
    const [tabsColor, setTabsColor] = useState("#ffffff");
    const [tabsBorderRadius, setTabsBorderRadius] = useState("#ffffff");
    const [tabsBackgroundHover, setTabsBackgroundHover] = useState("#ffffff");
    const [tabsBorderHover, setTabsBorderHover] = useState("#ffffff");
    const [tabsColorHover, setTabsColorHover] = useState("#ffffff");
  return (
    <>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Tabs Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={tabsBackground}
                  name="tabsBackground"
                  onChange={(e) => setTabsBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={tabsBackground}
                  onChange={(e) => setTabsBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Tabs Border
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={tabsBorder}
                  name="tabsBorder"
                  onChange={(e) => setTabsBorder(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={tabsBorder}
                  onChange={(e) => setTabsBorder(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      
       
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Tabs Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={tabsColor}
                  name="tabsColor"
                  onChange={(e) => setTabsColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={tabsColor}
                  onChange={(e) => setTabsColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Tabs Border Radius
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={tabsBorderRadius}
                  name="tabsBorderRadius"
                  onChange={(e) => setTabsBorderRadius(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={tabsBorderRadius}
                  onChange={(e) => setTabsBorderRadius(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      
       
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Tabs Background (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={tabsBackgroundHover}
                  name="tabsBackgroundHover"
                  onChange={(e) => setTabsBackgroundHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={tabsBackgroundHover}
                  onChange={(e) => setTabsBackgroundHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Tabs Border (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={tabsBorderHover}
                  name="tabsBorderHover"
                  onChange={(e) => setTabsBorderHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={tabsBorderHover}
                  onChange={(e) => setTabsBorderHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      
       
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Tabs Color (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={tabsColorHover}
                  name="tabsColorHover"
                  onChange={(e) => setTabsColorHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={tabsColorHover}
                  onChange={(e) => setTabsColorHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
       
      
       
      </Row>
    </>
  )
}

export default Tabs