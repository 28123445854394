import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import "../../style.css";
import { useAppServices } from "../../../../../../hook/services";
function InstallationModal({ headerButtons }) {
  const AppService = useAppServices();
  const [open, setOpen] = useState(false);
  const [addbutton, setaddbutton] = useState(false);
  const [selctedButton, setselctedButton] = useState(null);
  const [buttons, setButtons] = useState(headerButtons || []);

  const [selectedIcon, setSelectedIcon] = useState("");
  const [showIconSelector, setShowIconSelector] = useState(false);
  const icons = [
    { name: "address-book", unicode: "f2b9" },
    { name: "address-card", unicode: "f2bb" },
    { name: "adjust", unicode: "f042" },
    { name: "air-freshener", unicode: "f5d0" },
    { name: "align-center", unicode: "f037" },
    { name: "align-justify", unicode: "f039" },
    { name: "align-left", unicode: "f036" },
    { name: "align-right", unicode: "f038" },
    { name: "allergies", unicode: "f461" },
    { name: "ambulance", unicode: "f0f9" },
    { name: "american-signlanguage-interpreting", unicode: "f2a3" },
    { name: "anchor", unicode: "f13d" },
    { name: "angle-double-down", unicode: "f103" },
    { name: "angle-double-left", unicode: "f100" },
    { name: "angle-double-right", unicode: "f101" },
    { name: "angle-double-up", unicode: "f102" },
    { name: "angle-down", unicode: "f107" },
    { name: "angle-left", unicode: "f104" },
    { name: "angle-right", unicode: "f105" },
    { name: "angle-up", unicode: "f106" },
    { name: "angry", unicode: "f556" },
    { name: "ankh", unicode: "f644" },
    { name: "apple-alt", unicode: "f5d1" },
    { name: "archive", unicode: "f187" },
    { name: "archway", unicode: "f557" },
    { name: "arrow-alt-circle-down", unicode: "f358" },
    { name: "arrow-alt-circle-left", unicode: "f359" },
    { name: "arrow-alt-circle-right", unicode: "f35a" },
    { name: "arrow-alt-circle-up", unicode: "f35b" },
    { name: "arrow-circle-down", unicode: "f0ab" },
    { name: "arrow-circle-left", unicode: "f0a8" },
    { name: "arrow-circle-right", unicode: "f0a9" },
    { name: "arrow-circle-up", unicode: "f0aa" },
    { name: "arrow-down", unicode: "f063" },
    { name: "arrow-left", unicode: "f060" },
    { name: "arrow-right", unicode: "f061" },
    { name: "arrow-up", unicode: "f062" },
    { name: "arrows-alt", unicode: "f0b2" },
    { name: "arrows-alt-h", unicode: "f337" },
    { name: "arrows-alt-v", unicode: "f338" },
    { name: "assistive-listeningsystems", unicode: "f2a2" },
    { name: "asterisk", unicode: "f069" },
    { name: "at", unicode: "f1fa" },
    { name: "atlas", unicode: "f558" },
    { name: "atom", unicode: "f5d2" },
    { name: "audio-description", unicode: "f29e" },
    { name: "award", unicode: "f559" },
    { name: "backspace", unicode: "f55a" },
    { name: "backward", unicode: "f04a" },
    { name: "balance-scale", unicode: "f24e" },
    { name: "ban", unicode: "f05e" },
    { name: "band-aid", unicode: "f462" },
    { name: "barcode", unicode: "f02a" },
    { name: "bars", unicode: "f0c9" },
    { name: "baseball-ball", unicode: "f433" },
    { name: "basketball-ball", unicode: "f434" },
    { name: "bath", unicode: "f2cd" },
    { name: "battery-empty", unicode: "f244" },
    { name: "battery-full", unicode: "f240" },
    { name: "battery-half", unicode: "f242" },
    { name: "battery-quarter", unicode: "f243" },
    { name: "battery-three-quarters", unicode: "f241" },
    { name: "bed", unicode: "f236" },
    { name: "beer", unicode: "f0fc" },
    { name: "bell", unicode: "f0f3" },
    { name: "bell-slash", unicode: "f1f6" },
    { name: "bezier-curve", unicode: "f55b" },
    { name: "bible", unicode: "f647" },
    { name: "bicycle", unicode: "f206" },
    { name: "binoculars", unicode: "f1e5" },
    { name: "birthday-cake", unicode: "f1fd" },
    { name: "blender", unicode: "f517" },
    { name: "blind", unicode: "f29d" },
    { name: "bold", unicode: "f032" },
    { name: "bolt", unicode: "f0e7" },
    { name: "bomb", unicode: "f1e2" },
    { name: "bone", unicode: "f5d7" },
    { name: "bong", unicode: "f55c" },
    { name: "book", unicode: "f02d" },
    { name: "book-open", unicode: "f518" },
    { name: "book-reader", unicode: "f5da" },
    { name: "bookmark", unicode: "f02e" },
    { name: "bowling-ball", unicode: "f436" },
    { name: "box", unicode: "f466" },
    { name: "box-open", unicode: "f49e" },
    { name: "boxes", unicode: "f468" },
    { name: "braille", unicode: "f2a1" },
    { name: "brain", unicode: "f5dc" },
    { name: "briefcase", unicode: "f0b1" },
    { name: "briefcase-medical", unicode: "f469" },
    { name: "broadcast-tower", unicode: "f519" },
    { name: "broom", unicode: "f51a" },
    { name: "brush", unicode: "f55d" },
    { name: "bug", unicode: "f188" },
    { name: "building", unicode: "f1ad" },
    { name: "bullhorn", unicode: "f0a1" },
    { name: "bullseye", unicode: "f140" },
    { name: "burn", unicode: "f46a" },
    { name: "bus", unicode: "f207" },
    { name: "bus-alt", unicode: "f55e" },
    { name: "business-time", unicode: "f64a" },
    { name: "calculator", unicode: "f1ec" },
    { name: "calendar", unicode: "f133" },
    { name: "calendar-alt", unicode: "f073" },
    { name: "calendar-check", unicode: "f274" },
    { name: "calendar-minus", unicode: "f272" },
    { name: "calendar-plus", unicode: "f271" },
    { name: "calendar-times", unicode: "f273" },
    { name: "camera", unicode: "f030" },
    { name: "camera-retro", unicode: "f083" },
    { name: "cannabis", unicode: "f55f" },
    { name: "capsules", unicode: "f46b" },
    { name: "car", unicode: "f1b9" },
    { name: "car-alt", unicode: "f5de" },
    { name: "car-battery", unicode: "f5df" },
  ];

  const handleInputClick = () => {
    setShowIconSelector(!showIconSelector);
  };

  const handleIconClick = (iconName) => {
    setSelectedIcon(`fas fa-${iconName}`);
    setShowIconSelector(false);
  };

  useEffect(() => {
    setButtons(headerButtons);
  }, [headerButtons]);
  const handleButtonClick = (value, index) => {
    setselctedButton({ ...value, index: index });
    setaddbutton(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function AddButton() {
    const [actionType, setactionType] = useState(
      selctedButton?.action_type || "Target"
    );
    const [onlyIcon, setonlyIcon] = useState(
      selctedButton?.onlyIcon != undefined ? selctedButton.onlyIcon : true
    );
    console.log(selctedButton, "selctedButton");
    const handleDeleteButton = async () => {
      const tempButtons = buttons;
      tempButtons.splice(selctedButton.index, 1);
      const { response } = await AppService.header_buttons.delete({
        query: `_id=${selctedButton._id}`,
      });
      if (response) {
        setButtons(tempButtons);
        setselctedButton(null);
        setaddbutton(false);
      } else {
        setselctedButton(null);
        setaddbutton(false);
      }
    };
    const handleEditButton = async (e) => {
      let linkValue = "";
      if (actionType === "Link") {
        linkValue = e.target.link.value;
        if (!linkValue || !linkValue.startsWith("https://")) {
          if (document.getElementById("formLink")) {
            document.getElementById("formLink").style.borderColor = "red";
            document.getElementById("validLink").style.display = "block";
          }

          return;
        }
      }
      if (document.getElementById("formLink")) {
        document.getElementById("formLink").style.borderColor = "#E2E5EC";
        document.getElementById("validLink").style.display = "none";
      }

      e.preventDefault();
      const payload = {
        icon: e.target.icon.value,
        button_text: onlyIcon ? null : e.target.button_text.value,
        color: e.target.color.value,
        tooltip: e.target.tooltip.value,
        button_class: e.target.button_class.value,
        action_type: e.target.action_type.value,
        targetLocation:
          actionType == "Target" ? e.target.targetLocation.value : null,
        link: actionType == "Link" ? e.target.link.value : null,
        // openInIframe: actionType == "Link" ? e.target.openInIframe.value : null,
        onlyIcon: onlyIcon,
        _id: selctedButton._id,
      };
      console.log(payload, "payload");
      const tempButtons = buttons;
      Object.assign(tempButtons[selctedButton.index], payload);
      const { response } = await AppService.header_buttons.update({
        payload: payload,
      });
      if (response) {
        setButtons(tempButtons);
        setselctedButton(null);
        setaddbutton(false);
      } else {
        setselctedButton(null);
        setaddbutton(false);
      }
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      let linkValue = "";
      if (actionType === "Link") {
        linkValue = e.target.link.value;
        if (!linkValue || !linkValue.startsWith("https://")) {
          if (document.getElementById("formLink")) {
            document.getElementById("formLink").style.borderColor = "red";
            document.getElementById("validLink").style.display = "block";
          }

          return;
        }
      }
      if (document.getElementById("formLink")) {
        document.getElementById("formLink").style.borderColor = "#E2E5EC";
        document.getElementById("validLink").style.display = "none";
      }

      const payload = {
        icon: e.target.icon.value,
        button_text: onlyIcon ? null : e.target.button_text.value,
        color: e.target.color.value,
        tooltip: e.target.tooltip.value,
        button_class: e.target.button_class.value,
        action_type: e.target.action_type.value,
        targetLocation:
          actionType == "Target" ? e.target.targetLocation.value : null,
        link: actionType == "Link" ? e.target.link.value : null,
        // openInIframe: actionType == "Link" ? e.target.openInIframe.value : null,
        onlyIcon: onlyIcon,
        companyId: "LFGsbI5C9IaUFoBRPqSw",
      };
      console.log(payload, "payload");
      const { response } = await AppService.header_buttons.create({
        payload: payload,
      });
      if (response) {
        setButtons([...buttons, response.data]);
        setaddbutton(false);
      } else {
        setaddbutton(false);
      }
      // handleClose()
    };
    const handleAddButtonClose = () => {
      setaddbutton(false);
      setselctedButton(null);
    };
    const targetLocations = [
      "launchpad",
      "dashboard",
      "conversations/conversations",
      "conversations/manual_actions",
      "conversations/templates",
      "conversations/links/link",
      "conversations/links/analyze",
      "calendars/view",
      "calendars/appointments",
      "settings/calendars",
      "contacts/smart_list/All",
      "contacts/bulk/actions",
      "contacts/restore",
      "tasks",
      "businesses",
      "settings/smart_list/Settings",
      "opportunities/list",
      "opportunities/pipeline",
      "payments/invoices",
      "payments/recurring-templates",
      "payments/proposals-estimates",
      "payments/v2/orders",
      "payments/v2/subscriptions",
      "payments/v2/transactions",
      "payments/products",
      "payments/coupons",
      "payments/settings/receipts",
      "payments/v2/taxes",
      "payments/integrations",
      "marketing/social-planner",
      "marketing/emails/scheduled",
      "marketing/emails/all",
      "marketing/templates",
      "marketing/links/link",
      "marketing/links/analyze",
      "marketing/affiliate-manager/campaigns",
      "marketing/affiliate-manager/affiliates",
      "marketing/affiliate-manager/payouts",
      "marketing/affiliate-manager/media",
      "automation/workflows",
      "automation/campaigns",
      "automation/triggers",
      "funnels-websites/funnels",
      "funnels-websites/websites",
      "blogs",
      "funnels-websites/client-portal/domain-setup",
      "funnels-websites/client-portal/branding",
      "funnels-websites/wordpress/wordpress_dashboard-v2",
      "form-builder/main",
      "form-builder/analyze",
      "form-builder/submissions",
      "survey-builder/main",
      "survey-builder/analyze",
      "survey-builder/submissions",
      "funnels-websites/chat_widget_settings",
      "settings/media-settings",
      "settings/redirect",
      "memberships/courses/dashboard",
      "memberships/courses/products",
      "memberships/courses/offers",
      "memberships/courses/analytics",
      "memberships/courses/settings",
      "memberships/communities/community-groups",
      "memberships/communities/clientportal-domain-setup",
      "reputation/overview",
      "reputation/requests",
      "reputation/reviews",
      "reputation/listing",
      "reporting/google",
      "reporting/facebook",
      "reporting/attribution/conversion_report",
      "reporting/attribution/source_report",
      "reporting/attribution-v2",
      "reporting/call_stats",
      "reporting/appointments-reports",
      "settings/company",
      "settings/staff/team",
      "settings/staff/teams",
      "settings/pipeline",
      "settings/businesses",
      "settings/calendars/preferences",
      "settings/calendars/availability",
      "settings/calendars/connections",
      "settings/phone_number",
      "settings/reputation/settings",
      "settings/fields",
      "settings/custom_values",
      "settings/conversation_providers",
      "settings/domain",
      "settings/integrations/list",
      "settings/integrations/facebook",
      "settings/integrations/tiktok",
      "settings/smtp_service",
      "settings/tags",
      "settings/labs",
      "settings/audit/logs",
    ];

    return (
      <div>
        <Form
          onSubmit={selctedButton != null ? handleEditButton : handleSubmit}
        >
          <Form.Group className="mb-3" controlId="formIcons">
            {/* <Form.Label>Icon</Form.Label>
            <Form.Control
              type="text"
              placeholder="fas fa-icon"
              name="icon"
              defaultValue={selctedButton?.icon}
            /> */}

            <Form.Label>Icon</Form.Label>
            <Form.Control
              type="text"
              placeholder="fas fa-icon"
              name="icon"
              value={selectedIcon}
              onClick={handleInputClick}
              readOnly
            />
            {showIconSelector && (
              <div
                style={{
                  maxHeight: "150px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  padding: "5px",
                }}
              >
                {icons.map((icon, index) => (
                  <span
                    key={index}
                    onClick={() => handleIconClick(icon.name)}
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  >
                    <i
                      className={`fas fa-${icon.name}`}
                      style={{ fontSize: "24px" }}
                    />
                  </span>
                ))}
              </div>
            )}
            <Form.Check
              checked={onlyIcon}
              onChange={(e) => setonlyIcon(e.target.checked)}
              type="checkbox"
              label="Use Only icon"
              name="icon-only"
            />
          </Form.Group>
          {!onlyIcon && (
            <Form.Group className="mb-3" controlId="formLocationID">
              <Form.Label>Button Text</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Button Text"
                name="button_text"
                defaultValue={selctedButton?.button_text}
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3" controlId="formLocationID">
            <Form.Label>Color</Form.Label>
            <Form.Control
              className="w-100"
              type="color"
              name="color"
              defaultValue={selctedButton?.color}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formLocationID">
            <Form.Label>ToolTip</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter ToolTip"
              name="tooltip"
              defaultValue={selctedButton?.tooltip}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formLocationID">
            <Form.Label>Button Class</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Button Class"
              name="button_class"
              defaultValue={selctedButton?.button_class}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formActionName">
            <Form.Label>Action Type</Form.Label>
            <Form.Select
              name="action_type"
              onChange={(e) => setactionType(e.target.value)}
            >
              <option value="Target">Target</option>
              <option value="Link">Link</option>
            </Form.Select>
          </Form.Group>
          {actionType === "Target" && (
            <Form.Group className="mb-3" controlId="formTargetLocation">
              <Form.Label>Target Location</Form.Label>
              <Form.Select
                name="targetLocation"
                defaultValue={selctedButton?.targetLocation}
              >
                <option value="">Select a target location...</option>
                {targetLocations.map((location, index) => (
                  <option key={index} value={location}>
                    {location}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          {actionType === "Link" && (
            <Form.Group className="mb-3" controlId="formLink">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Paste link here"
                name="link"
                defaultValue={selctedButton?.link}
              />
              <p id="validLink" style={{ color: "red", display: "none" }}>
                Please Enter a valid Url
              </p>
            </Form.Group>
          )}
          {/* {actionType === "Link" && (
            <Form.Group className="mb-3" controlId="formOpenInIframe">
              <Form.Check
                type="checkbox"
                label="Open in iframe"
                name="openInIframe"
                defaultValue={selctedButton?.openInIframe}
              />
            </Form.Group>
          )} */}
          <Modal.Footer>
            <Button
              variant="primary"
              type="button"
              onClick={handleAddButtonClose}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {selctedButton != null ? "Save" : "Save"}
            </Button>
            {selctedButton != null ? (
              <Button
                variant="danger"
                type="button"
                onClick={handleDeleteButton}
              >
                Delete
              </Button>
            ) : (
              <></>
            )}
          </Modal.Footer>
        </Form>
      </div>
    );
  }
  return (
    <>
      <Button variant="primary" onClick={() => setOpen(true)}>
        Header Buttons
      </Button>
      <Modal
        show={open}
        onHide={handleClose}
        centered
        className={addbutton ? "" : "header-button-modal"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selctedButton != null ? "Edit Button" : "Add Button"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addbutton ? (
            <AddButton />
          ) : (
            <div className="text-center d-flex gap-3 flex-wrap">
              {buttons.length ? (
                buttons.map((button, index) => (
                  <Button
                    variant="primary"
                    type="button"
                    style={{ background: button.color }}
                    onClick={() => handleButtonClick(button, index)}
                  >
                    {/* {button.onlyIcon?`<i class=${button.icon}></i>` :""} */}
                    {button.icon && <i className={button.icon}></i>}&nbsp;
                    {button.button_text}
                  </Button>
                ))
              ) : (
                <span>No Buttons Yet</span>
              )}
            </div>
          )}
        </Modal.Body>
        {!addbutton && (
          <Modal.Footer>
            <Button
              variant="primary"
              type="button"
              onClick={() => setaddbutton(true)}
            >
              Add
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}
export default InstallationModal;
