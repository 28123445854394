import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useAppServices } from "../../../../../../../hook/services";
const targetLocations = [
  "launchpad",
  "dashboard",
  "conversations/conversations",
  "conversations/manual_actions",
  "conversations/templates",
  "conversations/links/link",
  "conversations/links/analyze",
  "calendars/view",
  "calendars/appointments",
  "settings/calendars",
  "contacts/smart_list/All",
  "contacts/bulk/actions",
  "contacts/restore",
  "tasks",
  "businesses",
  "settings/smart_list/Settings",
  "opportunities/list",
  "opportunities/pipeline",
  "payments/invoices",
  "payments/recurring-templates",
  "payments/proposals-estimates",
  "payments/v2/orders",
  "payments/v2/subscriptions",
  "payments/v2/transactions",
  "payments/products",
  "payments/coupons",
  "payments/settings/receipts",
  "payments/v2/taxes",
  "payments/integrations",
  "marketing/social-planner",
  "marketing/emails/scheduled",
  "marketing/emails/all",
  "marketing/templates",
  "marketing/links/link",
  "marketing/links/analyze",
  "marketing/affiliate-manager/campaigns",
  "marketing/affiliate-manager/affiliates",
  "marketing/affiliate-manager/payouts",
  "marketing/affiliate-manager/media",
  "automation/workflows",
  "automation/campaigns",
  "automation/triggers",
  "funnels-websites/funnels",
  "funnels-websites/websites",
  "blogs",
  "funnels-websites/client-portal/domain-setup",
  "funnels-websites/client-portal/branding",
  "funnels-websites/wordpress/wordpress_dashboard-v2",
  "form-builder/main",
  "form-builder/analyze",
  "form-builder/submissions",
  "survey-builder/main",
  "survey-builder/analyze",
  "survey-builder/submissions",
  "funnels-websites/chat_widget_settings",
  "settings/media-settings",
  "settings/redirect",
  "memberships/courses/dashboard",
  "memberships/courses/products",
  "memberships/courses/offers",
  "memberships/courses/analytics",
  "memberships/courses/settings",
  "memberships/communities/community-groups",
  "memberships/communities/clientportal-domain-setup",
  "reputation/overview",
  "reputation/requests",
  "reputation/reviews",
  "reputation/listing",
  "reporting/google",
  "reporting/facebook",
  "reporting/attribution/conversion_report",
  "reporting/attribution/source_report",
  "reporting/attribution-v2",
  "reporting/call_stats",
  "reporting/appointments-reports",
  "settings/company",
  "settings/staff/team",
  "settings/staff/teams",
  "settings/pipeline",
  "settings/businesses",
  "settings/calendars/preferences",
  "settings/calendars/availability",
  "settings/calendars/connections",
  "settings/phone_number",
  "settings/reputation/settings",
  "settings/fields",
  "settings/custom_values",
  "settings/conversation_providers",
  "settings/domain",
  "settings/integrations/list",
  "settings/integrations/facebook",
  "settings/integrations/tiktok",
  "settings/smtp_service",
  "settings/tags",
  "settings/labs",
  "settings/audit/logs",
];

function TaskForm({ onSave, defaultTask }) {
  const AppService = useAppServices();
  const [task, setTask] = useState({
    imageUrl: "",
    description: "",
    tagline: "",
    buttonText: "",
    buttonType: "link",
    buttonLink: "",
    buttonTarget: "",
  });
  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTask((prev) => ({ ...prev, [name]: value }));
  };

  const handleTypeChange = (e) => {
    const type = e.target.value;
    setTask((prev) => ({
      ...prev,
      buttonType: type,
      buttonLink: type === "link" ? "" : task.buttonLink,
      buttonTarget: type === "link" ? "" : targetLocations[0],
    }));
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSave = async () => {
    // console.log(defaultTask);
    console.log([...defaultTask, task]);

    onSave(task);
    handleClose();
  };

  return (
    <>
      <button
        onClick={handleShow}
        style={{
          width: "50%",
          margin: " 0px auto 20px",
          border: "1px dashed rgb(96, 125, 139)",
          textAlign: "center",
          padding: "7px",
          textTransform: "uppercase",
          fontWeight: "bold",
          color: "rgb(31, 41, 5)",
          opacity: "0.9",
          fontSize: "10p",
          cursor: "pointer",
        }}
      >
        Add Task
      </button>
      <h6
        style={{
          color: "rgb(31, 41, 5)",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "20px",
          lineHeight: "29px",
          textAlign: "center",
        }}
      >
        Let's get you on the path of success
      </h6>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Task Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formImageUrl">
              <Form.Label>Image URL</Form.Label>
              <Form.Control
                type="text"
                name="imageUrl"
                value={task.imageUrl}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={task.description}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formTagline">
              <Form.Label>Tagline</Form.Label>
              <Form.Control
                type="text"
                name="tagline"
                value={task.tagline}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formButtonText">
              <Form.Label>Button Text</Form.Label>
              <Form.Control
                type="text"
                name="buttonText"
                value={task.buttonText}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formButtonType">
              <Form.Label>Button Type</Form.Label>
              <Form.Control
                as="select"
                name="buttonType"
                value={task.buttonType}
                onChange={handleTypeChange}
              >
                <option value="link">Link</option>
                <option value="target">Target</option>
              </Form.Control>
            </Form.Group>

            {task.buttonType === "link" && (
              <Form.Group controlId="formButtonLink">
                <Form.Label>Button Link</Form.Label>
                <Form.Control
                  type="text"
                  id="formLink"
                  name="buttonLink"
                  value={task.buttonLink}
                  onChange={handleChange}
                />
              </Form.Group>
            )}

            {task.buttonType === "target" && (
              <Form.Group controlId="formButtonTarget">
                <Form.Label>Button Target</Form.Label>
                <Form.Control
                  as="select"
                  name="buttonTarget"
                  value={task.buttonTarget}
                  onChange={handleChange}
                >
                  {targetLocations.map((location) => (
                    <option key={location} value={location}>
                      {location}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TaskForm;
