import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

const Header = () => {
  const [logoWidth, setLogoWidth] = useState("200px");
  const [useGoogleFont, setUseGoogleFont] = useState(false);

  const [logoHeight, setLogoHeight] = useState("80px");
  const [headerBgColor, setHeaderBgColor] = useState("#ffffff");
  const [headerFgColor, setHeaderFgColor] = useState("#355ff1");
  const [headerButtonBorder, setHeaderButtonBorder] = useState("#ffffff");
  const [headerButtonShadow, setHeaderButtonShadow] = useState("#722727");
  const [headerMenuFont, setHeaderMenuFont] = useState("Open Sans");
  const availableFonts = [
    "Open Sans",
    "Roboto",
    "Lato",
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Georgia",
    "Courier New",
    "Verdana",
    "Tahoma",
    "Trebuchet MS",
    "Impact",
    "Arial Black",
    "Comic Sans MS",
    "Century Gothic",
    "Lucida Sans",
    "Palatino",
    "Garamond",
    "Futura",
    "Gill Sans",
    "Baskerville",
    "Didot",
    "Consolas",
    "Monaco",
    "Copperplate",
    "Brush Script",
  ];

  const handleFontChange = (e) => {
    setHeaderMenuFont(e.target.value);
  };
  return (
    <>
    
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={4}>
              Logo Width
            </Form.Label>
            <Row sm={8}>
              <Form.Control
                type="text"
                name="headerLogoWidth"
                value={logoWidth}
                onChange={(e) => setLogoWidth(e.target.value)}
              />
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={4}>
              Logo Height
            </Form.Label>
            <Row sm={8}>
              <Form.Control
                type="text"
                name="headerLogoHeight"
                value={logoHeight}
                onChange={(e) => setLogoHeight(e.target.value)}
              />
            </Row>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={4}>
              Header Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={headerBgColor}
                  name="headerBackgroundColor"
                  onChange={(e) => setHeaderBgColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={headerBgColor}
                  onChange={(e) => setHeaderBgColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={4}>
              Header Foreground
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={headerFgColor}
                  name="headerForegroundColor"
                  onChange={(e) => setHeaderFgColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={headerFgColor}
                  onChange={(e) => setHeaderFgColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={4}>
              Header Button Border
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={headerButtonBorder}
                  name="headerButtonBorder"
                  onChange={(e) => setHeaderButtonBorder(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={headerButtonBorder}
                  onChange={(e) => setHeaderButtonBorder(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={6}>
              Header Button Shadow
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={headerButtonShadow}
                  name="headerButtonShadow"
                  onChange={(e) => setHeaderButtonShadow(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={headerButtonShadow}
                  onChange={(e) => setHeaderButtonShadow(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <Form.Label column sm={4}>
                Header Menu Font
              </Form.Label>
              <Col>
                <Form.Check
                  type="switch"
                  id="customSwitch"
                  label="Google System"
                  checked={useGoogleFont}
                  onChange={() => setUseGoogleFont(!useGoogleFont)}
                />
              </Col>
            </div>
            <Col>
              {useGoogleFont ? (
                <Form.Control
                  type="text"
                  name="customGoogleFont"
                  placeholder="Custom font family name"
                  value={headerMenuFont}
                  onChange={handleFontChange}
                />
              ) : (
                <Form.Control
                  as="select"
                  name="headerMenuFont"
                  value={headerMenuFont}
                  onChange={handleFontChange}
                >
                  {availableFonts.map((font) => (
                    <option key={font}>{font}</option>
                  ))}
                </Form.Control>
              )}
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default Header;
