/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// Material Dashboard 2 React routes
import useRoutes from "./routes";
import { useEffect } from "react";

export default function Authentication() {
  const routes = useRoutes();
  const { pathname } = useLocation();

  window.scrollTo(0, 0);
  useEffect(() => {
    // let isOffset = document.body.classList.contains("sidebar-offset");
    // if (isOffset) {
    //   document.body.classList.toggle("sidebar-show");
    // } else {
    //   if (window.matchMedia("(max-width: 991px)").matches) {
    //     document.body.classList.toggle("sidebar-show");
    //   } else {
    //   }
    // }
    // alert('kljh')
    if (pathname.split('/')[3] != "theme-builder") {

      document.body.classList.add("sidebar-hide");
    }
  }, []);
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }
      return null;
    });

  return (
    <>

      <div className="p-3">
        <Routes>
          {getRoutes(routes)}
        </Routes>
      </div>
    </>
  );
}
