import { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import Select from "react-select";

function InstallationModal({ saveLocation, ghl_locations }) {
    const [open, setOpen] = useState(false)
    const [refresh, setrefresh] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState([""]);

    const removeLocation = (Index) => {
        console.log(Index, 'Index')
        const data = selectedLocations
        console.log(data, 'data')
        data.splice(Index, 1);
        console.log(data, 'splicedata')

        setSelectedLocations(data);
        setrefresh(!refresh)
    };
    const editLocation = (Index, value) => {
        const data = selectedLocations

        data[Index] = value;
        setSelectedLocations(data);
        setrefresh(!refresh)

    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const locationsWithIds = {
            name: e.target.name.value,
            enable_menu_edits: e.target.enable_menu_edits.checked,
            locations: selectedLocations,
        };
        console.log("Locations with IDs:", locationsWithIds);
        saveLocation(locationsWithIds);
        setSelectedLocations([]);
        handleClose()
    };

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <Button variant="primary" className="w-100" onClick={() => setOpen(true)}>
                + Add New Group
            </Button>
            <Modal show={open} onHide={handleClose} centered>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add new Group</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formLocationID">
                            <Form.Label>Group Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Location Name"
                                name="name"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLocationID">
                            <Form.Label>Locations</Form.Label>
                            <Select options={ghl_locations} isSearchable={false} isMulti onChange={(e) => setSelectedLocations(e)} />
                            {/* {
                                selectedLocations.map((location, index) =>
                                    <>
                                        <div className="d-flex gap-2 align-items-center">

                                            <Form.Control
                                                className="mb-2"
                                                type="text"
                                                placeholder="Enter Location ID"
                                                name="location_id"
                                                value={location}
                                                onChange={(e) => editLocation(index, e.target.value)}
                                            />
                                            <span style={{ cursor: 'pointer', fontSize: '18px' }}
                                                onClick={() => removeLocation(index)}
                                            ><i className="ri-close-circle-line"></i>
                                            </span>
                                        </div>
                                    </>
                                )
                            }
                            <div className="d-flex justify-content-start mt-2">

                                <Button variant="primary" type="button" onClick={() => setSelectedLocations([...selectedLocations, ''])}>
                                    + Add Location Id
                                </Button>
                            </div> */}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLink">
                            <Form.Check
                                type="checkbox"
                                label="Enable Menu Edits"
                                name="enable_menu_edits"
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Group
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
export default InstallationModal