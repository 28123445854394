import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";

function SecondaryNavigation() {
  const [enableLeftSidebarNavigation, setEnableLeftSidebarNavigation] =
    useState(false);
  const [domainsSettingsLabel, setDomainsSettingsLabel] = useState("");
  const [settingsIconBrightnessHover, setSettingsIconBrightnessHover] =
    useState(1);
  const [customFieldSettingsLabel, setCustomFieldSettingsLabel] = useState("");
  const [settingsIconBrightness, setSettingsIconBrightness] = useState(0);

  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
            controlId="formBasicEmail"
          >
            <Form.Check
              type="switch"
              name="EnableLeftSidebarNavigation"
              id="enable-sidebar-switch"
              label="Enable Left Sidebar Navigation"
              checked={enableLeftSidebarNavigation}
              onChange={() =>
                setEnableLeftSidebarNavigation((prevState) => !prevState)
              }
            />
          </Form.Group>
          <Form.Group
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
            controlId="domains-label"
          >
            <Form.Label>Domains Settings Label</Form.Label>
            <Form.Control
              type="text"
              placeholder="Domains"
              name="DomainsSettingsLabel"
              value={domainsSettingsLabel}
              onChange={(e) => setDomainsSettingsLabel(e.target.value)}
            />
          </Form.Group>
          <Form.Group
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
            controlId="settings-icon-brightness-hover"
          >
            <Form.Label>Settings Icon Brightness (Hover)</Form.Label>
            <Form.Control
              type="number"
              placeholder="1"
              name="settingsIconBrightnessHover"
              value={settingsIconBrightnessHover}
              onChange={(e) =>
                setSettingsIconBrightnessHover(parseFloat(e.target.value))
              }
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
            controlId="custom-field-label"
          >
            <Form.Label>Custom Field Settings Label</Form.Label>
            <Form.Control
              type="text"
              name="customFieldSettingsLabel"
              placeholder="Custom Fields"
              value={customFieldSettingsLabel}
              onChange={(e) => setCustomFieldSettingsLabel(e.target.value)}
            />
          </Form.Group>
          <Form.Group
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
            controlId="settings-icon-brightness"
          >
            <Form.Label>Settings Icon Brightness</Form.Label>
            <Form.Control
              type="number"
              placeholder="0"
              name="settingsIconBrightness"
              value={settingsIconBrightness}
              onChange={(e) =>
                setSettingsIconBrightness(parseFloat(e.target.value))
              }
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}

export default SecondaryNavigation;
