import { Environment } from "../enums";

const env = {
  API_URL: "https://api.appifyhub.co",
  // API_URL : 'http://35.176.217.108:8080/api'
  // SERVER: 'http://localhost:8080',
  // API_URL: "http://localhost:8080",
  SUPER_ADMIN: "6298acc76bcf0340b4ec6b6b",
  GOOGLE: {
    CLIENT_ID:
      "968522092484-5f7dplbiqadksv35pickgt8le3mke52t.apps.googleusercontent.com",
    CLIENT_SECRET: "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",
  },
  GHL: {
    CLIENT_ID: "6246e2fcfc3dabe2d3301bc5-l1gcm0ww",
    CLIENT_SECRET: "67be1628-cb7c-4530-95a3-a83e60093cd0",
    SCOPE:
      "contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/customFields.write workflows.readonly users.readonly users.write campaigns.readonly conversations/message.readonly conversations/message.write locations/customValues.readonly locations/customValues.write opportunities.readonly opportunities.write forms.readonly links.readonly links.write surveys.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly",
    REDIRECT: {
      LOCAITON: "http://localhost:3000/integrations/ghl/location",
      BUSINESS: "http://localhost:3000/integrations/ghl/business",
      AGENCY: "http://localhost:3020/integrations/ghl/agency",
      SUPER_ADMIN: "http://localhost:3000/integrations/ghl/sadmin",
      ASSOCIATE: "http://localhost:3000/integrations/ghl/associate",
    },
  },
  STRIPE: {
    PUBLIC_KEY:
      "pk_test_51KqzMeJpY5RIkHqhQTEaWGbWOPKfH9HKwCkMyQtcLAEZh2CsNcSynu4KcsSyZkAMavpcx438MDQHs05uk81dHQ8W00A89oVzEk",
    CLIENT_ID: "ca_Mrj5xmu7CRAeF3mF55TV6vKvgSCJCUQJ",
  },
};

// if(process.env.REACT_APP_ENV === Environment.DEVELOPMENT){
// }

if (process.env.REACT_APP_ENV === Environment.STAGING) {
  env.API_URL = "https://api.appifyhub.co";
}

if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env.API_URL = "https://api.appifyhub.co";
}

export default env;

// ya29.a0Ad52N38EmtuFzlKGKjZvjJ-f5MMb3Foo_QFRQXBJxV6Eurb2L4lCKeSjGZ8ujMEVe2yfwdQ9hV-hAxp5uPaUsoIPnz7PfRuu1zjCh4JmupntBmsLGZyZxAupexAnHOk-H_Y8GYxk6K6BUd-EVPlq3dot57tbKQFH6QaCgYKAeASARESFQHGX2MiaPpc0qdecBzBYm8Z4UO4Ow0169

// latest
// ya29.a0Ad52N39cg76YXNiWTA4RhDE45AIWDAV_OG57-C31HxvCJG4q4k9U0HZBq6fiCZP97WhXQSvMr_Hl62H0TKXw5oSrY6ToGtSP5KT_PaDUTLdVfaqsDkeZo3Kk8Hw24rgqM_XXJQL9G2OWUKuwKN2luOPZjJ_vaY98me6DaCgYKAUESARESFQHGX2Mignkn9UPA8cvRljNEn_Rc7Q0171
