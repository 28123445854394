import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export default function NotificationModal({ show, handleClose, selectedNotify }) {
    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedNotify?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: selectedNotify?.description }}></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>Understood</Button>
                </Modal.Footer>
            </Modal >
        </>
    )
}