import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

const MenuElements = () => {
  const [quickActionBackground, setQuickActionBackground] = useState("#ffffff");
  const [quickActionBorderColor, setQuickActionBorderColor] =
    useState("#ffffff");
  const [quickActionColor, setQuickActionColor] = useState("#ffffff");
  const [quickActionBackgroundHover, setQuickActionBackgroundHover] =
    useState("#ffffff");
  const [quickActionBorderColorHover, setQuickActionBorderColorHover] =
    useState("#ffffff");
  const [quickActionColorHover, setQuickActionColorHover] = useState("#ffffff");
  const [locationSwitcherColor, setLocationSwitcherColor] = useState("#ffffff");
  const [subHeadingColor, setSubHeadingColor] = useState("#ffffff");
  const [tooltipBackground, setTooltipBackground] = useState("#ffffff");
  const [tooltipColor, setTooltipColor] = useState("#ffffff");
  const [backButtonBackground, setBackButtonBackground] = useState("#ffffff");
  const [backButtonColor, setBackButtonColor] = useState("#ffffff");
  const [hideSettingsLabels, setHideSettingsLabels] = useState(false);
  const [hideMenuDividers, setHideMenuDividers] = useState(false);

  return (
    <>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Quick Action Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={quickActionBackground}
                  name="quickActionBackground"
                  onChange={(e) => setQuickActionBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={quickActionBackground}
                  onChange={(e) => setQuickActionBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Quick Action Border Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={quickActionBorderColor}
                  name="quickActionBorderColor"
                  onChange={(e) => setQuickActionBorderColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={quickActionBorderColor}
                  onChange={(e) => setQuickActionBorderColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Quick Action Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={quickActionColor}
                  name="quickActionColor"
                  onChange={(e) => setQuickActionColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={quickActionColor}
                  onChange={(e) => setQuickActionColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Quick Action Background (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={quickActionBackgroundHover}
                  name="quickActionBackgroundHover"
                  onChange={(e) =>
                    setQuickActionBackgroundHover(e.target.value)
                  }
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={quickActionBackgroundHover}
                  onChange={(e) =>
                    setQuickActionBackgroundHover(e.target.value)
                  }
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Quick Action Border Color (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={quickActionBorderColorHover}
                  name="quickActionBorderColorHover"
                  onChange={(e) =>
                    setQuickActionBorderColorHover(e.target.value)
                  }
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={quickActionBorderColorHover}
                  onChange={(e) =>
                    setQuickActionBorderColorHover(e.target.value)
                  }
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Quick Action Color (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={quickActionColorHover}
                  name="quickActionColorHover"
                  onChange={(e) => setQuickActionColorHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={quickActionColorHover}
                  onChange={(e) => setQuickActionColorHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Location Switcher Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={locationSwitcherColor}
                  name="locationSwitcherColor"
                  onChange={(e) => setLocationSwitcherColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={locationSwitcherColor}
                  onChange={(e) => setLocationSwitcherColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Sub Heading Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={subHeadingColor}
                  name="subHeadingColor"
                  onChange={(e) => setSubHeadingColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={subHeadingColor}
                  onChange={(e) => setSubHeadingColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Tooltip Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={tooltipBackground}
                  name="tooltipBackground"
                  onChange={(e) => setTooltipBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={tooltipBackground}
                  onChange={(e) => setTooltipBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Tooltip Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={tooltipColor}
                  name="tooltipColor"
                  onChange={(e) => setTooltipColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={tooltipColor}
                  onChange={(e) => setTooltipColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Back Button Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={backButtonBackground}
                  name="backButtonBackground"
                  onChange={(e) => setBackButtonBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={backButtonBackground}
                  onChange={(e) => setBackButtonBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Back Button Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={backButtonColor}
                  name="backButtonColor"
                  onChange={(e) => setBackButtonColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={backButtonColor}
                  onChange={(e) => setBackButtonColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Label>Hide Settings Labels</Form.Label>
            <div>
              <Form.Check
                type="switch"
                name="hideSettingsLabels"
                id="menu-icon-shite"
                checked={hideSettingsLabels} 
                onChange={(e) => setHideSettingsLabels(e.target.checked)}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Label>Hide Menu Dividers</Form.Label>
            <div>
              <Form.Check
                type="switch"
                name="hideMenuDividers"
                id="menu-icon-shite"
                checked={hideMenuDividers} 
                onChange={(e) => setHideMenuDividers(e.target.checked)}
              />
            </div>
          </div>
        </Col>
        
      </Row>
    </>
  );
};

export default MenuElements;
