import React, { useState } from "react";
import { Form, InputGroup} from "react-bootstrap";

const ImagesCustomization = () => {
  const [bodyImageURL, setBodyImageURL] = useState("");
  const [loginBoxURL, setLoginBoxURL] = useState("");
  const [logoHeight, setLogoHeight] = useState("200");
  const [logoWidth, setLogoWidth] = useState("200");
  const [logoTopMargin, setLogoTopMargin] = useState("-5px");

  const handleInputChange = (event, setter) => {
    const newValue = event.target.value;
    setter(newValue);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setBodyImageURL(imageURL);
    }
  };
  const handleLogoUpload = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const logoURL = URL.createObjectURL(file);
      setLoginBoxURL(logoURL);
    }
  };



  return (
    <div className="row row-cols-1  row-cols-md-2 p-3">
      <div className="col">
        <div className="border p-3 border-[#e0e6ed] dark:border-[#1b2e4b] dark:bg-[#191e3a]">
          <h3 className="fs-6">Body Image</h3>
          <div className="custom-file-container">
            <Form.Label htmlFor="bodyImageUpload" className="cursor-pointer">
              Upload
            </Form.Label>
            <Form.Control
              type="file"
              id="bodyImageUpload"
              name="bodyImageUpload"
              accept="image/*"
              className="bg-transparent border-none outline-none"
              onChange={handleImageUpload}
            />
          </div>
          <InputGroup className="mt-3">
            <InputGroup.Text>URL</InputGroup.Text>
            <Form.Control
              name="bodyImageUpload"
              value={bodyImageURL}
              onChange={(e) => handleInputChange(e, setBodyImageURL)}
              aria-label="Body Image Upload"
            />
          </InputGroup>
        </div>
      </div>
      <div className="col">
        <div className="border p-3 border-[#e0e6ed] dark:border-[#1b2e4b] dark:bg-[#191e3a]">
          <h3 className="fs-6">Login Box Logo</h3>
          <div className="custom-file-container">
            <Form.Label htmlFor="loginBoxLogoURL" className="cursor-pointer">
              Upload
            </Form.Label>
            <Form.Control
              type="file"
              id="loginBoxLogoURL"
              name="loginBoxLogoURL"
              accept="image/*"
              className="bg-transparent border-none outline-none"
              onChange={handleLogoUpload}
            />
          </div>
          <InputGroup className="mt-3">
            <InputGroup.Text>URL</InputGroup.Text>
            <Form.Control
              name="loginBoxLogoURL"
              value={loginBoxURL}
              onChange={(e) => handleInputChange(e, setLoginBoxURL)}
              aria-label="Custom Logo URL"
            />
          </InputGroup>
        </div>
      </div>
      <div className="col">
        <div className="border p-3 border-[#e0e6ed] dark:border-[#1b2e4b] dark:bg-[#191e3a]">
          <h3 className="fs-6">Login Box Logo Height</h3>
          <Form.Control
            value={logoHeight}
            onChange={(e) => handleInputChange(e, setLogoHeight)}
            type="text"
            name="loginBoxLogoHeight"
            placeholder="Logo Height"
          />
        </div>
      </div>
      <div className="col">
        <div className="border p-3 border-[#e0e6ed] dark:border-[#1b2e4b] dark:bg-[#191e3a]">
          <h3 className="fs-6">Login Box Logo Width</h3>
          <Form.Control
            value={logoWidth}
            onChange={(e) => handleInputChange(e, setLogoWidth)}
            type="text"
            name='loginBoxLogoWidth'
            placeholder="Logo Width"
          />
        </div>
      </div>
      <div className="col">
        <div className="border p-3 border-[#e0e6ed] dark:border-[#1b2e4b] dark:bg-[#191e3a]">
          <h3 className="fs-6">Logo Top Margin (minus to raise higher)</h3>
          <Form.Control
            value={logoTopMargin}
            onChange={(e) => handleInputChange(e, setLogoTopMargin)}
            type="text"
            name='logoTopMargin'
            placeholder="Logo Width"
          />
        </div>
      </div>
    </div>
  );
};

export default ImagesCustomization;
