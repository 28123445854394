import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";


const SecondaryTopNavigation = () => {
  const [insideTopMenuItemColor, setInsideTopMenuItemColor] =
    useState("##161313");
  const [insideTopMenuItemActiveColor, setInsideTopMenuItemActiveColor] =
    useState("#eeeeee");
  const [insideTopMenuItemBorder, setInsideTopMenuItemBorder] =
    useState("#eeeeee");
  const [insideTopMenuItemActiveBorder, setInsideTopMenuItemActiveBorder] =
    useState("##6c8599");
  const [insideTopMenuItemIconBackground, setInsideTopMenuItemIconBackground] =
    useState("#eeeeee");
  const [insideTopSubmenuItemColor, setInsideTopSubmenuItemColor] =
    useState("#eeeeee");
  const [insideTopSubmenuItemColorActive, setInsideTopSubmenuItemColorActive] =
    useState("#c97e7e");

  return (
    <>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Inside Top Menu Item Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={insideTopMenuItemColor}
                  name="insideTopMenuItemColor"
                  onChange={(e) => setInsideTopMenuItemColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={insideTopMenuItemColor}
                  onChange={(e) => setInsideTopMenuItemColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Inside Top Menu Item Active Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={insideTopMenuItemActiveColor}
                  name="insideTopMenuItemActiveColor"
                  onChange={(e) =>
                    setInsideTopMenuItemActiveColor(e.target.value)
                  }
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={insideTopMenuItemActiveColor}
                  onChange={(e) =>
                    setInsideTopMenuItemActiveColor(e.target.value)
                  }
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Inside Top Menu Item Border & Divider
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={insideTopMenuItemBorder}
                  name="insideTopMenuItemBorder"
                  onChange={(e) => setInsideTopMenuItemBorder(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={insideTopMenuItemBorder}
                  onChange={(e) => setInsideTopMenuItemBorder(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Inside Top Menu Item Active Border
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={insideTopMenuItemIconBackground}
                  name="insideTopMenuItemIconBackground"
                  onChange={(e) =>
                    setInsideTopMenuItemIconBackground(e.target.value)
                  }
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={insideTopMenuItemIconBackground}
                  onChange={(e) =>
                    setInsideTopMenuItemIconBackground(e.target.value)
                  }
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Inside Top Submenu Item Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={insideTopSubmenuItemColor}
                  name="insideTopSubmenuItemColor"
                  onChange={(e) => setInsideTopSubmenuItemColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={insideTopSubmenuItemColor}
                  onChange={(e) => setInsideTopSubmenuItemColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Inside Top Menu Item Active Border
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={insideTopMenuItemActiveBorder}
                  name="insideTopMenuItemActiveBorder"
                  onChange={(e) =>
                    setInsideTopMenuItemActiveBorder(e.target.value)
                  }
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={insideTopMenuItemActiveBorder}
                  onChange={(e) =>
                    setInsideTopMenuItemActiveBorder(e.target.value)
                  }
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Inside Top Submenu Item Color (Active)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={insideTopSubmenuItemColorActive}
                  name="insideTopSubmenuItemColorActive"
                  onChange={(e) => setInsideTopSubmenuItemColorActive(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={insideTopSubmenuItemColorActive}
                  onChange={(e) => setInsideTopSubmenuItemColorActive(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
    
      </Row>
    </>
  );
};

export default SecondaryTopNavigation;
