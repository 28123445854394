import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

const Buttons = () => {
  const [primaryButtonsBackground, setPrimaryButtonsBackground] =
    useState("#006aac");
  const [primaryButtonsBackgroundHover, setPrimaryButtonsBackgroundHover] =
    useState("#004d7c");
  const [primaryButtonsBorder, setPrimaryButtonsBorder] = useState("#006aac");
  const [primaryButtonsBorderHover, setPrimaryButtonsBorderHover] =
    useState("#006aac");
  const [primaryButtonsColor, setPrimaryButtonsColor] = useState("#006aac");
  const [primaryButtonsColorHover, setPrimaryButtonsColorHover] =
    useState("#006aac");

  return (
    <>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Primary Buttons Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={primaryButtonsBackground}
                  name="primaryButtonsBackground"
                  onChange={(e) => setPrimaryButtonsBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={primaryButtonsBackground}
                  onChange={(e) => setPrimaryButtonsBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Primary Buttons Background (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={primaryButtonsBackgroundHover}
                  name="primaryButtonsBackgroundHover"
                  onChange={(e) =>
                    setPrimaryButtonsBackgroundHover(e.target.value)
                  }
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={primaryButtonsBackgroundHover}
                  onChange={(e) =>
                    setPrimaryButtonsBackgroundHover(e.target.value)
                  }
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Primary Buttons Border
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={primaryButtonsBorder}
                  name="primaryButtonsBorder"
                  onChange={(e) => setPrimaryButtonsBorder(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={primaryButtonsBorder}
                  onChange={(e) => setPrimaryButtonsBorder(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Primary Buttons Border (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={primaryButtonsBorderHover}
                  name="primaryButtonsBorderHover"
                  onChange={(e) =>
                    setPrimaryButtonsBorderHover(e.target.value)
                  }
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={primaryButtonsBorderHover}
                  onChange={(e) =>
                    setPrimaryButtonsBorderHover(e.target.value)
                  }
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Primary Buttons Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={primaryButtonsColor}
                  name="primaryButtonsColor"
                  onChange={(e) => setPrimaryButtonsColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={primaryButtonsColor}
                  onChange={(e) => setPrimaryButtonsColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Primary Buttons Color (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={primaryButtonsColorHover}
                  name="primaryButtonsColorHover"
                  onChange={(e) =>
                    setPrimaryButtonsColorHover(e.target.value)
                  }
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={primaryButtonsColorHover}
                  onChange={(e) =>
                    setPrimaryButtonsColorHover(e.target.value)
                  }
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default Buttons;
