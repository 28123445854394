/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// Material Dashboard 2 React routes
import routes from "./routes";
import Sidebar from "../../../examples/Sidenav";

export default function Authentication() {
  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar"];
  const { pathname } = useLocation();

  const bc = document.body.classList;
  // alert(pathname.split('/')[1])
  // set sidebar to offset
  offsets.includes(pathname)
    ? bc.add("sidebar-offset")
    : bc.remove("sidebar-offset");

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  return (
    <>
      <Sidebar routes={routes} />
      <div className="main main-app p-3 p-lg-4">

        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/auth/sign-in" />} />
        </Routes>
      </div>
    </>
  );
}
