import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

const LocationSwitcher = () => {
  const [inputBackground, setInputBackground] = useState("#813636");
  const [inputBorder, setInputBorder] = useState("#813636");
  const [inputColor, setInputColor] = useState("#222222");
  const [buttonBackground, setButtonBackground] = useState("#95c095");
  const [buttonBorder, setButtonBorder] = useState("#95c095");
  const [buttonHoverBackground, setButtonHoverBackground] = useState("#b596c0");
  const [buttonHoverBorder, setButtonHoverBorder] = useState("#95c095");
  const [quickActionBlackBolt, setQuickActionBlackBolt] = useState(false);
  const [quickActionWhiteBolt, setQuickActionWhiteBolt] = useState(false);
  const [quickActionHoverBlackBolt, setQuickActionHoverBlackBolt] =
    useState(false);
  const [quickActionHoverWhiteBolt, setQuickActionHoverWhiteBolt] =
    useState(false);

  return (
    <>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Location Switcher Input Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={inputBackground}
                  name="inputBackground"
                  onChange={(e) => setInputBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={inputBackground}
                  onChange={(e) => setInputBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Location Switcher Border
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={inputBorder}
                  name="inputBorder"
                  onChange={(e) => setInputBorder(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={inputBorder}
                  onChange={(e) => setInputBorder(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Location Switcher Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={inputColor}
                  name="inputColor"
                  onChange={(e) => setInputColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={inputColor}
                  onChange={(e) => setInputColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Quick Action Button Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={buttonBackground}
                  name="quickActionbuttonBackground"
                  onChange={(e) => setButtonBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={buttonBackground}
                  onChange={(e) => setButtonBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Quick Action Button Border
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={buttonBorder}
                  name="quickActionbuttonBorder"
                  onChange={(e) => setButtonBorder(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={buttonBorder}
                  onChange={(e) => setButtonBorder(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Quick Action Button Hover Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={buttonHoverBackground}
                  name="quickActionbuttonHoverBackground"
                  onChange={(e) => setButtonHoverBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={buttonHoverBackground}
                  onChange={(e) => setButtonHoverBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Quick Action Button Hover Border
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={buttonHoverBorder}
                  name="quickActionbuttonHoverBorder"
                  onChange={(e) => setButtonHoverBorder(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={buttonHoverBorder}
                  onChange={(e) => setButtonHoverBorder(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col>
          <div
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Label>Quick Action Black Lightning Bolt</Form.Label>
            <div>
              <Form.Check
                type="switch"
                name="quickActionBlackBolt"
                id="quickActionBlackBolt"
                checked={quickActionBlackBolt}
                onChange={(e) => setQuickActionBlackBolt(e.target.checked)}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Label>Quick Action White Lightning Bolt</Form.Label>
            <div>
              <Form.Check
                type="switch"
                name="quickActionWhiteBolt"
                id="quickActionWhiteBolt"
                checked={quickActionWhiteBolt}
                onChange={(e) => setQuickActionWhiteBolt(e.target.checked)}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Label>Quick Action Hover Black Lightning Bolt</Form.Label>
            <div>
              <Form.Check
                type="switch"
                name="quickActionHoverBlackBolt"
                id="quickActionHoverBlackBolt"
                checked={quickActionHoverBlackBolt}
                onChange={(e) => setQuickActionHoverBlackBolt(e.target.checked)}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div
            
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Label>Quick Action Hover White Lightning Bolt</Form.Label>
            <div>
              <Form.Check
                type="switch"
                name="quickActionHoverWhiteBolt"
                id="quickActionHoverWhiteBolt"
                checked={quickActionHoverWhiteBolt}
                onChange={(e) => setQuickActionHoverWhiteBolt(e.target.checked)}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LocationSwitcher;
