import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";

const DarkTheme = () => {
  const [darkThemeToggle, setDarkThemeToggle] = useState(false);

  const handleToggle = () => {
    setDarkThemeToggle(prevState => !prevState);
  };

  useEffect(() => {
    const logDarkThemeStatus = (status) => {
    };
    logDarkThemeStatus(darkThemeToggle);
  }, [darkThemeToggle]);

  return (
    <>
      <div className="p-3">
        <div className="row row-cols-1 row-cols-md-2">
          <div className="col">
            <div className="border p-3 border-[#e0e6ed] dark:border-[#1b2e4b] dark:bg-[#191e3a]">

            <div className="d-flex flex-column gap-2">
              <Form.Label className="mb-0">
                Enable Dark Theme Toggle
              </Form.Label>
              <Form.Text className="mb-0">
                Enable Dark Theme Toggle Displays a toggle inside the Profile
                top right menu which let user to switch theme to Dark and vice
                versa.
              </Form.Text>
              <Form.Check
                type="switch"
                id="darkThemeToggle"
                name="darkThemeToggle"
                checked={darkThemeToggle}
                onChange={handleToggle}
              />
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DarkTheme;
