import React, { useState } from 'react'
import { Form, Row, Col } from "react-bootstrap";

const TopHeader = () => {
    const [headerBackground, setHeaderBackground] = useState("#eeeeee");
    const [headerControlButtonsBorder, setHeaderControlButtonsBorder] = useState("#c57d7d");
    const [headerControlButtonsShadow, setHeaderControlButtonsShadow] = useState("#c57d7d");


  return (
    <>
     <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Header Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={headerBackground}
                  name="headerBackground"
                  onChange={(e) => setHeaderBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={headerBackground}
                  onChange={(e) => setHeaderBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Header Control Buttons Border
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={headerControlButtonsBorder}
                  name="headerControlButtonsBorder"
                  onChange={(e) => setHeaderControlButtonsBorder(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={headerControlButtonsBorder}
                  onChange={(e) => setHeaderControlButtonsBorder(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
     <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Header Control Buttons Shadow (use .1 or none)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={headerControlButtonsShadow}
                  name="headerControlButtonsShadow"
                  onChange={(e) => setHeaderControlButtonsShadow(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={headerControlButtonsShadow}
                  onChange={(e) => setHeaderControlButtonsShadow(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      
      </Row>
    </>
  )
}

export default TopHeader