import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useAppServices } from "../../../../../../hook/services";

function DashboardButtonModal({ sso_cred }) {
  console.log("Company ID",sso_cred.companyId)
  const [showDashboardModal, setShowDashboardModal] = useState(false);
  const [showAddButtonModal, setShowAddButtonModal] = useState(false);
  const [buttonText, setButtonText] = useState("Sample Button");
  const [buttonColor, setButtonColor] = useState("");
  const [buttonType, setButtonType] = useState("TARGET");
  const [linkValue, setLinkValue] = useState("");
  const [targetLocation, setTargetLocation] = useState("");
  const [savedButtons, setSavedButtons] = useState([]);
  const [editingButtonIndex, setEditingButtonIndex] = useState(null);
  const [showLinkInput, setShowLinkInput] = useState(false);
  const [openButtonId, setOpenButtonId] = useState(null);
  const AppService = useAppServices();
  const handleCloseDashboard = () => setShowDashboardModal(false);
  const handleShowDashboard = () => setShowDashboardModal(true);
  async function GetData() {
    const { response } = await AppService.ghlDashboardButton.get({
      query: `company_id=${sso_cred.companyId}`,
    });
    console.log(response.data);
    setSavedButtons(response.data);
  }
  useEffect(() => {
    GetData();
  }, [sso_cred]);

  const handleCloseAddButton = () => {
    setShowAddButtonModal(false);
    setLinkValue("");
  };

  const handleShowAddButton = () => {
    handleCloseDashboard();
    setShowAddButtonModal(true);
  };

  const handleBackToAddButton = () => {
    setOpenButtonId(null);
    handleCloseAddButton();
    handleShowDashboard();
  };

  const handleTextChange = (event) => {
    setButtonText(event.target.value);
  };

  const handleColorChange = (event) => {
    setButtonColor(event.target.value);
  };

  const handleTypeChange = (event) => {
    const type = event.target.value;
    setButtonType(type);
    setLinkValue("");
    setShowLinkInput(type === "LINK");
  };

  const handleLinkChange = (event) => {
    setLinkValue(event.target.value);
  };

  const handleTargetLocationChange = (event) => {
    setTargetLocation(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setShowLinkInput(event.target.checked);
  };

  const handleSaveButton = async () => {
    console.log("asdfhaslhfasdglhafs")
    console.log(buttonType,"button type")

    let link = '';
    if (buttonType === "LINK") {
        link = linkValue;
        console.log("l;ink",link)
        if (!link || !link.startsWith('https://')) {
          console.log("link not correct")
          if(document.getElementById("formLink")){
            console.log("formLink found")

            document.getElementById("formLink").style.borderColor = "red";
            document.getElementById("validLink").style.display = "block";
          }

            return;
        }
    }
    if(document.getElementById("formLink")){
      
          document.getElementById("formLink").style.borderColor = "#E2E5EC";
              document.getElementById("validLink").style.display = "none";

    }

    const payload = {
      company_id: sso_cred.companyId,
      text: buttonText,
      color: buttonColor,
      type: buttonType,
      link: buttonType === "TARGET" ? null : linkValue,
      targetLocation: buttonType === "TARGET" ? targetLocation : null,
      linkTarget: buttonType === "TARGET" && showLinkInput ? linkValue : null,
      id:
        editingButtonIndex !== null
          ? savedButtons[editingButtonIndex].id
          : Date.now(),
    };
    console.log(payload,"payload")
    const { response } = await AppService.ghlDashboardButton.create({
      payload: payload,
    });
    const newButton = {
      company_id:sso_cred.companyId,
      text: buttonText,
      color: buttonColor,
      type: buttonType,
      link: buttonType === "TARGET" ? null : linkValue,
      targetLocation: buttonType === "TARGET" ? targetLocation : null,
      linkTarget: buttonType === "TARGET" && showLinkInput ? linkValue : null,
      id:
        editingButtonIndex !== null
          ? savedButtons[editingButtonIndex].id
          : Date.now(),
    };
    console.log("button", newButton);
    let updatedButtons = [...savedButtons];
    if (editingButtonIndex !== null) {
      updatedButtons[editingButtonIndex] = newButton;
    } else {
      updatedButtons.push(newButton);
    }
    GetData();
    setSavedButtons(updatedButtons);
    handleCloseAddButton();
    handleShowDashboard();
    setEditingButtonIndex(null);
    setOpenButtonId(null);
    console.log("Payload:", newButton);
  };
  const handleEditButton = async (e) => {
    let link = '';
    if (buttonType === "LINK") {
        link = linkValue;
        console.log("l;ink",link)
        if (!link || !link.startsWith('https://')) {
          console.log("link not correct")
          if(document.getElementById("formLink")){
            console.log("formLink found")

            document.getElementById("formLink").style.borderColor = "red";
            document.getElementById("validLink").style.display = "block";
          }

            return;
        }
    }
    if(document.getElementById("formLink")){
      
          document.getElementById("formLink").style.borderColor = "#E2E5EC";
              document.getElementById("validLink").style.display = "none";

    }
  



    e.preventDefault();
    const payload = {
      _id: openButtonId,
      text: buttonText,
      color: buttonColor,
      type: buttonType,
      link: buttonType === "TARGET" ? null : linkValue,
      targetLocation: buttonType === "TARGET" ? targetLocation : null,
      linkTarget: buttonType === "TARGET" && showLinkInput ? linkValue : null,
      id:
        editingButtonIndex !== null
          ? savedButtons[editingButtonIndex].id
          : Date.now(),
    };
    console.log("apay", payload);
    // Object.assign(tempButtons[selctedButton.index], payload);
    const { response } = await AppService.ghlDashboardButton.update({
      payload: payload,
    });
    console.log(response);
    // GetData();
    // setSavedButtons(updatedButtons);
    // handleCloseAddButton();
    // handleShowDashboard();
    // setEditingButtonIndex(null);
    setOpenButtonId(null);
  };

  const handleDeleteButton = async () => {
    let updatedButtons = [...savedButtons];
    if (editingButtonIndex !== null) {
      updatedButtons.splice(editingButtonIndex, 1);
      setSavedButtons(updatedButtons);
    }
    handleCloseAddButton();
    handleShowDashboard();
    setEditingButtonIndex(null);
    const { response } = await AppService.ghlDashboardButton.delete({
      query: `_id=${openButtonId}`,
    });
  };

  const handleEditButtonClick = (buttonIndex) => {
    setEditingButtonIndex(buttonIndex);
    const buttonToEdit = savedButtons[buttonIndex];
    setButtonText(buttonToEdit.text);
    setButtonColor(buttonToEdit.color);
    setButtonType(buttonToEdit.type);
    setLinkValue(buttonToEdit.link || "");
    setTargetLocation(buttonToEdit.targetLocation || "");
    setShowLinkInput(buttonToEdit.type === "LINK");
    handleShowAddButton();
  };

  const targetLocations = [
    "launchpad",
    "dashboard",
    "conversations/conversations",
    "conversations/manual_actions",
    "conversations/templates",
    "conversations/links/link",
    "conversations/links/analyze",
    "calendars/view",
    "calendars/appointments",
    "settings/calendars",
    "contacts/smart_list/All",
    "contacts/bulk/actions",
    "contacts/restore",
    "tasks",
    "businesses",
    "settings/smart_list/Settings",
    "opportunities/list",
    "opportunities/pipeline",
    "payments/invoices",
    "payments/recurring-templates",
    "payments/proposals-estimates",
    "payments/v2/orders",
    "payments/v2/subscriptions",
    "payments/v2/transactions",
    "payments/products",
    "payments/coupons",
    "payments/settings/receipts",
    "payments/v2/taxes",
    "payments/integrations",
    "marketing/social-planner",
    "marketing/emails/scheduled",
    "marketing/emails/all",
    "marketing/templates",
    "marketing/links/link",
    "marketing/links/analyze",
    "marketing/affiliate-manager/campaigns",
    "marketing/affiliate-manager/affiliates",
    "marketing/affiliate-manager/payouts",
    "marketing/affiliate-manager/media",
    "automation/workflows",
    "automation/campaigns",
    "automation/triggers",
    "funnels-websites/funnels",
    "funnels-websites/websites",
    "blogs",
    "funnels-websites/client-portal/domain-setup",
    "funnels-websites/client-portal/branding",
    "funnels-websites/wordpress/wordpress_dashboard-v2",
    "form-builder/main",
    "form-builder/analyze",
    "form-builder/submissions",
    "survey-builder/main",
    "survey-builder/analyze",
    "survey-builder/submissions",
    "funnels-websites/chat_widget_settings",
    "settings/media-settings",
    "settings/redirect",
    "memberships/courses/dashboard",
    "memberships/courses/products",
    "memberships/courses/offers",
    "memberships/courses/analytics",
    "memberships/courses/settings",
    "memberships/communities/community-groups",
    "memberships/communities/clientportal-domain-setup",
    "reputation/overview",
    "reputation/requests",
    "reputation/reviews",
    "reputation/listing",
    "reporting/google",
    "reporting/facebook",
    "reporting/attribution/conversion_report",
    "reporting/attribution/source_report",
    "reporting/attribution-v2",
    "reporting/call_stats",
    "reporting/appointments-reports",
    "settings/company",
    "settings/staff/team",
    "settings/staff/teams",
    "settings/pipeline",
    "settings/businesses",
    "settings/calendars/preferences",
    "settings/calendars/availability",
    "settings/calendars/connections",
    "settings/phone_number",
    "settings/reputation/settings",
    "settings/fields",
    "settings/custom_values",
    "settings/conversation_providers",
    "settings/domain",
    "settings/integrations/list",
    "settings/integrations/facebook",
    "settings/integrations/tiktok",
    "settings/smtp_service",
    "settings/tags",
    "settings/labs",
    "settings/audit/logs",
  ];

  return (
    <>
      <Button variant="primary" onClick={handleShowDashboard}>
        Dashboard Button
      </Button>

      <Modal show={showDashboardModal} onHide={handleCloseDashboard}>
        <Modal.Header closeButton>
          <Modal.Title>Dashboard Shortcut Buttons</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {savedButtons.map((button, index) => (
            <Button
              key={button.id}
              variant="success"
              style={{ backgroundColor: button.color, margin: "2px 5px" }}
              onClick={() => {
                handleEditButtonClick(index);
                setOpenButtonId(button._id);
              }}
            >
              {button.text}
            </Button>
          ))}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDashboard}>
            Close
          </Button>
          <Button variant="primary" onClick={handleShowAddButton}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddButtonModal} onHide={handleCloseAddButton}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingButtonIndex !== null
              ? "Updated Dashboard Button"
              : "Add Dashboard Shortcut Buttons"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="preview-section mb-3">
              <label className="form-label d-block mb-2">
                <strong>PREVIEW</strong>
              </label>
              <Button
                variant="success"
                size="sm"
                style={{ backgroundColor: buttonColor, width: "100%" }}
              >
                {buttonText}
              </Button>
            </div>

            <div className="mb-3">
              <label className="form-label">Text</label>
              <input
                type="text"
                className="form-control"
                placeholder="Sample Button"
                value={buttonText}
                onChange={handleTextChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Color</label>
              <input
                type="color"
                className="form-control form-control-color"
                defaultValue="#28a745"
                title="Choose your color"
                onChange={handleColorChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Type</label>
              <select
                className="form-select"
                onChange={handleTypeChange}
                value={buttonType}
              >
                <option value="TARGET">TARGET</option>
                <option value="LINK">LINK</option>
              </select>
            </div>

            {buttonType === "LINK" && (
              <div className="mb-3">
                <label className="form-label" >Paste Link Here</label>
                <input
                id="formLink"
                  type="text"
                  className="form-control"
                  placeholder="Paste link here"
                  value={linkValue}
                  onChange={handleLinkChange}
                />
                <p id="validLink" style={{color:"red",display:"none"}}>Please Enter a valid Url</p>
              </div>
            )}

            {buttonType === "TARGET" && !showLinkInput && (
              <>
                <div className="mb-3">
                  <label className="form-label">Target Location</label>
                  <select
                    className="form-select"
                    id="targetLocation"
                    onChange={handleTargetLocationChange}
                    value={targetLocation}
                  >
                    {targetLocations.map((location, index) => (
                      <option key={index}>{location}</option>
                    ))}
                  </select>
                </div>
                <div className="mb-4 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="pasteLinkTarget"
                    checked={showLinkInput}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label" htmlFor="pasteLinkTarget">
                    Paste link target
                  </label>
                </div>
              </>
            )}
            {buttonType === "TARGET" && showLinkInput && (
              <div className="mb-3">
                <label className="form-label">Paste Target Link Here</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Paste target link here"
                  value={linkValue}
                  onChange={handleLinkChange}
                />
              </div>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          {editingButtonIndex !== null && (
            <Button variant="danger" onClick={handleDeleteButton}>
              Delete
            </Button>
          )}
          <Button variant="secondary" onClick={handleBackToAddButton}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={openButtonId ? handleEditButton : handleSaveButton}
            // onClick={handleEditButton}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DashboardButtonModal;
