import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

const InsideWrapper = () => {
  const [appBackgroundColor, setAppBackgroundColor] = useState("#eeeeee");
  const [appBorderColor, setAppBorderColor] = useState("#b15d5d");
  const [headerTitleColor, setHeaderTitleColor] = useState("#dbc7c7");
  const [headerDescriptionColor, setHeaderDescriptionColor] =
    useState("#da0707");
  const [tableHeaderTextColor, setTableHeaderTextColor] = useState("#471010");
  const [tableHeaderBackground, setTableHeaderBackground] = useState("#471010");

  return (
    <>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              App Background Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={appBackgroundColor}
                  name="appBackgroundColor"
                  onChange={(e) => setAppBackgroundColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={appBackgroundColor}
                  onChange={(e) => setAppBackgroundColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              App Border Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={appBorderColor}
                  name="appBorderColor"
                  onChange={(e) => setAppBorderColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={appBorderColor}
                  onChange={(e) => setAppBorderColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Header Title Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={headerTitleColor}
                  name="headerTitleColor"
                  onChange={(e) => setHeaderTitleColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={headerTitleColor}
                  onChange={(e) => setHeaderTitleColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Header Description Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={headerDescriptionColor}
                  name="headerDescriptionColor"
                  onChange={(e) => setHeaderDescriptionColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={headerDescriptionColor}
                  onChange={(e) => setHeaderDescriptionColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Table Header Text Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={tableHeaderTextColor}
                  name="tableHeaderTextColor"
                  onChange={(e) => setTableHeaderTextColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={tableHeaderTextColor}
                  onChange={(e) => setTableHeaderTextColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Table Header Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={tableHeaderBackground}
                  name="tableHeaderBackground"
                  onChange={(e) => setTableHeaderBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={tableHeaderBackground}
                  onChange={(e) => setTableHeaderBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default InsideWrapper;
