import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import Main from './layouts/Main';
// import NotFound from "./pages/NotFound";

// import publicRoutes from "./routes/PublicRoutes";
// import protectedRoutes from "./routes/ProtectedRoutes";
import useRoutes from "./routes";
import RouteGuard from "./route.guard"
// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";


// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  const routes = useRoutes()
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.guard) {
        const { valid, redirect, state } = route.guard;
        console.log(valid, redirect, state);
        return (
          <Route
            element={<RouteGuard valid={valid} redirect={redirect} state={state} />}
            key={route.key}
          >
            <Route exact path={route.route} element={route.component} />
          </Route>
        );
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Main />}>
            {protectedRoutes.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  element={route.element}
                  key={index}
                />
              )
            })}
          </Route>
          {publicRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={route.element}
                key={index}
              />
            )
          })}
          <Route path="*" element={<NotFound />} /> */}
          {getRoutes(routes)}
          {/* <Route path="*" element={<Navigate to="/auth/sign-in" />} /> */}
        </Routes>
      </BrowserRouter>
    </React.Fragment>

  );
}