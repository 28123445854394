import React, { useEffect, useState } from "react";

import { FaBars } from "react-icons/fa";
// import AddButton from "./components/AddButton";
// import UpdateContactButton from "./components/UpdateButton";
import { useAppServices } from "../../../../../../hook/services";
import {
  Modal,
  Button,
  Form,
  Tab,
  Tabs,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FaBell } from "react-icons/fa";

function ContactButtons({ sso_cred, fetchData, data, isupdate }) {
  const [show, setShow] = useState(false);
  const AppService = useAppServices();
  const [formData, setFormData] = useState({
    text: data?.button_text || "",
    color: false,
    colorPicker: data?.colorPicker || "",
    linkOrCustomValue: data?.linkOrCustomValue || "",
    location: data?.location || "",
  });
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    const updatedLinkOrCustomValue = formData.linkOrCustomValue + selectedValue;
    setFormData((prevState) => ({
      ...prevState,
      linkOrCustomValue: updatedLinkOrCustomValue,
    }));
  };
  const handleSelectLocation = (e) => {
    const selectedValue = e.target.value;

    setFormData((prevState) => ({
      ...prevState,
      location: selectedValue,
    }));
  };
  // function setButtonData() {
  //   console.log(buttonData, "buttonData");
  //   if (buttonData) {
  //     setFormData(buttonData.button_data);
  //     console.log(formData, "formData");
  //   }
  // }
  const [selectedContact, setSelectedContact] = useState("");

  const [buttonData, setButtonData] = useState([]);

  const companyId = "companyId";

  // useEffect(() => {
  //   if (SSO != "" && SSO != undefined) {
  //     var data = JSON.parse(SSO);
  //     if (data?.companyId) {
  //       // getCustomizerData(data?.companyId);
  //       setsso_cred(data);
  //     }
  //     // console.log(data, 'datadata');
  //   }
  //   // console.log(SSO, 'SSOSSO');
  // }, [SSO]);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      if (
        name === "agencyAdmins" ||
        name === "agencyUsers" ||
        name === "accountAdmins" ||
        name === "accountUsers"
      ) {
        setFormData((prevState) => ({
          ...prevState,
          permissions: {
            ...prevState.permissions,
            [name]: checked,
          },
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [name]: checked,
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSave = async () => {
    // console.log(type);
    console.log(formData, "formdata");
    console.log(selectedContact, "selectedContact");

    // return 0;
    if (isupdate) {
      const payload = {
        _id: data._id,
        button_text: formData.text,
        colorPicker: formData.colorPicker,
        linkOrCustomValue: formData.linkOrCustomValue,
        color: formData.color,
        location: formData.location,
      };
      const { response } = await AppService.contactButtonLink.update({
        payload: payload,
      });
      if (response.success) {
        fetchData();
        handleClose();
      }
      console.log(response, "response update");
    } else {
      const payload = {
        button_text: formData.text,
        colorPicker: formData.colorPicker,
        linkOrCustomValue: formData.linkOrCustomValue,
        color: formData.color,
        location: formData.location,
      };
      const { response } = await AppService.contactButtonLink.create({
        payload: payload,
      });
      if (response.success) {
        fetchData();
        handleClose();
      }
      console.log(response, "response create");
    }

    // const payload = {
    //   button_text: formData.text,
    //   colorPicker: formData.colorPicker,
    //   linkOrCustomValue: formData.linkOrCustomValue,
    //   color: formData.color,
    //   location: formData.location,
    // };
    // const { response } = await AppService.contactButtonLink.create({
    //   payload: payload,
    // });
    // if (response.success) {
    //   handleClose();
    // }
    // console.log(response, "response create");

    // fetchData();
    // // onReturn();
  };
  function handleCancel() {
    setFormData({
      text: "",
      color: false,
      colorPicker: "",
      linkOrCustomValue: "",
    });
    setSelectedContact("");
  }
  const options = [
    { label: "Name", value: "{{contact.name}}" },
    { label: "First Name", value: "{{contact.first_name}}" },
    { label: "Last Name", value: "{{contact.last_name}}" },
    { label: "Email", value: "{{contact.email}}" },
    { label: "Phone", value: "{{contact.phone}}" },
    { label: "Raw Phone", value: "{{contact.phone_raw}}" },
    { label: "Company Name", value: "{{contact.company_name}}" },
    { label: "Full Address", value: "{{contact.full_address}}" },
    { label: "Address Line 1", value: "{{contact.address1}}" },
    { label: "City", value: "{{contact.city}}" },
    { label: "State", value: "{{contact.state}}" },
    { label: "Country", value: "{{contact.country}}" },
    { label: "Postal Code", value: "{{contact.postal_code}}" },
    { label: "Date of Birth", value: "{{contact.date_of_birth}}" },
    { label: "Timezone", value: "{{contact.timezone}}" },
    { label: "Source", value: "{{contact.source}}" },
  ];

  const deleteButton = async (id) => {
    console.log("delete");
    const { success, message, data } =
      await AppService.contactButtonLink.delete({ query: `_id=${id}` });
    if (success) {
      fetchData();
      // Perform any additional actions after successful deletion
    } else {
      console.log(message);
      fetchData();
      // Handle error case
    }
  };

  return (
    <>
      <Button
        variant={`${isupdate ? "success" : "primary"}`}
        onClick={handleShow}
      >
        {isupdate ? "update" : "Create Contact Buttons"}
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Contact Buttons</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "100vh", overflowY: "auto" }}>
          <div className="row">
            <div className="col-md-12">
              <Form>
                <Form.Group controlId="formText">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    id="text"
                    type="text"
                    placeholder="Sample Button"
                    onChange={handleChange}
                    name="text"
                    value={formData.text}
                  />
                </Form.Group>

                <Form.Group controlId="formColor">
                  <Form.Check
                    type="checkbox"
                    label="Color"
                    checked={formData.color}
                    onChange={handleChange}
                    name="color"
                    value={formData.color}
                  />

                  <div className="input-group colorpicker">
                    <input
                      type="color"
                      className="form-control"
                      onChange={handleChange}
                      name="colorPicker"
                      value={formData.colorPicker}
                    />
                  </div>
                </Form.Group>
                <Form.Group controlId="formLinkOrCustomValue">
                  <Form.Label>Link or Custom Value</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="https://"
                    onChange={handleChange}
                    name="linkOrCustomValue"
                    value={formData.linkOrCustomValue}
                  />
                </Form.Group>
                <Form.Group controlId="formContact">
                  <Form.Label>Contact</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleSelectChange}
                    name="contact"
                    value={formData.contact}
                  >
                    <option value="">Select Contact</option>
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formColor">
                  <Form.Check
                    type="checkbox"
                    label="Custom Location"
                    checked={formData.showSelect}
                    onChange={handleChange}
                    name="showSelect"
                  />
                  {formData.showSelect && (
                    <Form.Group controlId="formSelect">
                      <Form.Label>Select Option</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={handleSelectLocation}
                        name="selectOption"
                        value={formData.location}
                      >
                        <option value="">Select Option</option>

                        <option value="New York">New York</option>
                        <option value="Los Angeles">Los Angeles</option>
                        <option value="Chicago">Chicago</option>
                        <option value="Houston">Houston</option>
                        <option value="Phoenix">Phoenix</option>
                        <option value="Philadelphia">Philadelphia</option>
                        <option value="San Antonio">San Antonio</option>
                        <option value="San Diego">San Diego</option>
                        <option value="Dallas">Dallas</option>
                        <option value="San Jose">San Jose</option>
                      </Form.Control>
                    </Form.Group>
                  )}
                </Form.Group>
              </Form>
            </div>
          </div>
          <Modal.Footer className="mt-3 d-flex justify-content-end">
            <Button variant="secondary" className="m-2" onClick={handleCancel}>
              Reset
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ContactButtons;
