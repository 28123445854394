import React from "react";
import {
  Modal,
  Button,
  Form,
  Tab,
  Tabs,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FaBell } from "react-icons/fa";

function AddButton({ onReturn }) {
  
  const handleSave = () => {
    onReturn();
  };

  const handleCancel = () => { 
    onReturn();
  };

  return (
    <div className="container-fluid">

      <div className="row">
        <div className="col-md-6">
          <Form>
            <div className="preview-area text-center mb-3">
              <Button variant="success">
                <FaBell className="mr-2" />
                Sample Button
              </Button>
            </div>
            <Form.Group controlId="formFolder">
              <Form.Label>Folder</Form.Label>
              <Form.Control as="select">
                <option>Header - Folder name</option>
                <option>Folder name</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formText">
              <Form.Label>Text</Form.Label>
              <Form.Control type="text" placeholder="Sample Button" />
            </Form.Group>
            <Form.Group controlId="formTooltip">
              <Form.Label>Tooltip (HTML Allowed)</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group controlId="formIcon">
              <Form.Label>Icon</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FaBell />
                </InputGroup.Text>
                <FormControl type="text" placeholder="fas fa-bell" />
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="formColor">
              <Form.Check type="checkbox" label="Color" checked />

              <div className="input-group colorpicker">
                <input type="color" className="form-control" />
              </div>
            </Form.Group>
            <Form.Group controlId="formCSSSelector">
              <Form.Label>CSS Selector</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group>
              <Form.Label>Permissions</Form.Label>
              {["checkbox"].map((type) => (
                <div key={`default-${type}`} className="mb-3">
                  <Form.Check
                    type={type}
                    id={`check-api-${type}`}
                    label="Agency Admins"
                  />
                  <Form.Check
                    type={type}
                    id={`check-api-${type}`}
                    label="Agency Users"
                  />
                  <Form.Check
                    type={type}
                    id={`check-api-${type}`}
                    label="Account Admins"
                  />
                  <Form.Check
                    type={type}
                    id={`check-api-${type}`}
                    label="Account Users"
                  />
                </div>
              ))}
            </Form.Group>
          </Form>
        </div>
        <div className="col-md-6">
          <Tabs
            defaultActiveKey="action"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="action" title="ACTION">
              <Form>
                <Form.Group controlId="formTags">
                  <Form.Label>Tags</Form.Label>
                  <Form.Control type="text" placeholder="Comma separated" />
                </Form.Group>
                <Form.Group controlId="formEnableToggle">
                  <Form.Check type="checkbox" label="Enable Toogle" />
                </Form.Group>
                <Form.Group controlId="formMonitorChanges">
                  <Form.Check type="checkbox" label="Check for tag changes" />
                </Form.Group>
                <fieldset>
                  <Form.Group>
                    <Form.Label>Read/Unread</Form.Label>
                    <Form.Check
                      type="radio"
                      label="Read"
                      name="readUnreadOptions"
                      id="readOption"
                    />
                    <Form.Check
                      type="radio"
                      label="Unread"
                      name="readUnreadOptions"
                      id="unreadOption"
                    />
                  </Form.Group>
                </fieldset>
                <fieldset>
                  <Form.Group>
                    <Form.Label>Starred/Unstarred</Form.Label>
                    <Form.Check
                      type="radio"
                      label="Starred"
                      name="starredOptions"
                      id="starredOption"
                    />
                    <Form.Check
                      type="radio"
                      label="Unstarred"
                      name="starredOptions"
                      id="unstarredOption"
                    />
                  </Form.Group>
                </fieldset>
                <Form.Group controlId="formArchiveConversation">
                  <Form.Check type="checkbox" label="Archive Conversation" />
                </Form.Group>
                <Form.Group controlId="formConfirmationRequired">
                  <Form.Check type="checkbox" label="Confirmation Required" />
                </Form.Group>
              </Form>
            </Tab>
            <Tab eventKey="link" title="LINK">
              <Form>
                <Form.Group controlId="formLinkOrCustomValue">
                  <Form.Label>Link or Custom Value</Form.Label>
                  <Form.Control type="text" placeholder="https://" />
                  <Form.Text className="text-muted">
                    Must contain https:// (Works with custom fields & values)
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="formLockToContact">
                  <Form.Check type="checkbox" label="Lock to Contact?" />
                </Form.Group>
                <Form.Group controlId="formContactDataSent">
                  <Form.Label>Contact Data is sent to the link:</Form.Label>
                  <Form.Control as="select">
                    <option>Through the API</option>
                    <option>Through the URL</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formViewType">
                  <Form.Label>View Type</Form.Label>
                  <Form.Control as="select">
                    <option>Open in New Window</option>
                  </Form.Control>
                </Form.Group>
              </Form>
            </Tab>
          </Tabs>
        </div>
      </div>
      <Modal.Footer className="mt-3 d-flex justify-content-end">
        <Button variant="secondary" className="mr-2" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
      
    </div>
  );
}

export default AddButton;
