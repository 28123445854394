import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import IconDropdown from "../../../../Components/IconDropdown";

function InstallationModal({ saveMenu, edit_data, index }) {
    const [open, setOpen] = useState(false)
    const [menuLocation, setMenuLocation] = useState("Top");
    const [specificMenu, setSpecificMenu] = useState("");
    const [selectedIcon, setSelectedIcon] = useState('');
    const [menu_type, setMenu_type] = useState('sidebar');
    const [formData, setFormData] = useState(edit_data || {
        locationID: "#locationmenu",
        menu_id: Date.now(),
        icons: "",
        name: "",
        type: "Menu Folder",
        useCustomImage: false,
        target: {},
        menu_type: menu_type,
        customImage: "",
        actionName: "Target",
        targetLocation: "",
        link: "",
        openInIframe: false,
        user_permissions: {
            agency_admins: true,
            agency_users: true,
            account_admins: true,
            account_users: true,
        },
    });
    const [showIconSelector, setShowIconSelector] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const handleIconClick = (icon) => {
        setSelectedIcon(icon);
        const iconcode = `&#x${icon.unicode};`
        setFormData({ ...formData, icons: iconcode });
        setShowIconSelector(false);
    };

    const handleIconInputChange = (event) => {
        setSearchTerm(event.target.value);
        // setShowIconSelector(true);
    };

    const icons = [
        { name: "address-book", unicode: "f2b9" },
        { name: "address-card", unicode: "f2bb" },
        { name: "adjust", unicode: "f042" },
        { name: "air-freshener", unicode: "f5d0" },
        { name: "align-center", unicode: "f037" },
        { name: "align-justify", unicode: "f039" },
        { name: "align-left", unicode: "f036" },
        { name: "align-right", unicode: "f038" },
        { name: "allergies", unicode: "f461" },
        { name: "ambulance", unicode: "f0f9" },
        { name: "american-signlanguage-interpreting", unicode: "f2a3" },
        { name: "anchor", unicode: "f13d" },
        { name: "angle-double-down", unicode: "f103" },
        { name: "angle-double-left", unicode: "f100" },
        { name: "angle-double-right", unicode: "f101" },
        { name: "angle-double-up", unicode: "f102" },
        { name: "angle-down", unicode: "f107" },
        { name: "angle-left", unicode: "f104" },
        { name: "angle-right", unicode: "f105" },
        { name: "angle-up", unicode: "f106" },
        { name: "angry", unicode: "f556" },
        { name: "ankh", unicode: "f644" },
        { name: "apple-alt", unicode: "f5d1" },
        { name: "archive", unicode: "f187" },
        { name: "archway", unicode: "f557" },
        { name: "arrow-alt-circle-down", unicode: "f358" },
        { name: "arrow-alt-circle-left", unicode: "f359" },
        { name: "arrow-alt-circle-right", unicode: "f35a" },
        { name: "arrow-alt-circle-up", unicode: "f35b" },
        { name: "arrow-circle-down", unicode: "f0ab" },
        { name: "arrow-circle-left", unicode: "f0a8" },
        { name: "arrow-circle-right", unicode: "f0a9" },
        { name: "arrow-circle-up", unicode: "f0aa" },
        { name: "arrow-down", unicode: "f063" },
        { name: "arrow-left", unicode: "f060" },
        { name: "arrow-right", unicode: "f061" },
        { name: "arrow-up", unicode: "f062" },
        { name: "arrows-alt", unicode: "f0b2" },
        { name: "arrows-alt-h", unicode: "f337" },
        { name: "arrows-alt-v", unicode: "f338" },
        { name: "assistive-listeningsystems", unicode: "f2a2" },
        { name: "asterisk", unicode: "f069" },
        { name: "at", unicode: "f1fa" },
        { name: "atlas", unicode: "f558" },
        { name: "atom", unicode: "f5d2" },
        { name: "audio-description", unicode: "f29e" },
        { name: "award", unicode: "f559" },
        { name: "backspace", unicode: "f55a" },
        { name: "backward", unicode: "f04a" },
        { name: "balance-scale", unicode: "f24e" },
        { name: "ban", unicode: "f05e" },
        { name: "band-aid", unicode: "f462" },
        { name: "barcode", unicode: "f02a" },
        { name: "bars", unicode: "f0c9" },
        { name: "baseball-ball", unicode: "f433" },
        { name: "basketball-ball", unicode: "f434" },
        { name: "bath", unicode: "f2cd" },
        { name: "battery-empty", unicode: "f244" },
        { name: "battery-full", unicode: "f240" },
        { name: "battery-half", unicode: "f242" },
        { name: "battery-quarter", unicode: "f243" },
        { name: "battery-three-quarters", unicode: "f241" },
        { name: "bed", unicode: "f236" },
        { name: "beer", unicode: "f0fc" },
        { name: "bell", unicode: "f0f3" },
        { name: "bell-slash", unicode: "f1f6" },
        { name: "bezier-curve", unicode: "f55b" },
        { name: "bible", unicode: "f647" },
        { name: "bicycle", unicode: "f206" },
        { name: "binoculars", unicode: "f1e5" },
        { name: "birthday-cake", unicode: "f1fd" },
        { name: "blender", unicode: "f517" },
        { name: "blind", unicode: "f29d" },
        { name: "bold", unicode: "f032" },
        { name: "bolt", unicode: "f0e7" },
        { name: "bomb", unicode: "f1e2" },
        { name: "bone", unicode: "f5d7" },
        { name: "bong", unicode: "f55c" },
        { name: "book", unicode: "f02d" },
        { name: "book-open", unicode: "f518" },
        { name: "book-reader", unicode: "f5da" },
        { name: "bookmark", unicode: "f02e" },
        { name: "bowling-ball", unicode: "f436" },
        { name: "box", unicode: "f466" },
        { name: "box-open", unicode: "f49e" },
        { name: "boxes", unicode: "f468" },
        { name: "braille", unicode: "f2a1" },
        { name: "brain", unicode: "f5dc" },
        { name: "briefcase", unicode: "f0b1" },
        { name: "briefcase-medical", unicode: "f469" },
        { name: "broadcast-tower", unicode: "f519" },
        { name: "broom", unicode: "f51a" },
        { name: "brush", unicode: "f55d" },
        { name: "bug", unicode: "f188" },
        { name: "building", unicode: "f1ad" },
        { name: "bullhorn", unicode: "f0a1" },
        { name: "bullseye", unicode: "f140" },
        { name: "burn", unicode: "f46a" },
        { name: "bus", unicode: "f207" },
        { name: "bus-alt", unicode: "f55e" },
        { name: "business-time", unicode: "f64a" },
        { name: "calculator", unicode: "f1ec" },
        { name: "calendar", unicode: "f133" },
        { name: "calendar-alt", unicode: "f073" },
        { name: "calendar-check", unicode: "f274" },
        { name: "calendar-minus", unicode: "f272" },
        { name: "calendar-plus", unicode: "f271" },
        { name: "calendar-times", unicode: "f273" },
        { name: "camera", unicode: "f030" },
        { name: "camera-retro", unicode: "f083" },
        { name: "cannabis", unicode: "f55f" },
        { name: "capsules", unicode: "f46b" },
        { name: "car", unicode: "f1b9" },
        { name: "car-alt", unicode: "f5de" },
        { name: "car-battery", unicode: "f5df" },
    ];
    const filteredIcons = icons.filter((icon) =>
        icon.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const menuItems = [
        {
            label: "Launch Pad",
            value: "launchpad_v2",
            selector: "#sb_launchpad",
            level: "sidebar",
            icon: "⚡",
        },
        {
            label: "Dashboard",
            value: "dashboard_v2",
            selector: "#sb_dashboard",
            level: "sidebar",
            icon: "⚡",
        },
        {
            label: "Conversations",
            value: "conversations_v2",
            level: "sidebar",
            selector: "#sb_conversations",
            icon: "📁",
        },

        {
            label: "Conversations",
            value: "tb_conversations_v2",
            level: "nested",
            path: "conversations",
            selector: "#tb_conversations-tab",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Manual Actions",
            value: "manual_actions_v2",
            level: "nested",
            path: "conversations",
            selector: "#tb_manial-actions",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Templates",
            value: "conversations_templates_v2",
            level: "nested",
            path: "conversations",
            selector: "#tb_conversations-templates",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Trigger Links",
            value: "trigger_links_v2",
            level: "nested",
            path: "conversations",
            selector: "#tb_trigger-links",
            icon: "➜",
            type: "subMenu",
        },

        {
            label: "Calendars",
            value: "calendars_v2",
            level: "sidebar",
            selector: "#sb_calendars",
            icon: "📁",
        },
        {
            label: "Calendars",
            value: "calendars_sub_v2",
            level: "nested",
            path: "calendars",
            selector: "#tb_calendars-tab",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Appointments",
            value: "appointments_v2",
            level: "nested",
            path: "calendars",
            selector: "#tb_apppontment-tab",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Calendar Settings",
            value: "calendar_settings_v2",
            level: "calendars",
            path: "calendars",
            selector: "#tb_calendar-settings-top",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Contacts",
            value: "contacts_v2",
            level: "sidebar",
            selector: "#sb_contacts",
            icon: "📁",
        },
        {
            label: "Contacts",
            value: "contacts_v2",
            level: "nested",
            path: "contacts",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Smart Lists",
            value: "smart_list_v2",
            level: "nested",
            path: "contacts",
            selector: "#tb_lists",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Contact Requests",
            value: "contact_requests_v2",
            level: "nested",
            path: "kjhjkh",
            selector: "#tb_contact-requests",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Bulk Actions",
            value: "bulk_actions_v2",
            level: "nested",
            path: "contacts",
            selector: "#tb_bulk-actions",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Restore",
            value: "restore_v2",
            level: "nested",
            path: "contacts",
            selector: "#tb_contacts-restore",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Tasks",
            value: "tb_tasks",
            level: "nested",
            path: "contacts",
            selector: "#tb_tasks",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Company",
            value: "tb_business",
            level: "nested",
            path: "contacts",
            selector: "#tb_business",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Manage Smart Lists",
            value: "manage_smart_lists_v2",
            level: "nested",
            path: "contacts",
            selector: "#tb_contacts-settings-top",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Settings Gear: Custom Field Settings",
            value: "tb_contact-custom-fields-settings",
            level: "nested",
            path: "contacts",
            selector: "#tb_contact-custom-fields-settings",
            icon: "➜",
            type: "subMenu",
        },

        {
            label: "Opportunities",
            value: "opportunities_v2",
            level: "sidebar",
            selector: "#sb_opportunities",
            icon: "📁",
        },

        {
            label: "Opportunities",
            value: "opportunities_sub_v2",
            level: "nested",
            path: "opportunities",
            selector: "#tb_opportunities-tab",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Pipelines",
            value: "pipelines_v2",
            level: "nested",
            path: "opportunities",
            selector: "#tb_pipeline",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Payments",
            value: "payments_v2",
            level: "sidebar",
            selector: "#sb_payments",
            icon: "📁",
        },
        {
            label: "Invoices",
            value: "tb_payment-invoices",
            level: "nested",
            path: "payments",
            selector: "#tb_payment-invoices",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Recurring Templates",
            value: "tb-sub_payment-invoices",
            level: "nested",
            path: "payments",
            selector: "#tb-sub_payment-invoices",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Documents & Contracts",
            value: "tb_proposals-estimates",
            level: "nested",
            path: "payments",
            selector: "#tb_proposals-estimates",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Orders",
            value: "tb_payment-orders-new",
            level: "nested",
            path: "payments",
            selector: "#tb_payment-orders-new",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Abandoned Checkouts",
            value: "tb-sub_all-abandoned-checkouts",
            level: "nested",
            path: "payments",
            selector: "#tb-sub_all-abandoned-checkouts",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Subscriptions",
            value: "tb_payment-subscriptions",
            level: "nested",
            path: "payments",
            selector: "#tb_payment-subscriptions",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Payment Links",
            value: "tb_payment-links",
            level: "nested",
            path: "payments",
            selector: "#tb_payment-links",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Transactions",
            value: "tb_payment-transactions-new",
            level: "nested",
            path: "payments",
            selector: "#tb_payment-transactions-new",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Products",
            value: "tb_payments-products",
            level: "nested",
            path: "payments",
            selector: "#tb_payments-products",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Coupons",
            value: "tb_payments-coupons",
            level: "nested",
            path: "payments",
            selector: "#tb_payments-coupons",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Settings",
            value: "tb_payment-settings",
            level: "nested",
            path: "payments",
            selector: "#tb_payment-settings",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Integrations",
            value: "tb_payment-integrations",
            level: "nested",
            path: "payments",
            selector: "#tb_payment-integrations",
            icon: "➜",
            type: "subMenu",
        },

        {
            label: "Marketing",
            value: "marketing",
            level: "sidebar",
            selector: "#sb_email-marketing",
            icon: "📁",
        },
        {
            label: "Social Planner",
            value: "social_planner_v2",
            level: "nested",
            path: "marketing",
            selector: "#tb_social-planner",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Email Builder",
            value: "email_builder_v2",
            level: "nested",
            path: "marketing",
            selector: "#tb_email-builder",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Templates",
            value: "templates_v2",
            level: "nested",
            path: "marketing",
            selector: "#tb_email-templates",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Trigger Links",
            value: "marketing_trigger_links_v2",
            level: "nested",
            path: "marketing",
            selector: "#tb_trigger-links",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "HTML Builder",
            value: "html_builder-v2",
            level: "nested",
            path: "kjhjkh",
            selector: "#tb_html-builder",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Affiliate Manager",
            value: "tb_affiliate-manager",
            level: "nested",
            path: "marketing",
            selector: "#tb_affiliate-manager",
            icon: "➜",
            type: "subMenu",
        },

        {
            label: "Automation",
            value: "automation",
            level: "sidebar",
            selector: "#sb_automation",
            icon: "📁",
        },
        {
            label: "Workflows",
            value: "workflows_v2",
            level: "nested",
            path: "automation",
            selector: "#tb_workflow",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Content AI",
            value: "contentai",
            level: "nested",
            path: "kjhjkh",
            selector: "#tb_content-ai",
            icon: "➜",
            type: "subMenu",
        },
        // {
        // label: "Campaigns",
        // value: "campaigns_v2",
        // level: "nested",
        // path:"kjhjkh",
        // selector: "sb_launchpad",
        // icon: "➜",
        // type: "subMenu",
        // },
        // {
        // label: "Triggers",
        // value: "triggers_v2",
        // level: "nested",
        // path:"kjhjkh",
        // selector: "sb_launchpad",
        // icon: "➜",
        // type: "subMenu",
        // },
        {
            label: "Sites",
            value: "sites-v2",
            level: "sidebar",
            selector: "#sb_sites",
            icon: "📁",
        },
        {
            label: "Funnels",
            value: "funnels_v2",
            level: "nested",
            path: "funnels-websites",
            selector: "#tb_funnels",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Websites",
            value: "websites_v2",
            level: "nested",
            path: "funnels-websites",
            selector: "#tb_websites",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Blogs",
            value: "blogs",
            level: "nested",
            path: "funnels-websites",
            selector: "#tb_blogs",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Wordpress",
            value: "wordpress_v2",
            level: "nested",
            path: "funnels-websites",
            selector: "#tb_wordpress",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Client Portal",
            value: "clientportal",
            level: "nested",
            path: "funnels-websites",
            selector: "#tb_clientportal",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Forms",
            value: "forms_v2",
            level: "nested",
            path: "funnels-websites",
            selector: "#tb_form-builder",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Surveys",
            value: "surveys_v2",
            level: "nested",
            path: "funnels-websites",
            selector: "#tb_survey-builder",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Chat Widget",
            value: "chat_widget_v2",
            level: "nested",
            path: "funnels-websites",
            selector: "#tb_chat-widget",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Media",
            value: "tb_media",
            level: "nested",
            path: "funnels-websites",
            selector: "#tb_media",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "URL Redirects",
            value: "url_redirects_v2_tb",
            level: "nested",
            path: "funnels-websites",
            selector: "#tb_url-redirects",
            icon: "➜",
            type: "subMenu",
        },

        {
            label: "Memberships",
            value: "memberships_v2",
            level: "sidebar",
            selector: "#sb_memberships",
            icon: "📁",
        },
        {
            label: "Courses",
            value: "tb_courses",
            level: "nested",
            path: "memberships",
            selector: "#tb_courses",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Dashboard",
            value: "sub_membership-dashboard",
            level: "nested",
            path: "memberships",
            selector: "#tb-sub_dashboard",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Products",
            value: "sub_membership-products",
            level: "nested",
            path: "memberships",
            selector: "#tb-sub_products",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Offers",
            value: "sub_membership-offers",
            level: "nested",
            path: "memberships",
            selector: "#tb-sub_offers",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Analytics",
            value: "sub_membership-analytics",
            level: "nested",
            path: "memberships",
            selector: "#tb-sub_analytics",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Settings",
            value: "sub_membership-settings",
            level: "nested",
            path: "memberships",
            selector: "#tb-sub_settings",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "communities",
            value: "tb_communities-settings",
            level: "nested",
            path: "memberships",
            selector: "#tb_communities",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Certificates",
            value: "tb_certificates-settings",
            level: "nested",
            path: "memberships",
            selector: "#tb_certificates",
            icon: "➜",
            type: "subMenu",
        },

        {
            label: "Reputation",
            value: "reputation_v2",
            level: "sidebar",
            selector: "#sb_reputation",
            icon: "📁",
        },
        {
            label: "Overview",
            value: "overview_v2",
            level: "nested",
            path: "reputation",
            selector: "#tb_overview",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Requests",
            value: "requests_v2",
            level: "nested",
            path: "reputation",
            selector: "#tb_reputations-requests",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Reviews",
            value: "reviews_v2",
            level: "nested",
            path: "reputation",
            selector: "#tb_reputations-reviews",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Widget",
            value: "tb_widget",
            level: "nested",
            path: "reputation",
            selector: "#tb_widget",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Listings",
            value: "listings_v2",
            level: "nested",
            path: "reputation",
            selector: "#tb_online-listings",
            icon: "➜",
            type: "subMenu",
        },

        {
            label: "Reporting",
            value: "reporting_v2",
            level: "sidebar",
            selector: "#sb_reporting",
            icon: "📁",
        },
        {
            label: "Google Ads",
            value: "google_ads_v2",
            level: "nested",
            path: "reporting",
            selector: "#tb_google-ads",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Facebook Ads",
            value: "facebook_ads_v2",
            level: "nested",
            path: "reporting",
            selector: "#tb_facebook-ads",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Attribution Report",
            value: "attribution_report_v2",
            level: "nested",
            path: "reporting",
            selector: "#tb_attribution-report",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Call reporting",
            value: "call_reporting_v2",
            level: "nested",
            path: "reporting",
            selector: "#tb_call-reporting",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Agent Reporting",
            value: "agent_reporting_v2",
            level: "nested",
            path: "reporting",
            selector: "#tb_agent-reporting",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Appointment Report",
            value: "appointment_report_v2",
            level: "nested",
            path: "reporting",
            selector: "#tb_appointment-report",
            icon: "➜",
            type: "subMenu",
        },

        {
            label: "App Marketplace",
            value: "sb_app-marketplace",
            level: "sidebar",
            selector: "#sb_app-marketplace",
            icon: "📁",
        },
        {
            label: "Mobile App",
            value: "mobileapp",
            level: "sidebar",
            selector: "#sb_location-mobile-app",
            icon: "📁",
        },
        {
            label: "Settings",
            value: "settings_v2",
            level: "sidebar",
            selector: "#sb_settings",
            icon: "📁",
        },
        {
            label: "Business Info",
            value: "business_info_v2",
            level: "nested",
            path: "settings",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "My Staff",
            value: "my_staff_v2",
            level: "nested",
            path: "settings",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Pipeline",
            value: "pipeline_v2",
            level: "nested",
            path: "settings",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Calendars",
            value: "calendars_settings_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Conversation AI",
            value: "conversation_ai_settings",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Phone Numbers",
            value: "phone_numbers_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Whats App",
            value: "sb_whatsapp",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Reputation Management",
            value: "reputation_management_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Custom Fields",
            value: "custom_fields_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Custom Values",
            value: "custom_values_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Manage Scoring",
            value: "sb_manage-scoring",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Domains",
            value: "domains_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Media",
            value: "media_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "URL Redirects",
            value: "url_redirects_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Integrations",
            value: "integrations_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Email Services",
            value: "email_services_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Conversation Providers",
            value: "conversations_providers_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "SMTP & Mailgun Services",
            value: "smtp_services_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Tags",
            value: "tags_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Labs",
            value: "labs_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Audit Logs",
            value: "auditlogs_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
        {
            label: "Companies",
            value: "companies_v2",
            level: "nested",
            path: "",
            selector: "sb_launchpad",
            icon: "➜",
            type: "subMenu",
        },
    ];
    const menus = [
        "Conversations",
        "Activities",
        "Reporting",
        "Scheduling",
        "Reputation",
        "Contacts",
        "Funnels",
        "Triggers",
        "Online Listings",
        "Phone Calls",
    ];

    const targetLocations = [
        "Conversations",
        "Activities",
        "Reporting",
        "Scheduling",
        "Reputation",
        "Contacts",
        "Funnels",
        "Triggers Links",
        "Online Listings",
        "Phone Calls",
        "Oppertunities",
        "Marketing",
        "Call Stats",
        "call reporting",
        "Scheuling/Appointments",
        "Html Builder",
        "Google Ads",
        "Facebook Ads",
        "Facebook Ads Reporting",
        "Reposting /Attribution",
        "Attribution Report",
        "Manual Actions",
    ];
    const handlePermissionsChange = (e) => {
        const { name, value, type, checked } = e.target;
        const val = checked
        setFormData({ ...formData, user_permissions: { ...formData.user_permissions, [name]: val } });
    };
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        console.log(name)
        console.log(value)
        const val = type === "checkbox" ? checked : value;
        if (name == "target") {
            setFormData({ ...formData, [name]: JSON.parse(val) });

        } else {

            setFormData({ ...formData, [name]: val });
        }
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        if (name === "menuLocation") {
            setMenuLocation(value);
            if (value !== "After a specific menu") {
                setSpecificMenu("");
            }
        } else if (name === "specificMenu") {
            setSpecificMenu(value);
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    useEffect(() => {
        if (edit_data) {
            const orignalicon = edit_data?.icons.replace(/&#x|;/g, "");
            console.log(orignalicon, 'orignaliconorignalicon')
            const filteredicon = icons.filter((item) => item.unicode == orignalicon)[0]
            console.log(filteredicon, 'filterediconfilteredicon')
            setSelectedIcon(filteredicon)
        }
    }, [edit_data]);
    const handleSaveMenu = () => {

        console.log("Form Data:", formData);

        saveMenu(formData, "location", index);
        setMenu_type('sidebar');

        setFormData({
            locationID: "#locationmenu",
            icons: "",
            name: "",
            type: "Menu Folder",
            useCustomImage: false,
            target: {},
            menu_type: menu_type,
            customImage: "",
            actionName: "Target",
            targetLocation: "",
            link: "",
            openInIframe: false,
            user_permissions: {
                agency_admins: true,
                agency_users: true,
                account_admins: true,
                account_users: true,
            },
        });
        setMenuLocation("Top");
        setSpecificMenu("");
        handleClose();
    };
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            {
                edit_data ?
                    <i className="ri-edit-2-fill" style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}></i>
                    :
                    <Button variant="primary" className="w-100" onClick={() => setOpen(true)}>
                        Add Menu
                    </Button>
            }
            <Modal show={open} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{edit_data ? "Edit Menu" : "Add Menu"}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "500px", overflowY: "auto" }}>
                    <Form>
                        {/* <div class="form-check form-switch mb-2">
                            <input class="form-check-input" onChange={(e) => setMenu_type(e.target.checked == true ? "topbar" : "sidebar")} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            <label class="form-check-label" for="flexSwitchCheckDefault">Add menu in topbar</label>
                        </div> */}
                        {
                            menu_type == "sidebar" ?

                                <Form.Group className="mb-3" controlId="formIcons">
                                    <Form.Label>Icons</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search Icon"
                                        name="icon"
                                        defaultValue={showIconSelector ? "" : selectedIcon?.name}
                                        onClick={() => setShowIconSelector(!showIconSelector)}
                                        onChange={handleIconInputChange}
                                    />
                                    {
                                        showIconSelector &&
                                        <div>
                                            <div style={{ maxHeight: '150px', overflowY: 'auto', border: '1px solid #ccc', padding: '5px' }}>
                                                {filteredIcons.map((icon, index) => (
                                                    <span
                                                        key={index}
                                                        onClick={() => handleIconClick(icon)}
                                                        style={{ cursor: 'pointer', marginRight: '10px' }}
                                                    >
                                                        <i className={`fas fa-${icon.name}`} style={{ fontSize: '24px' }} />
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    {/* <Form.Check
                                type="checkbox"
                                label="Use a custom image icon"
                                name="useCustomImage"
                                checked={formData.useCustomImage}
                                onChange={handleInputChange}
                            />
                            {formData.useCustomImage && (
                                <Form.Control
                                    type="file"
                                    name="customImage"
                                    onChange={handleInputChange}
                                />
                            )} */}
                                </Form.Group>
                                :
                                <Form.Group>
                                    <select
                                        className="form-control"
                                        name="target"
                                        value={JSON.stringify(formData.target)}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select an item</option>
                                        {menuItems
                                            .filter((item) => item.level == 'sidebar')
                                            .map(function (filteredItem, index) {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={JSON.stringify(filteredItem)}
                                                    >
                                                        {filteredItem?.icon && <span>{filteredItem.icon}</span>}{" "}
                                                        {filteredItem.label}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </Form.Group>
                        }

                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Option Name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLink">
                            <Form.Label>Link</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Paste link here"
                                name="link"
                                value={formData.link}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="formOpenInIframe">
                            <Form.Check
                                type="checkbox"
                                label="Open in new tab"
                                name="openInIframe"
                                checked={formData.openInIframe}
                                onChange={handleInputChange}
                            />
                        </Form.Group> */}
                        {/* <Form.Group className="mb-3" controlId="formType">
                            <Form.Label>Type</Form.Label>
                            <Form.Select
                                name="type"
                                value={formData.type}
                                onChange={handleSelectChange}
                            >
                                <option>Menu Folder</option>
                                <option>Menu Item</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formMenuLocation">
                            <Form.Label>Menu Location</Form.Label>
                            <Form.Select
                                name="menuLocation"
                                value={menuLocation}
                                onChange={handleSelectChange}
                            >
                                <option value="Top">Top</option>
                                <option value="Bottom">Bottom</option>
                                <option value="After a specific menu">
                                    After a specific menu
                                </option>
                            </Form.Select>
                        </Form.Group>

                        {menuLocation === "After a specific menu" && (
                            <Form.Group className="mb-3" controlId="formSpecificMenu">
                                <Form.Label>Specific Menu</Form.Label>
                                <Form.Select
                                    name="specificMenu"
                                    value={specificMenu}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Select a Menu</option>
                                    {menus.map((menu, index) => (
                                        <option key={index} value={menu}>
                                            {menu}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        )}
                        <Form.Group className="mb-3" controlId="formActionName">
                            <Form.Label>Action Name</Form.Label>
                            <Form.Select
                                name="actionName"
                                value={formData.actionName}
                                onChange={handleSelectChange}
                            >
                                <option value="Target">Target</option>
                                <option value="Link">Link</option>
                            </Form.Select>
                        </Form.Group>
                        {formData.actionName === "Target" && (
                            <Form.Group className="mb-3" controlId="formTargetLocation">
                                <Form.Label>Target Location</Form.Label>
                                <Form.Select
                                    name="targetLocation"
                                    value={formData.targetLocation}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Select a target location...</option>
                                    {targetLocations.map((location, index) => (
                                        <option key={index} value={location}>
                                            {location}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        )}
                        {formData.actionName === "Link" && (
                            <Form.Group className="mb-3" controlId="formLink">
                                <Form.Label>Link</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Paste link here"
                                    name="link"
                                    value={formData.link}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        )}
                        {formData.actionName === "Link" && (
                            <Form.Group className="mb-3" controlId="formOpenInIframe">
                                <Form.Check
                                    type="checkbox"
                                    label="Open in iframe"
                                    name="openInIframe"
                                    checked={formData.openInIframe}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        )} */}
                        <Form.Group className="mb-3" controlId="formLink">
                            <Form.Label>User Permissions</Form.Label>
                            <Form.Check
                                checked={formData.user_permissions.agency_admins}
                                type="checkbox"
                                label="Agency Admins"
                                name="agency_admins"
                                onChange={handlePermissionsChange}
                            />
                            <Form.Check
                                checked={formData.user_permissions.agency_users}
                                type="checkbox"
                                label="Agency Users"
                                name="agency_users"
                                onChange={handlePermissionsChange}
                            />
                            <Form.Check
                                checked={formData.user_permissions.account_admins}
                                type="checkbox"
                                label="Account Admins"
                                name="account_admins"
                                onChange={handlePermissionsChange}
                            />
                            <Form.Check
                                checked={formData.user_permissions.account_users}
                                type="checkbox"
                                label="Account Users"
                                name="account_users"
                                onChange={handlePermissionsChange}
                            />
                        </Form.Group>
                    </Form>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSaveMenu}>
                            {edit_data ? "Edit" : "Save"}
                        </Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default InstallationModal