import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";

const MenuBackgrounds = () => {
  const [mainMenuBG, setMainMenuBG] = useState("#355ff1");
  const [menuItemShadowColor, setmenuItemShadowColor] = useState("#355ff1");
  const [menuIconWhite,  setMenuIconWhite]  = useState(false);
  const [menuIconBlack, setMenuIconBlack] = useState(false);
  const [menuIconWhiteHover, setMenuIconWhiteHover] = useState(false);
  const [menuIconBlackHover, setMenuIconBlackHover] = useState(false);

  const [menuItemBackGround, setMenuItemBackGround] = useState("#ffa900");
  const [menuItemBorder, setMenuItemBorder] = useState("#355ff1");
  const [menuItemColor, setMenuItemColor] = useState("#528bff");
  const [menuItemShadowColorHover, setMenuItemShadowColorHover] =
    useState("#ffffff");
  const [menuItemBackGroundHover, setMenuItemBackGroundHover] =
    useState("#eeeeee");
  const [menuItemBorderHover, setmenuItemBorderHover] = useState("#e93d3d");
  const [menuItemColorHover, setmenuItemColorHover] = useState("#333333");
  const [subMenuItemBackground, setSubMenuItemBackground] = useState("#ffffff");
  const [subMenuItemColor, setsubMenuItemColor] = useState("#ffffff");
  const [subMenuItemBackgroundHover, setsubMenuItemBackgroundHover] =
    useState("#ffffff");
  const [submenuItemColorHover, setsubmenuItemColorHover] = useState("#355ff1");

  return (
    <>

        <Row className="mb-3">
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={4}>
                Main Menu BG
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={mainMenuBG}
                    name="mainMenuBackground"
                    onChange={(e) => setMainMenuBG(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={mainMenuBG}
                    onChange={(e) => setMainMenuBG(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
                Menu Item Shadow Color
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={menuItemShadowColor}
                    name="menuItemShadowColor"
                    onChange={(e) => setmenuItemShadowColor(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={menuItemShadowColor}
                    onChange={(e) => setmenuItemShadowColor(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
        <Col>
            <div
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Form.Label>Menu Icon (White)</Form.Label>
              <div>
                <Form.Check type="switch" name="menuIconWhite"  id="menu-icon-shite" 
                
                checked={menuIconWhite} 
                onChange={(e) => setMenuIconWhite(e.target.checked)}
                />
              </div>
            </div>
          </Col>
          <Col>
            <div
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Form.Label>Menu Icon (Black)</Form.Label>
              <div>
                <Form.Check type="switch" name="menuIconBlack" id="menu-icon-black"
                 checked={menuIconBlack} 
                 onChange={(e) => setMenuIconBlack(e.target.checked)}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
        <Col>
            <div
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Form.Label>Menu Hover Icon (White)</Form.Label>
              <div>
                <Form.Check type="switch" name="menuIconWhiteHover" id="menu-icon-white-hover" 
                 checked={menuIconWhiteHover} 
                 onChange={(e) => setMenuIconWhiteHover(e.target.checked)}
                />
              </div>
            </div>
          </Col>
        <Col>
            <div
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Form.Label>Menu Hover Icon (Black)</Form.Label>
              <div>
                <Form.Check type="switch" name="menuIconBlackHover" id="menu-icon-Black-hover"
                 checked={menuIconBlackHover} 
                 onChange={(e) => setMenuIconBlackHover(e.target.checked)}
                />
              </div>
            </div>
          </Col>
          
        </Row>
        <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Menu Item BackGround
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={menuItemBackGround}
                  name="menuItemBackGround"
                  onChange={(e) => setMenuItemBackGround(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={menuItemBackGround}
                  onChange={(e) => setMenuItemBackGround(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Menu Item Border
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={menuItemBorder}
                  name="menuItemBorder"
                  onChange={(e) => setMenuItemBorder(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={menuItemBorder}
                  onChange={(e) => setMenuItemBorder(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
         
        </Row>
        <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Menu Item Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={menuItemColor}
                  name="menuItemColor"
                  onChange={(e) => setMenuItemColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={menuItemColor}
                  onChange={(e) => setMenuItemColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Menu Item Shadow Color (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={menuItemShadowColorHover}
                  name="menuItemShadowColorHover"
                  onChange={(e) => setMenuItemShadowColorHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={menuItemShadowColorHover}
                  onChange={(e) => setMenuItemShadowColorHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
       
        </Row>
        <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Menu Item BackGround (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={menuItemBackGroundHover}
                  name="menuItemBackGroundHover"
                  onChange={(e) => setMenuItemBackGroundHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={menuItemBackGroundHover}
                  onChange={(e) => setMenuItemBackGroundHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Menu Item Border (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={menuItemBorderHover}
                  name="menuItemBorderHover"
                  onChange={(e) => setmenuItemBorderHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={menuItemBorderHover}
                  onChange={(e) => setmenuItemBorderHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        
        </Row>
        <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Menu Item Color (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={menuItemColorHover}
                  name="menuItemColorHover"
                  onChange={(e) => setmenuItemColorHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={menuItemColorHover}
                  onChange={(e) => setmenuItemColorHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Sub Menu Item Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={subMenuItemBackground}
                  name="subMenuItemBackground"
                  onChange={(e) => setSubMenuItemBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={subMenuItemBackground}
                  onChange={(e) => setSubMenuItemBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        </Row>
        <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Sub Menu Item Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={subMenuItemColor}
                  name="subMenuItemColor"
                  onChange={(e) => setsubMenuItemColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={subMenuItemColor}
                  onChange={(e) => setsubMenuItemColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Sub Menu Item Background (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={subMenuItemBackgroundHover}
                  name="subMenuItemBackgroundHover"
                  onChange={(e) => setsubMenuItemBackgroundHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={subMenuItemBackgroundHover}
                  onChange={(e) => setsubMenuItemBackgroundHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        </Row>
        <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
            Sub Menu Item Color (Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={submenuItemColorHover}
                  name="submenuItemColorHover"
                  onChange={(e) => setsubmenuItemColorHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={submenuItemColorHover}
                  onChange={(e) => setsubmenuItemColorHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        </Row>
      
    </>
  );
};

export default MenuBackgrounds;
