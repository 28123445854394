import React, { useEffect, useState } from "react";
import { Button, Modal, Form, ListGroup } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useAppServices } from "../../../../../../hook/services";
const OpportunityTabsModal = ({ sso_cred }) => {
  // console.log(sso_cred, "sso_cred on op");
  const AppService = useAppServices();
  const [show, setShow] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [showAddTab, setShowAddTab] = useState(false);
  const [trueUrl, setTrueUrl] = useState(false);
  const [tabFormData, setTabFormData] = useState({
    name: "",
    link: "",
    sendContact: false,
    contact: false,
    address: false,
    customFields: false,
    sendType: "Query String",
  });
  const fetchData = async () => {
    const { response } = await AppService.opportunities.get({
      query: `company_id=${sso_cred.companyId}`,
    });
    console.log(response.data, "response");
    setTabs(response?.data[0].tabs);
  };
  useEffect(() => {
    fetchData();
  }, [sso_cred]);
  const [editIndex, setEditIndex] = useState(-1);

  const handleClose = () => {
    setTrueUrl(false);
    setShow(false);
    setShowAddTab(false);
    setTabFormData({
      name: "",
      link: "",
      sendContact: false,
      contact: false,
      address: false,
      customFields: false,
      sendType: "Query String",
    });
    setEditIndex(-1);
  };
  const handleShow = () => setShow(true);
  const handleAddTab = () => setShowAddTab(true);

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setTabFormData({
      ...tabFormData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSaveTab = async () => {
    console.log("asdfasdf");
    if (!tabFormData.link || !tabFormData.link.startsWith("https://")) {
      // alert("please Enter a proper Url")
      document.getElementById("formLink").style.borderColor = "red";
      setTrueUrl(true);
      return;
    }
    setTrueUrl(false);
    document.getElementById("formLink").style.borderColor = "#E2E5EC";
    if (editIndex > -1) {
      const updatedTabs = [...tabs];
      updatedTabs[editIndex] = tabFormData;
      setTabs(updatedTabs);
      // update payload
      const payload = {
        company_id: sso_cred.companyId,
        tabs: updatedTabs,
      };
      console.log(payload, "payload");
      const { response } = await AppService.opportunities.create({
        payload: payload,
      });
      console.log(response, "response updates");
      console.log(updatedTabs, "updatedTabs");
      setEditIndex(-1);
    } else {
      if (tabFormData.name) {
        const data = [...tabs, tabFormData];
        setTabs(data);
        console.log(tabs, "tabs");
        console.log(data, "data");
        // payload
        const payload = {
          company_id: `${sso_cred.companyId}`,
          tabs: [...tabs, tabFormData],
        };
        console.log(payload, "payload");
        const { response } = await AppService.opportunities.create({
          payload: payload,
        });
        console.log(response, "response");
        // console.log(tabs, "tabs");
      }
    }
    setShowAddTab(false);
    setTabFormData({
      name: "",
      link: "",
      sendContact: false,
      contact: false,
      address: false,
      customFields: false,
      sendType: "Query String",
    });
    console.log(tabFormData);
  };

  const deleteTab = async (indexToDelete) => {
    setTabs(tabs.filter((_, index) => index !== indexToDelete));
    console.log(
      tabs.filter((_, index) => index !== indexToDelete),
      "tabsdeleted"
    );
    const payload = {
      company_id: sso_cred.companyId,
      tabs: tabs.filter((_, index) => index !== indexToDelete),
    };
    console.log(payload, "payload");
    const { response } = await AppService.opportunities.create({
      payload: payload,
    });
  };

  const editTab = (indexToEdit) => {
    setShowAddTab(true);
    setEditIndex(indexToEdit);
    setTabFormData(tabs[indexToEdit]);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Opportunity Tabs
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Opportunity Tabs</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ height: "50vh", overflowY: "auto" }}>
          {showAddTab ? (
            <Form>
              <Form.Group controlId="tabName">
                <Form.Label>Tab name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={tabFormData.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formLink">
                <Form.Label>Form link</Form.Label>
                <Form.Control
                  type="text"
                  name="link"
                  value={tabFormData.link}
                  onChange={handleInputChange}
                />
                {trueUrl && (
                  <small style={{ color: "red" }}>Enter a valid url</small>
                )}
              </Form.Group>
              <Form.Group controlId="sendContactData">
                <Form.Check
                  type="checkbox"
                  name="sendContact"
                  label="Send contact data to URL"
                  checked={tabFormData.sendContact}
                  onChange={handleInputChange}
                />
              </Form.Group>
              {tabFormData.sendContact && (
                <>
                  <div className="d-flex justify-content-start gap-3 mt-4">
                    <h6>Send Data</h6>
                    <Form.Check
                      name="contact"
                      label="Contact"
                      type="checkbox"
                      checked={tabFormData.contact}
                      onChange={handleInputChange}
                    />
                    {/* <Form.Check
                      name="address"
                      label="Address"
                      type="checkbox"
                      checked={tabFormData.address}
                      onChange={handleInputChange}
                    />
                    <Form.Check
                      name="customFields"
                      label="Custom Fields"
                      type="checkbox"
                      checked={tabFormData.customFields}
                      onChange={handleInputChange}
                    /> */}
                  </div>
                  <Form.Group controlId="sendType">
                    <Form.Label>Send type:</Form.Label>
                    <Form.Control
                      as="select"
                      name="sendType"
                      value={tabFormData.sendType}
                      onChange={handleInputChange}
                    >
                      <option>Query String</option>
                      {/* <option>comming soon</option> */}
                    </Form.Control>
                  </Form.Group>
                </>
              )}
            </Form>
          ) : (
            <>
              {tabs.length > 0 ? (
                <ListGroup>
                  {tabs.map((tab, index) => (
                    <ListGroup.Item
                      key={index}
                      className="d-flex justify-content-between align-items-center"
                    >
                      {tab.name}
                      <div className="d-flex gap-2">
                        <FaEdit
                          cursor={"pointer"}
                          onClick={() => editTab(index)}
                        />
                        <FaTrashAlt
                          color="red"
                          cursor={"pointer"}
                          onClick={() => deleteTab(index)}
                        />
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <div className="text-center">
                  <h5>No tabs found</h5>
                </div>
              )}
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          {showAddTab ? (
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  // Hide the add tab UI
                  setShowAddTab(false);

                  // Reset URL state
                  setTrueUrl(false);

                  // Access and modify the element's style safely
                  const formLinkElement = document.getElementById("formLink");
                  if (formLinkElement) {
                    formLinkElement.style.borderColor = "#E2E5EC";
                  }
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSaveTab}>
                Save
              </Button>
            </>
          ) : (
            <>
              <Button variant="primary" onClick={handleAddTab}>
                + Add
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OpportunityTabsModal;
