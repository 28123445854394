import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";
import { IoMdEyeOff } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { useAppServices } from "../../../../../../hook/services";

function MenuEditorModal() {
  const AppService = useAppServices();
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [selectedItem, setSelectedItem] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [label, setLabel] = useState("");
  const [editedLabels, setEditedLabels] = useState([]);
  const [hiddenItems, setHiddenItems] = useState([]);
  const companyId = "hEANpF0GDaTmISXjA8Fm";

  const getData = async () => {
    const { response } = await AppService.menu_edits.get({
      // query: `companyId=${companyId}`,
    });
    if (response) {
      const editmenus = response.data.filter(function (item) {
        return item.type == "edit";
      });
      const hidemenus = response.data.filter(function (item) {
        return item.type == "hide";
      });
      setEditedLabels(editmenus);
      setHiddenItems(hidemenus);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  const onLoad = async () => {
    getData();
  };
  useEffect(() => {
    onLoad();
  }, []);
  const openModal = () => {
    setShowModal(true);
    setEditMode(false);
  };
  const closeModal = () => setShowModal(false);

  const handleSelectChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    setSelectedItem(event.target.value);
    setLabel(selectedOption.text);
    setEditMode(false);
  };

  const handleLabelChange = (event) => {
    setLabel(event.target.value);
  };

  const addEdit = async () => {
    const originalItem = menuItems.find((item) => item.value === selectedItem);
    const originalLabel = originalItem ? originalItem.label : selectedItem;
    const labelExists = editedLabels.some(
      (edit) =>
        edit.originalLabel === originalLabel &&
        edit.newLabel.trim() === label.trim()
    );
    const isNewLabelEmpty = !label.trim();
    // alert(label.trim())
    if (!labelExists && !isNewLabelEmpty) {
      setEditedLabels((prevLabels) => [
        ...prevLabels,
        { originalLabel, newLabel: label.trim() },
      ]);
      const payload = {
        originalLabel,
        newLabel: label.trim(),
        companyId: companyId,
        type: "edit",
      };
      const { response } = await AppService.menu_edits.create({
        payload: payload,
      });
      setLabel("");
      setEditMode(false);
      setSelectedItem("");
    }
  };

  const handleHideClick = async () => {
    if (
      selectedItem &&
      hiddenItems.filter((item) => item.originalLabel == selectedItem).length ==
        0
    ) {
      // setHiddenItems((prevItems) => {
      //   if (!prevItems.includes(selectedItem)) {
      //     return [...prevItems, selectedItem];
      //   }
      //   return prevItems;
      // });
      console.log(selectedItem);
      const payload = {
        originalLabel: selectedItem,
        companyId: "companyId",
        type: "hide",
      };
      const { response } = await AppService.menu_edits.create({
        payload: payload,
      });
      if (response) {
        setHiddenItems([...hiddenItems, response.data]);
      }
      setSelectedItem("");
    } else {
      if (!selectedItem) {
        alert("Please select an item to hide.");
      } else {
        alert(`${selectedItem} is already exist`);
      }
    }

    //
  };

  const handleEditClick = () => {
    if (selectedItem) {
      setEditMode(true);
    } else {
      alert("Please select an item to edit.");
    }
  };

  async function removeHiddenItem(itemValue, id) {
    setHiddenItems((prevItems) =>
      prevItems.filter((item) => item !== itemValue)
    );
    const { response } = await AppService.menu_edits.delete({
      query: `_id=${id}`,
    });
  }

  const handleDeleteEdit = async (indexToDelete, id) => {
    setEditedLabels((currentEdits) =>
      currentEdits.filter((_, index) => index !== indexToDelete)
    );
    const { response } = await AppService.menu_edits.delete({
      query: `_id=${id}`,
    });
  };
  const menuItems = [
    {
      label: "Launch Pad",
      value: "launchpad_v2",
      icon: "⚡",
    },
    {
      label: "Dashboard",
      value: "dashboard_v2",
      icon: "⚡",
    },
    {
      label: "Conversations",
      value: "conversations_v2",
      icon: "📁",
    },

    {
      label: "Conversations",
      value: "tb_conversations_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Templates",
      value: "conversations_templates_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Manual Actions",
      value: "manual_actions_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Trigger Links",
      value: "trigger_links_v2",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Calendars",
      value: "calendars_v2",
      icon: "📁",
    },
    {
      label: "Calendars",
      value: "calendars_sub_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Appointments",
      value: "appointments_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Calendar Settings",
      value: "calendar_settings_v2",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Contacts",
      value: "contacts_v2",
      icon: "📁",
    },
    {
      label: "Contacts",
      value: "contacts_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Smart Lists",
      value: "smart_list_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Contact Requests",
      value: "contact_requests_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Bulk Actions",
      value: "bulk_actions_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Restore",
      value: "restore_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Tasks",
      value: "tb_tasks",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Company",
      value: "tb_business",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Manage Smart Lists",
      value: "manage_smart_lists_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Settings Gear: Custom Field Settings",
      value: "tb_contact-custom-fields-settings",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Opportunities",
      value: "opportunities_v2",
      icon: "📁",
    },

    {
      label: "Opportunities",
      value: "opportunities_sub_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Pipelines",
      value: "pipelines_v2",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Payments",
      value: "payments_v2",
      icon: "📁",
    },
    {
      label: "Invoices",
      value: "tb_payment-invoices",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Recurring Templates",
      value: "tb-sub_payment-invoices",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Proposals & Estimates",
      value: "tb_proposals-estimates",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Orders",
      value: "tb_payment-orders-new",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Subscriptions",
      value: "tb_payment-subscriptions",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Transactions",
      value: "tb_payment-transactions-new",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Products",
      value: "tb_payments-products",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Coupons",
      value: "tb_payments-coupons",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Settings",
      value: "tb_payment-settings",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Integrations",
      value: "tb_payment-integrations",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Marketing",
      value: "marketing",
      icon: "📁",
    },
    {
      label: "Social Planner",
      value: "social_planner_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Email Builder",
      value: "email_builder_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Templates",
      value: "templates_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Trigger Links",
      value: "marketing_trigger_links_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "HTML Builder",
      value: "html_builder-v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Affiliate Manager",
      value: "tb_affiliate-manager",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Automation",
      value: "automation",
      icon: "📁",
    },
    {
      label: "Workflows",
      value: "workflows_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Content AI",
      value: "contentai",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Campaigns",
      value: "campaigns_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Triggers",
      value: "triggers_v2",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Sites",
      value: "sites-v2",
      icon: "📁",
    },
    {
      label: "Funnels",
      value: "funnels_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Websites",
      value: "websites_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Blogs",
      value: "blogs",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Wordpress",
      value: "wordpress_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Client Portal",
      value: "clientportal",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Forms",
      value: "forms_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Surveys",
      value: "surveys_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Chat Widget",
      value: "chat_widget_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "URL Redirects",
      value: "url_redirects_v2_tb",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Memberships",
      value: "memberships_v2",
      icon: "📁",
    },
    {
      label: "Courses",
      value: "tb_courses",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Dashboard",
      value: "sub_membership-dashboard",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Products",
      value: "sub_membership-products",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Offers",
      value: "sub_membership-offers",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Analytics",
      value: "sub_membership-analytics",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Settings",
      value: "sub_membership-settings",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Reputation",
      value: "reputation_v2",
      icon: "📁",
    },
    {
      label: "Overview",
      value: "overview_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Requests",
      value: "requests_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Reviews",
      value: "reviews_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Listings",
      value: "listings_v2",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Reporting",
      value: "reporting_v2",
      icon: "📁",
    },
    {
      label: "Google Ads",
      value: "google_ads_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Facebook Ads",
      value: "facebook_ads_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Attribution Report",
      value: "attribution_report_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Call reporting",
      value: "call_reporting_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Agent Reporting",
      value: "agent_reporting_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Appointment Report",
      value: "appointment_report_v2",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "App Marketplace",
      value: "sb_app-marketplace",
      icon: "📁",
    },
    {
      label: "Mobile App",
      value: "mobileapp",
      icon: "📁",
    },
    {
      label: "Settings",
      value: "settings_v2",
      icon: "📁",
    },
    {
      label: "Business Info",
      value: "business_info_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "My Staff",
      value: "my_staff_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Pipeline",
      value: "pipeline_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Calendars",
      value: "calendars_settings_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Conversation AI",
      value: "conversation_ai_settings",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Phone Numbers",
      value: "phone_numbers_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Whats App",
      value: "sb_whatsapp",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Reputation Management",
      value: "reputation_management_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Custom Fields",
      value: "custom_fields_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Custom Values",
      value: "custom_values_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Manage Scoring",
      value: "sb_manage-scoring",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Domains",
      value: "domains_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Media",
      value: "media_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "URL Redirects",
      value: "url_redirects_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Integrations",
      value: "integrations_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Email Services",
      value: "email_services_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Conversation Providers",
      value: "conversations_providers_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "SMTP & Mailgun Services",
      value: "smtp_services_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Tags",
      value: "tags_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Labs",
      value: "labs_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Audit Logs",
      value: "auditlogs_v2",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Companies",
      value: "companies_v2",
      icon: "➜",
      type: "subMenu",
    },
  ];

  return (
    <>
      <Button variant="primary" onClick={openModal}>
        Menu Edits
      </Button>

      <Modal show={showModal} onHide={closeModal} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Menu Editor</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "50vh", overflowY: "auto" }}>
          <div className="d-flex align-items-center gap-2">
            <select
              value={selectedItem}
              onChange={handleSelectChange}
              className="form-control"
            >
              <option value="">Select an item</option>
              {menuItems.map((item, index) => (
                <option
                  key={index}
                  value={item.value}
                  style={item.type === "subMenu" ? { paddingLeft: "10px" } : {}}
                >
                  {item?.icon && <span>{item.icon}</span>} {item.label}
                </option>
              ))}
            </select>
            <Button onClick={handleHideClick}>
              <IoMdEyeOff style={{ fontSize: "16px", color: "white" }} />
            </Button>
            <Button onClick={handleEditClick}>
              <FaEdit style={{ fontSize: "16px", color: "white" }} />
            </Button>
          </div>

          {editMode && (
            <Form>
              <Form.Group className="mb-3" controlId="formItemLabel">
                <Form.Label>Menu Item Label</Form.Label>
                <Form.Control
                  type="text"
                  value={label}
                  onChange={handleLabelChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formItemIcon">
                <Form.Control
                  type="text"
                  placeholder="Menu icon- empty will use native icon"
                />
              </Form.Group>
              <Button variant="secondary" onClick={() => setEditMode(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={addEdit} className="ms-2">
                Add Menu Edit
              </Button>
            </Form>
          )}

          <div>
            {editedLabels.length > 0 && (
              <div>
                <p
                  style={{
                    background: "rgb(194, 194, 194)",
                    width: "6rem",
                    color: "rgb(79, 79, 79)",
                    textAlign: "center",
                    fontSize: "8pt",
                    borderRadius: "3px",
                    marginTop: "10px",
                  }}
                >
                  Edited Menus
                </p>
                {editedLabels.map((edit, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center p-2 mb-1"
                    style={{
                      borderLeft: "2px solid rgb(63, 63, 63)",
                      background: "rgb(229, 229, 232)",
                    }}
                  >
                    <div className="d-flex align-items-center gap-2">
                      <span style={{ color: "black" }}>{edit.newLabel}</span>{" "}
                      <span style={{ fontSize: "14px", color: "black" }}>
                        &#10148;
                      </span>{" "}
                      <span style={{ color: "black" }}>
                        {edit.originalLabel}
                      </span>
                    </div>
                    {/* <span>{edit._id}</span> */}
                    <BsTrash
                      onClick={() => handleDeleteEdit(index, edit._id)}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>

          <div>
            {hiddenItems.length > 0 && (
              <div style={{ marginTop: "20px" }}>
                <p
                  style={{
                    background: "rgb(194, 194, 194)",
                    width: "6rem",
                    color: "rgb(79, 79, 79)",
                    textAlign: "center",
                    fontSize: "8pt",
                    borderRadius: "3px",
                    marginTop: "10px",
                  }}
                >
                  Hidden Menus
                </p>
                {hiddenItems.map((itemValue, index) => {
                  const item = menuItems.find(
                    (menuItem) => menuItem.value === itemValue
                  );
                  return (
                    <div
                      key={index}
                      className="d-flex justify-content-between align-items-center p-2 mb-1 "
                      style={{
                        borderLeft: "2px solid rgb(63, 63, 63)",
                        background: "rgb(229, 229, 232)",
                      }}
                    >
                      <div style={{ color: "black" }}>
                        {itemValue?.icon && <span>{itemValue.icon}</span>}{" "}
                        {itemValue?.originalLabel}
                        {/* {JSON.stringify(itemValue)} */}
                      </div>

                      <BsTrash
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          removeHiddenItem(itemValue, itemValue._id)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={closeModal}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MenuEditorModal;
