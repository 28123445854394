import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAppServices } from "../../../hook/services";
import { useAgencyInfo } from "../../../context/agency";
import { useUserInfo } from "../../../context/user";
import localforage from "localforage";

export default function Signin() {
  const Service = useAppServices();
  const updateAgency = useAgencyInfo()[1];
  const updateUser = useUserInfo()[1];
  const navigate = useNavigate();

  const [processing, setProcessing] = useState(false);
  const [message, setMessage] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      email: e.target.email.value,
      password: e.target.password.value,
      role: "agency",
    };
    console.log(payload, "payload");
    const { response, error } = await Service.auth.login({ payload: payload });
    setProcessing(false);
    if (error) return setMessage(error.message);

    // Set locally
    const { token, ...user } = response.data;
    localforage.setItem("token", token);
    localforage.setItem("user", user);
    // response.brand && localforage.setItem('brand', response.brand)
    response.agency && localforage.setItem("agency", response.agency);

    // Update Context
    // response.brand && updateBrand({...response.brand})
    response.agency && updateAgency({ ...response.agency });
    updateUser(response.data);
    return response ? navigate(`/dashboard`) : "";
  };
  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">
            dashbyte
          </Link>
          <Card.Title>Sign In</Card.Title>
          <Card.Text>Welcome back! Please signin to continue.</Card.Text>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter your email address"
                // defaultValue="me@themepixels.com"
              />
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                Password <Link to="">Forgot password?</Link>
              </Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Enter your password"
                // defaultValue="password123"
              />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">
              Sign In
            </Button>

            <div className="divider">
              <span>or sign in with</span>
            </div>

            <Row className="gx-2">
              <Col>
                <Button variant="" className="btn-facebook">
                  <i className="ri-facebook-fill"></i> Facebook
                </Button>
              </Col>
              <Col>
                <Button variant="" className="btn-google">
                  <i className="ri-google-fill"></i> Google
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
        <Card.Footer>
          Don't have an account?{" "}
          <Link to="/pages/signup">Create an Account</Link>
        </Card.Footer>
      </Card>
    </div>
  );
}
