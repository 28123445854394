import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

const LeftSideBar = () => {
  const [sideBarBackground, setSideBarBackground] = useState("#562929");

  const [subHeadingColor, setSubHeadingColor] = useState("#562929");
  const [backButtonBackground, setBackButtonBackground] = useState("#c84646");
  const [backButtonColor, setBackButtonColor] = useState("#916eb4");
  const [menuColor, setMenuColor] = useState("#aad803");
  const [menuColorHover, setMenuColorHover] = useState("#fffff");
  const [menuBackground, setMenuBackground] = useState("#fffff");
  const [menuBackgroundHover, setMenuBackgroundHover] = useState("#fffff");
  const [menuIconBackground, setMenuIconBackground] = useState("#185eb9");
  const [menuIconBackgroundHover, setMenuIconBackgroundHover] =
    useState("#06479d");
  const [menuDivider, setMenuDivider] = useState("#c2cbd6");
  const [subMenuBackground, setSubMenuBackground] = useState("#1e3148");
  const [subMenuBackgroundHover, setSubMenuBackgroundHover] =
    useState("#1e3148");
  const [subMenuColor, setSubMenuColor] = useState("#0ebe52");
  const [subMenuColorHover, setSubMenuColorHover] = useState("#0ebe52");
  const [navbarTogglerBackground, setNavbarTogglerBackground] =
    useState("#86b196");
  const [navbarTogglerForeground, setNavbarTogglerForeground] =
    useState("#86b196");
  const [iconColorWhite, setIconColorWhite] = useState(false);
  const [iconColorWhiteHover, setIconColorWhiteHover] = useState(false);
  const [iconColorBlack, setIconColorBlack] = useState(false);
  const [iconColorBlackHover, setIconColorBlackHover] = useState(false);

  return (
    <>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Sidebar Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={sideBarBackground}
                  name="sideBarBackground"
                  onChange={(e) => setSideBarBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={sideBarBackground}
                  onChange={(e) => setSideBarBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Subheading Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={subHeadingColor}
                  name="subHeadingColor"
                  onChange={(e) => setSubHeadingColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={subHeadingColor}
                  onChange={(e) => setSubHeadingColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Back Button Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={backButtonBackground}
                  name="backButtonBackground"
                  onChange={(e) => setBackButtonBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={backButtonBackground}
                  onChange={(e) => setBackButtonBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Back Button Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={backButtonColor}
                  name="backButtonColor"
                  onChange={(e) => setBackButtonColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={backButtonColor}
                  onChange={(e) => setBackButtonColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Menu Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={menuBackground}
                  name="menuBackground"
                  onChange={(e) => setMenuBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={menuBackground}
                  onChange={(e) => setMenuBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Menu Background (Active/Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={menuBackgroundHover}
                  name="menuBackgroundHover"
                  onChange={(e) => setMenuBackgroundHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={menuBackgroundHover}
                  onChange={(e) => setMenuBackgroundHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Menu Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={menuColor}
                  name="menuColor"
                  onChange={(e) => setMenuColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={menuColor}
                  onChange={(e) => setMenuColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Menu Color (Active/Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={menuColorHover}
                  name="menuColorHover"
                  onChange={(e) => setMenuColorHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={menuColorHover}
                  onChange={(e) => setMenuColorHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Menu Icon Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={menuIconBackground}
                  name="menuIconBackground"
                  onChange={(e) => setMenuIconBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={menuIconBackground}
                  onChange={(e) => setMenuIconBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Menu Icon Background (Active/Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={menuIconBackgroundHover}
                  name="menuIconBackgroundHover"
                  onChange={(e) => setMenuIconBackgroundHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={menuIconBackgroundHover}
                  onChange={(e) => setMenuIconBackgroundHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Menu Divider
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={menuDivider}
                  name="menuDivider"
                  onChange={(e) => setMenuDivider(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={menuDivider}
                  onChange={(e) => setMenuDivider(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Submenu Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={subMenuBackground}
                  name="subMenuBackground"
                  onChange={(e) => setSubMenuBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={subMenuBackground}
                  onChange={(e) => setSubMenuBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Submenu Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={subMenuColor}
                  name="subMenuColor"
                  onChange={(e) => setSubMenuColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={subMenuColor}
                  onChange={(e) => setSubMenuColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Submenu Background (Active/Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={subMenuBackgroundHover}
                  name="subMenuBackgroundHover"
                  onChange={(e) => setSubMenuBackgroundHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={subMenuBackgroundHover}
                  onChange={(e) => setSubMenuBackgroundHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Submenu Color (Active/Hover)
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={subMenuColorHover}
                  name="subMenuColorHover"
                  onChange={(e) => setSubMenuColorHover(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={subMenuColorHover}
                  onChange={(e) => setSubMenuColorHover(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Navbar Toggler Background
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={navbarTogglerBackground}
                  name="navbarTogglerBackground"
                  onChange={(e) => setNavbarTogglerBackground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={navbarTogglerBackground}
                  onChange={(e) => setNavbarTogglerBackground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Navbar Toggler Foreground
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={navbarTogglerForeground}
                  name="navbarTogglerForeground"
                  onChange={(e) => setNavbarTogglerForeground(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={navbarTogglerForeground}
                  onChange={(e) => setNavbarTogglerForeground(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col>
          <div
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Label>Icon Color (White)</Form.Label>
            <div>
              <Form.Check
                type="switch"
                name="iconColorWhite"
                id="iconColorWhite"
                checked={iconColorWhite}
                onChange={(e) => setIconColorWhite(e.target.checked)}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Label>Icon Color (Black)</Form.Label>
            <div>
              <Form.Check
                type="switch"
                name="iconColorBlack"
                id="iconColorBlack"
                checked={iconColorBlack}
                onChange={(e) => setIconColorBlack(e.target.checked)}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Label>Active/Hover Icon Color (White)</Form.Label>
            <div>
              <Form.Check
                type="switch"
                name="iconColorWhiteHover"
                id="iconColorWhiteHover"
                checked={iconColorWhiteHover}
                onChange={(e) => setIconColorWhiteHover(e.target.checked)}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Label>Active/Hover Icon Color (Black)</Form.Label>
            <div>
              <Form.Check
                type="switch"
                name="iconColorBlackHover"
                id="iconColorBlackHover"
                checked={iconColorBlackHover}
                onChange={(e) => setIconColorBlackHover(e.target.checked)}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LeftSideBar;
