import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";

const Dashboard = () => {
  const [saasyDashboardCards ,setSaasyDashboardCards]=useState(false)
  const [statusColor, setStatusColor] = useState("#355f1");
  const [cardValueBackground, setCardValueBackground] = useState("#ffbc00");
  const [cardValueColor, setCardValueColor] = useState("#eeeeee");
  const [closedStatsBackground, setClosedStatsBackground] = useState("#333333");
  const [closedStatsColor, setClosedStatsColor] = useState("#ffffff");
  const [openStatsBackground, setOpenStatsBackground] = useState("#ffffff");
  const [openStatsColor, setOpenStatsColor] = useState("#ffffff");
  const [lostStatsBackground, setLostStatsBackground] = useState("#528bff");
  const [lostStatsColor, setLostStatsColor] = useState("#ffffff");
  const [wonStatsBackground, setWonStatsBackground] = useState("#eeeeee");
  const [wonStatsColor, setWonStatsColor] = useState("#e93d3d");
  const [statsValueBackground, setStatsValueBackground] = useState("#333333");
  const [statsValueColor, setStatsValueColor] = useState("#ffffff");
  const [customButtonsBackground, setCustomButtonsBackground] =
    useState("#ffffff");
  const [customButtonsbgHover, setCustomButtonsbgHover] = useState("#ffffff");
  const [customButtonsBorder, setCustomButtonsBorder] = useState("#355ff1");
  const [customButtonsBorderHover, setCustomButtonsBorderHover] =
    useState("#355ff1");
  const [customButtonsColor, setCustomButtonsColor] = useState("#373c9f");
  const [customButtonsColorHover, setCustomButtonsColorHover] =
    useState("#355ff1");

  return (
    <>
     
        <Row className="mb-3">
          <Col>
            <div
            
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Form.Label>SAASY Dashboard Cards</Form.Label>
              <div>
                <Form.Check type="switch" id="saasyDashboardCards" 
                 checked={saasyDashboardCards} 
                 name="saasDashboardCards"
                 onChange={(e) => setSaasyDashboardCards(e.target.checked)}
                />
              </div>
            </div>
          </Col>
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={4}>
                Status Color
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={statusColor}
                    name="statusColor"
                    onChange={(e) => setStatusColor(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={statusColor}
                    name="statusColor"
                    onChange={(e) => setStatusColor(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
                Card Value Background
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={cardValueBackground}
                    name="cardValueBackground"
                    onChange={(e) => setCardValueBackground(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={cardValueBackground}
                    onChange={(e) => setCardValueBackground(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={4}>
                Card Value Color
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={cardValueColor}
                    name="cardValueColor"
                    onChange={(e) => setCardValueColor(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={cardValueColor}
                    onChange={(e) => setCardValueColor(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
                Closed Stats Background
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={closedStatsBackground}
                    name="closedStatsBackground"
                    onChange={(e) => setClosedStatsBackground(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={closedStatsBackground}
                    onChange={(e) => setClosedStatsBackground(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
                Closed Stats Color
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={closedStatsColor}
                    name="closedStatsColor"
                    onChange={(e) => setClosedStatsColor(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={closedStatsColor}
                    onChange={(e) => setClosedStatsColor(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
                Open Stats Background
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={openStatsBackground}
                    name="openStatsBackground"
                    onChange={(e) => setOpenStatsBackground(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={openStatsBackground}
                    onChange={(e) => setOpenStatsBackground(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
                Open Stats Color
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={openStatsColor}
                    name="openStatsColor"
                    onChange={(e) => setOpenStatsColor(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={openStatsColor}
                    onChange={(e) => setOpenStatsColor(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
                Lost Stats Background
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={lostStatsBackground}
                    name="lostStatsBackground"
                    onChange={(e) => setLostStatsBackground(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={lostStatsBackground}
                    onChange={(e) => setLostStatsBackground(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
                Lost Stats Color
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={lostStatsColor}
                    name="lostStatsColor"
                    onChange={(e) => setLostStatsColor(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={lostStatsColor}
                    onChange={(e) => setLostStatsColor(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
                Won Stats Background
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={wonStatsBackground}
                    name="wonStatsBackground"
                    onChange={(e) => setWonStatsBackground(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={wonStatsBackground}
                    onChange={(e) => setWonStatsBackground(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
                Won Stats Color
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={wonStatsColor}
                    name="wonStatsColor"
                    onChange={(e) => setWonStatsColor(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={wonStatsColor}
                    onChange={(e) => setWonStatsColor(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
              Stats Value Background
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={statsValueBackground}
                    name="statsValueBackground"
                    onChange={(e) => setStatsValueBackground(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={statsValueBackground}
                    onChange={(e) => setStatsValueBackground(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
              Stats Value Color
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={statsValueColor}
                    name="statsValueColor"
                    onChange={(e) => setStatsValueColor(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={statsValueColor}
                    onChange={(e) => setStatsValueColor(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
              Custom Buttons Background
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={customButtonsBackground}
                    name="customButtonsBackground"
                    onChange={(e) => setCustomButtonsBackground(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={customButtonsBackground}
                    onChange={(e) => setCustomButtonsBackground(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
              Custom Buttons Background (Hover)
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={customButtonsbgHover}
                    name="customButtonsBackgroundHover"
                    onChange={(e) => setCustomButtonsbgHover(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={customButtonsbgHover}
                    onChange={(e) => setCustomButtonsbgHover(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
              Custom Buttons Border
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={customButtonsBorder}
                    name="customButtonsBorder"
                    onChange={(e) => setCustomButtonsBorder(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={customButtonsBorder}
                    onChange={(e) => setCustomButtonsBorder(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
              Custom Buttons Border (Hover)
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={customButtonsBorderHover}
                    name="customButtonsBorderHover"
                    onChange={(e) => setCustomButtonsBorderHover(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={customButtonsBorderHover}
                    onChange={(e) => setCustomButtonsBorderHover(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
              Custom Buttons Color
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={customButtonsColor}
                    name="customButtonsColor"
                    onChange={(e) => setCustomButtonsColor(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={customButtonsColor}
                    onChange={(e) => setCustomButtonsColor(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group
              as={Row}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
              }}
            >
              <Form.Label column sm={8}>
              Custom Buttons Color (Hover)
              </Form.Label>
              <Row sm={8}>
                <div className="d-flex">
                  <input
                    type="color"
                    value={customButtonsColorHover}
                    name="customButtonsColorHover"
                    onChange={(e) => setCustomButtonsColorHover(e.target.value)}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                  <Form.Control
                    type="text"
                    value={customButtonsColorHover}
                    onChange={(e) => setCustomButtonsColorHover(e.target.value)}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
        </Row>
   
    </>
  );
};

export default Dashboard;
