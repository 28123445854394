import React, { useEffect, useState } from "react";
import { useAppServices } from "../../hook/services";

const Analytics = () => {
    const Service = useAppServices();
    const searchParams = new URLSearchParams(window.location.search);
    const googleCode = searchParams.get("code");

    const submitHandler = async (e) => {
        e.preventDefault();
        const { response } = await Service.app_token.googleAccessToken();
        if (response) {
            window.location.href = response.data
        }
    };

    const getAccessToken = async (code) => {
        const { response } = await Service.app_token.googleAccessToken({
            query: `code=${code}`
        });
        console.log('response', response)
    }

    const getTracingId = async () => {
        const { response } = await Service.apps.getTrackingId();
        console.log('responsesss', response);
    }

    useEffect(() => {
        if (googleCode !== null) {
            getAccessToken(googleCode);
        }
    }, [googleCode])

    return (
        <>
            <button type="submit" onClick={(e) => submitHandler(e)}>Get Access Token</button>
            <button type="submit" onClick={(e) => getTracingId(e)}>Get Tracking ID</button>
        </>
    )
}

export default Analytics;
