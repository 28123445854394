import React, { useEffect, useState } from "react";
import { Modal, Button, ListGroup, Form } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import AddNewTask from "./components/AddNewTask";
import { useAppServices } from "../../../../../../hook/services";
const targetLocations = [
  "launchpad",
  "dashboard",
  "conversations/conversations",
  "conversations/manual_actions",
  "conversations/templates",
  "conversations/links/link",
  "conversations/links/analyze",
  "calendars/view",
  "calendars/appointments",
  "settings/calendars",
  "contacts/smart_list/All",
  "contacts/bulk/actions",
  "contacts/restore",
  "tasks",
  "businesses",
  "settings/smart_list/Settings",
  "opportunities/list",
  "opportunities/pipeline",
  "payments/invoices",
  "payments/recurring-templates",
  "payments/proposals-estimates",
  "payments/v2/orders",
  "payments/v2/subscriptions",
  "payments/v2/transactions",
  "payments/products",
  "payments/coupons",
  "payments/settings/receipts",
  "payments/v2/taxes",
  "payments/integrations",
  "marketing/social-planner",
  "marketing/emails/scheduled",
  "marketing/emails/all",
  "marketing/templates",
  "marketing/links/link",
  "marketing/links/analyze",
  "marketing/affiliate-manager/campaigns",
  "marketing/affiliate-manager/affiliates",
  "marketing/affiliate-manager/payouts",
  "marketing/affiliate-manager/media",
  "automation/workflows",
  "automation/campaigns",
  "automation/triggers",
  "funnels-websites/funnels",
  "funnels-websites/websites",
  "blogs",
  "funnels-websites/client-portal/domain-setup",
  "funnels-websites/client-portal/branding",
  "funnels-websites/wordpress/wordpress_dashboard-v2",
  "form-builder/main",
  "form-builder/analyze",
  "form-builder/submissions",
  "survey-builder/main",
  "survey-builder/analyze",
  "survey-builder/submissions",
  "funnels-websites/chat_widget_settings",
  "settings/media-settings",
  "settings/redirect",
  "memberships/courses/dashboard",
  "memberships/courses/products",
  "memberships/courses/offers",
  "memberships/courses/analytics",
  "memberships/courses/settings",
  "memberships/communities/community-groups",
  "memberships/communities/clientportal-domain-setup",
  "reputation/overview",
  "reputation/requests",
  "reputation/reviews",
  "reputation/listing",
  "reporting/google",
  "reporting/facebook",
  "reporting/attribution/conversion_report",
  "reporting/attribution/source_report",
  "reporting/attribution-v2",
  "reporting/call_stats",
  "reporting/appointments-reports",
  "settings/company",
  "settings/staff/team",
  "settings/staff/teams",
  "settings/pipeline",
  "settings/businesses",
  "settings/calendars/preferences",
  "settings/calendars/availability",
  "settings/calendars/connections",
  "settings/phone_number",
  "settings/reputation/settings",
  "settings/fields",
  "settings/custom_values",
  "settings/conversation_providers",
  "settings/domain",
  "settings/integrations/list",
  "settings/integrations/facebook",
  "settings/integrations/tiktok",
  "settings/smtp_service",
  "settings/tags",
  "settings/labs",
  "settings/audit/logs",
];
function LaunchpadModal({ sso_cred }) {
  const AppService = useAppServices();
  const fetchData = async () => {
    const { response } = await AppService.launchPad.get({
      query: `company_id=${sso_cred.companyId}`,
    });
    

    
    // setTasks(response.data[0].tasks);
    setTasks(response.data && response.data[0] && response.data[0].tasks ? response.data[0].tasks : []);
    // console.log([...tasks], "tasks");
  };
  useEffect(() => {
    fetchData();
  }, [sso_cred]);
  const defaultTasks = [
    {
      type: "default",
      selector: "chat-connect",
      id: 1,
      description: "Download our app and engage with your leads on the go!",
      buttonText: "Send Link",
      imageUrl: "https://static.msgsndr.com/img/icon-android-ios.3a3fcb9c.png",
      tagline: "",
    },
    {
      type: "default",
      selector: "gmb-connect",
      id: 2,
      description:
        "Generate more leads from Google My Business! Monitor and reply to GMB conversations & reviews.",
      buttonText: "Connect",
      imageUrl: "https://static.msgsndr.com/img/gmb.90d0fb06.svg",
    },
    {
      type: "default",
      selector: "fb-connect",
      id: 3,
      description:
        "Connect directly with prospects and customers via Messenger in Conversation and sync your Facebook leads with our CRM.",
      buttonText: "Connect",
      imageUrl: "https://static.msgsndr.com/img/icon-facebook.0fedcae1.svg",
    },
    {
      type: "default",
      selector: "chat-connect",
      id: 4,
      description:
        "Generate leads from your website by connecting webchat widget.",
      buttonText: "Connect",
      imageUrl:
        "https://static.msgsndr.com/img/icon-chat-widget-integration.ee9ce5e8.svg",
    },
    {
      type: "default",
      selector: "chat-connect",
      id: 5,
      description: "Connect your Stripe account to start accepting payments.",
      buttonText: "Connect",
      imageUrl: "https://static.msgsndr.com/img/stripe_logo.5a4dee84.png",
      tagline:
        "Existing stripe API integration will continue to work, but it is advised to use Stripe Connect for more security",
    },
    {
      type: "default",
      id: 6,
      selector: "yext-connect",
      description:
        "Yext enables you to list your business on 70+ portals and websites to boost online presence and SEO instantly.",
      buttonText: "Connect",
      imageUrl: "https://static.msgsndr.com/pmd/img/icon-yext-logo.svg",
      tagline: "",
    },
    {
      type: "default",
      id: 7,
      selector: "wordpress-connect",
      description: "Move your WordPress site to our hosting fast and securely.",
      buttonText: "Connect",
      imageUrl: "https://static.msgsndr.com/pmd/img/icon-wordpress-logo.jpg",
      tagline: "",
    },
    {
      type: "default",
      id: 8,
      selector: "invite-user",
      description: "Quickly add one or more team members.",
      buttonText: "Add",
      imageUrl: "https://static.msgsndr.com/img/icon-add-member.a0b41d16.svg",
      tagline:
        "The new user(s) will have the same permissions like yours except the ability to add new users.",
    },
  ];

  const [showLaunchpad, setShowLaunchpad] = useState(false);
  const [currentTask, setCurrentTask] = useState({});
  const [taskDescription, setTaskDescription] = useState("");
  const [taskButton, setTaskButton] = useState("");
  const [buttonType, setButtonType] = useState("");
  const [buttonLink, setButtonLink] = useState("");
  const [buttonTarget, setButtonTarget] = useState("");
  const [taskImageUrl, setTaskImageUrl] = useState("");
  const [taskTagline, setTaskTagline] = useState("");
  const [tasks, setTasks] = useState(defaultTasks);

  const handleLaunchpadShow = () => setShowLaunchpad(true);

  const handleEditTaskShow = (task) => {
    setCurrentTask(task);
    setTaskDescription(task.description);
    setTaskButton(task.buttonText);
    setButtonTarget(task.buttonTarget);
    setButtonLink(task.buttonLink);
    setButtonType(task.buttonType);
    setTaskImageUrl(task.imageUrl);
    setTaskTagline(task.tagline);
    setShowLaunchpad(true);
  };

  const handleDescriptionChange = (e) => setTaskDescription(e.target.value);

  const handleButtonChange = (e) => setTaskButton(e.target.value);
  const handleButtonType = (e) => setButtonType(e.target.value);
  const handleButtonLink = (e) => {
    setButtonLink(e.target.value);
    setButtonTarget("");
  };
  const handleButtonTarget = (e) => {
    setButtonTarget(e.target.value);
    setButtonLink("");
  };

  const handleImageUrlChange = (e) => setTaskImageUrl(e.target.value);

  const handleTaglineChange = (e) => setTaskTagline(e.target.value);

  const handleSaveChanges = async () => {
    const updatedTask = {
      ...currentTask,
      description: taskDescription,
      buttonText: taskButton,
      imageUrl: taskImageUrl,
      tagline: taskTagline,
      buttonType: buttonType,
      buttonLink: buttonLink,
      buttonTarget: buttonTarget,
    };
    const updatedTasks = tasks.map((task) =>
      task.id === currentTask.id ? updatedTask : task
    );
    setTasks(updatedTasks);
    console.log(updatedTasks, "updatedTasks");
    const payload = {
      company_id: sso_cred.companyId,
      tasks: updatedTasks,
    };
    console.log(payload, "payload");
    const { response } = await AppService.launchPad.create({
      payload: payload,
    });
    console.log(response);
    setCurrentTask({});
    setTaskDescription("");
    setTaskButton("");
    setButtonTarget("");
    setButtonLink("");
    setButtonType("");
    setTaskImageUrl("");
    setTaskTagline("");
  };

  const handleSaveTask = async (newTask) => {
    const taskId = tasks.length + 1;
    const updatedTasks = [...tasks, { id: taskId, ...newTask, hidden: false }];
    setTasks(updatedTasks);
    console.log(updatedTasks, "updatedtask");
    const payload = {
      company_id: sso_cred.companyId,
      tasks: updatedTasks,
    };
    console.log(payload, "payload");
    const { response } = await AppService.launchPad.create({
      payload: payload,
    });
    console.log(response);
  };

  const handleResetToDefault = async () => {
    setTasks(defaultTasks);
    setCurrentTask({});
    setTaskDescription("");
    setButtonType("");
    setTaskButton("");
    setButtonLink("");
    setButtonTarget("");
    setTaskImageUrl("");
    setTaskTagline("");
    setShowLaunchpad(true);
    const { response } = await AppService.launchPad.delete({
      query: `company_id=${sso_cred.companyId}`,
    });
    console.log(response, "response");
    fetchData();
  };

  const handleCancel = () => {
    setCurrentTask({});
    setTaskDescription("");
    setTaskButton("");
    setButtonType("");
    setButtonTarget("");
    setButtonLink("");
    setTaskImageUrl("");
    setTaskTagline("");
  };

  const handleClose = () => {
    setShowLaunchpad(false);
  };

  const toggleTaskVisibility = (taskId) => {
    const updatedTasks = tasks.map((task) =>
      task.id === taskId ? { ...task, hidden: !task.hidden } : task
    );
    setTasks(updatedTasks);
  };

  return (
    <>
      <Button variant="primary" onClick={handleLaunchpadShow}>
        Launchpad
      </Button>

      <Modal show={showLaunchpad} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {currentTask.id ? "Edit Task" : "Launchpad"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "90vh", overflowY: "auto" }}>
          {currentTask.id ? (
            <Form>
              <Form.Group controlId="taskImage">
                <Form.Label>Image URL</Form.Label>
                <Form.Control
                  type="text"
                  name="imageUrl"
                  value={taskImageUrl}
                  onChange={handleImageUrlChange}
                />
              </Form.Group>

              <Form.Group controlId="taskDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={taskDescription}
                  onChange={handleDescriptionChange}
                />
              </Form.Group>

              <Form.Group controlId="taskTagline">
                <Form.Label>Tagline</Form.Label>
                <Form.Control
                  type="text"
                  name="tagline"
                  value={taskTagline}
                  onChange={handleTaglineChange}
                />
              </Form.Group>

              <Form.Group controlId="taskButton">
                <Form.Label>Button Text</Form.Label>
                <Form.Control
                  type="text"
                  name="buttonText"
                  value={taskButton}
                  onChange={handleButtonChange}
                />
              </Form.Group>
              {!currentTask.type && (
                <Form.Group controlId="formButtonType">
                  <Form.Label>Button Type</Form.Label>
                  <Form.Control
                    as="select"
                    name="buttonType"
                    value={buttonType}
                    onChange={handleButtonType}
                  >
                    <option value="link">Link</option>
                    <option value="target">Target</option>
                  </Form.Control>
                </Form.Group>
              )}
              {buttonType === "link" && (
                <Form.Group controlId="formButtonLink">
                  <Form.Label>Button Link</Form.Label>
                  <Form.Control
                    type="text"
                    name="buttonLink"
                    value={buttonLink}
                    onChange={handleButtonLink}
                  />
                </Form.Group>
              )}

              {buttonType === "target" && (
                <Form.Group controlId="formButtonTarget">
                  <Form.Label>Button Target</Form.Label>
                  <Form.Control
                    as="select"
                    name="buttonTarget"
                    value={buttonTarget}
                    onChange={handleButtonTarget}
                  >
                    {targetLocations.map((location) => (
                      <option key={location} value={location}>
                        {location}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}

              <Form.Group controlId="hideTaskCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Hide Task"
                  onChange={() => toggleTaskVisibility(currentTask.id)}
                />
              </Form.Group>
            </Form>
          ) : (
            <ListGroup>
              <AddNewTask onSave={handleSaveTask} defaultTask={defaultTasks} />
              {tasks.map((task, index) => (
                <ListGroup.Item
                  key={index}
                  className={`launchpad-item d-flex ${
                    task.hidden ? "task-hidden" : ""
                  }`}
                  onClick={() => handleEditTaskShow(task)}
                >
                  <div className="d-flex align-items-center gap-2">
                    {task.imageUrl && (
                      <img
                        src={task.imageUrl}
                        alt="Task"
                        style={{
                          width: "50px",
                          height: "50px",
                          marginRight: "10px",
                        }}
                      />
                    )}
                    <div>
                      <div>{task.description}</div>
                      <div style={{ fontSize: "12px" }}>
                        {task.tagline && ` (${task.tagline})`}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <button
                      style={{
                        border: "1px solid rgb(56, 161, 105)",
                        background: "rgb(56, 161, 105)",
                        color: "rgb(255, 255, 255)",
                        borderRadius: "3px",
                        fontWeight: "bold",
                        fontSize: "8pt",
                        padding: "12px 14px",
                        cursor: "pointer",
                      }}
                    >
                      {task.buttonText}
                    </button>
                    <FaEdit
                      className="edit-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditTaskShow(task);
                      }}
                    />
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Modal.Body>
        {(currentTask.id || tasks.length > 0) && (
          <Modal.Footer>
            {!currentTask.id && <Button variant="primary">Save</Button>}
            <Button variant="secondary" onClick={handleResetToDefault}>
              Reset to default
            </Button>
            {currentTask.id && (
              <>
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleSaveChanges}>
                  Save Changes
                </Button>
              </>
            )}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default LaunchpadModal;
