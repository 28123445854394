import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

const ExtraFooterHeader = () => {
  const [headerNoteColor, setHeaderNoteColor] = useState("#813636");
  const [footerNoteColors, setFooterNoteColor] = useState("#727272");
  const [headerNote, setHeaderNote] = useState("");
  const [footerNote, setFooterNote] = useState("");

  const handleHeaderNoteChange = (e) => {
    setHeaderNote(e.target.value);
  };

  const handleFooterNoteChange = (e) => {
    setFooterNote(e.target.value);
  };

  return (
    <>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Header Note Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={headerNoteColor}
                  name="headerNoteColor"
                  onChange={(e) => setHeaderNoteColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={headerNoteColor}
                  onChange={(e) => setHeaderNoteColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Footer Note Color
            </Form.Label>
            <Row sm={8}>
              <div className="d-flex">
                <input
                  type="color"
                  value={footerNoteColors}
                  name="footerNoteColors"
                  onChange={(e) => setFooterNoteColor(e.target.value)}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
                <Form.Control
                  type="text"
                  value={footerNoteColors}
                  onChange={(e) => setFooterNoteColor(e.target.value)}
                />
              </div>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Header Note
            </Form.Label>
            
              <Form.Group controlId="headerNoteTextarea">
                <Form.Control
                  as="textarea"
                  name="headerNote"
                  rows={3}
                  value={headerNote}
                  onChange={handleHeaderNoteChange}
                />
              </Form.Group>
            
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            as={Row}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              margin: "10px",
            }}
          >
            <Form.Label column sm={8}>
              Footer Note
            </Form.Label>
            
              <Form.Group controlId="footerNoteTextarea">
                <Form.Control
                  as="textarea"
                  name="footerNote"
                  rows={3}
                  value={footerNote}
                  onChange={handleFooterNoteChange}
                />
              </Form.Group>
            
          </Form.Group>
        </Col>
     
      </Row>
    </>
  );
};

export default ExtraFooterHeader;
