import React, { useState } from "react";
import {
  Modal,
  Button,
  ListGroup,
  Form,
  DropdownButton,
  Dropdown,
  InputGroup,
} from "react-bootstrap";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import "../../style.css";

const targetLocations = [
  "launchpad",
  "dashboard ",
  "conversations/conversations",
  "conversations/manual_actions",
  "conversations/templates",
  "conversations/links/link",
  "conversations/links/analyze",
  "calendars/view",
  "calendars/appointments",
  "settings/calendars",
  "contacts/smart_list/All",
  "contacts/bulk/actions",
  "contacts/restore",
  "tasks",
  "businesses",
  "settings/smart_list/Settings",
  "opportunities/list",
  "opportunities/pipeline",
  "payments/invoices",
  "payments/recurring-templates",
  "payments/proposals-estimates",
  "payments/v2/orders",
  "payments/v2/subscriptions",
  "payments/v2/transactions",
  "payments/products",
  "payments/coupons",
  "payments/settings/receipts",
  "payments/v2/taxes",
  "payments/integrations",
  "marketing/social-planner",
  "marketing/emails/scheduled",
  "marketing/emails/all",
  "marketing/templates",
  "marketing/links/link",
  "marketing/links/analyze",
  "marketing/affiliate-manager/campaigns",
  "marketing/affiliate-manager/affiliates",
  "marketing/affiliate-manager/payouts",
  "marketing/affiliate-manager/media",
  "automation/workflows",
  "automation/campaigns",
  "automation/triggers",
  "funnels-websites/funnels",
  "funnels-websites/websites",
  "blogs",
  "funnels-websites/client-portal/domain-setup",
  "funnels-websites/client-portal/branding",
  "funnels-websites/wordpress/wordpress_dashboard-v2",
  "form-builder/main",
  "form-builder/analyze",
  "form-builder/submissions",
  "survey-builder/main",
  "survey-builder/analyze",
  "survey-builder/submissions",
  "funnels-websites/chat_widget_settings",
  "settings/media-settings",
  "settings/redirect",
  "memberships/courses/dashboard",
  "memberships/courses/products",
  "memberships/courses/offers",
  "memberships/courses/analytics",
  "memberships/courses/settings",
  "memberships/communities/community-groups",
  "memberships/communities/clientportal-domain-setup",
  "reputation/overview",
  "reputation/requests",
  "reputation/reviews",
  "reputation/listing",
  "reporting/google",
  "reporting/facebook",
  "reporting/attribution/conversion_report",
  "reporting/attribution/source_report",
  "reporting/attribution-v2",
  "reporting/call_stats",
  "reporting/appointments-reports",
  "settings/company",
  "settings/staff/team",
  "settings/staff/teams",
  "settings/pipeline",
  "settings/businesses",
  "settings/calendars/preferences",
  "settings/calendars/availability",
  "settings/calendars/connections",
  "settings/phone_number",
  "settings/reputation/settings",
  "settings/fields",
  "settings/custom_values",
  "settings/conversation_providers",
  "settings/domain",
  "settings/integrations/list",
  "settings/integrations/facebook",
  "settings/integrations/tiktok",
  "settings/smtp_service",
  "settings/tags",
  "settings/labs",
  "settings/audit/logs",
];

function QuickActionsModal() {
  const [showModal, setShowModal] = useState(false);
  const [linkType, setLinkType] = useState(null);
  const [targetLocation, setTargetLocation] = useState("");
  const [expandedAction, setExpandedAction] = useState(null);
  const [actions, setActions] = useState({
    createOpportunity: {
      name: "Create an Opportunity",
      title: "Create a new",
      icon: "graph-up",
      default: true,
    },
    addContact: {
      name: "Add a Contact",
      title: "Add a new Contact from scratch",
      icon: "person-plus",
      default: true,
    },
    sendReview: {
      name: "Send a Review Request",
      title: "Send a review request to the customizer",
      icon: "envelope-open",
      default: true,
    },
    createAppointment: {
      name: "Create an Appointment",
      title: "Book a new appointment in calendar",
      icon: "calendar-check",
      default: true,
    },
  });
  const [actionCount, setActionCount] = useState(Object.keys(actions).length);
  const [hiddenActions, setHiddenActions] = useState({});

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleExpand = (actionKey, isEyeClick) => {
    if (!isEyeClick) {
      setExpandedAction(expandedAction === actionKey ? null : actionKey);
    }
  };

  const handleChange = (actionKey, field, value) => {
    setActions((prevActions) => ({
      ...prevActions,
      [actionKey]: {
        ...prevActions[actionKey],
        [field]: value,
      },
    }));
  };

  const addAction = () => {
    const newActionKey = `newAction${actionCount + 1}`;
    setActions((prevActions) => ({
      ...prevActions,
      [newActionKey]: {
        name: "New Action",
        title: "Subtitle here",
        icon: "",
        default: false,
      },
    }));
    setActionCount((prevCount) => prevCount + 1);
  };

  const deleteAction = (actionKey) => {
    const { [actionKey]: deletedAction, ...remainingActions } = actions;
    setActions(remainingActions);
  };

  const actionInputs = (actionKey) => {
    const action = actions[actionKey];
    const isNewAction = !action.default;

    return (
      expandedAction === actionKey && (
        <Form>
          <Form.Group controlId={`${actionKey}-name`}>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={action.name}
              onChange={(e) => handleChange(actionKey, "name", e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId={`${actionKey}-title`}>
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter subtitle"
              value={action.title}
              onChange={(e) => handleChange(actionKey, "title", e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId={`${actionKey}-icon`}>
            <Form.Label>Icon</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter icon class"
              value={action.icon}
              onChange={(e) => handleChange(actionKey, "icon", e.target.value)}
            />
          </Form.Group>

          {isNewAction && (
            <Form.Group controlId={`${actionKey}-link-type`}>
              <Form.Check
                type="radio"
                label="Target"
                checked={linkType === "target"}
                onChange={() => setLinkType("target")}
              />
              <Form.Check
                type="radio"
                label="Link"
                checked={linkType === "link"}
                onChange={() => setLinkType("link")}
              />
              <Form.Check
                type="radio"
                label="Link of Target"
                checked={linkType === "linkOfTarget"}
                onChange={() => setLinkType("linkOfTarget")}
              />
            </Form.Group>
          )}

          {isNewAction && linkType === "link" && (
            <Form.Group controlId={`${actionKey}-link`}>
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Paste link here"
                value={action.link || ""}
                onChange={(e) =>
                  handleChange(actionKey, "link", e.target.value)
                }
              />
            </Form.Group>
          )}

          {isNewAction && linkType === "target" && (
            <Form.Group controlId={`${actionKey}-target-location`}>
              <InputGroup className="mb-3">
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  title={targetLocation || "Select Target Location"}
                  id="input-group-dropdown-1"
                >
                  {targetLocations.map((location, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => setTargetLocation(location)}
                    >
                      {location}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
            </Form.Group>
          )}

          {isNewAction && linkType === "linkOfTarget" && (
            <Form.Group controlId={`${actionKey}-link-of-target`}>
              <Form.Control
                type="text"
                placeholder="Paste target link here"
                value={targetLocation || ""}
                onChange={(e) => setTargetLocation(e.target.value)}
              />
            </Form.Group>
          )}
        </Form>
      )
    );
  };

  const toggleHidden = (actionKey) => {
    setHiddenActions((prevHiddenActions) => ({
      ...prevHiddenActions,
      [actionKey]: !prevHiddenActions[actionKey],
    }));
    
  };

  const actionItemClass = (actionKey) =>
    `d-flex justify-content-between align-items-center ${
      hiddenActions[actionKey] ? "hidden-action" : ""
    }`;

    const handleSave =()=>{
      setShowModal(true);
      setExpandedAction(null);
    }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Quick Actions
      </Button>

      <Modal
        show={showModal}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Quick Actions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "60vh", overflowY: "auto" }}>
          <ListGroup>
            {Object.keys(actions).map((actionKey) => (
              <React.Fragment key={actionKey}>
                <ListGroup.Item
                  className={actionItemClass(actionKey)}
                  action
                  onClick={() => handleExpand(actionKey, false)}
                >
                  <div>
                    <span
                      style={{
                        marginRight: "10px",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      {actions[actionKey].name}
                    </span>
                    <br />
                    <span className="text-muted">
                      {actions[actionKey].title}
                    </span>
                  </div>
                  <div style={{ float: "right" }}>
                    <FaEdit style={{ marginRight: "5px" }} />
                    {!actions[actionKey].default && (
                      <FaTrash
                        style={{
                          color: "red",
                          cursor: "pointer",
                          marginRight: "5px",
                        }}
                        onClick={() => deleteAction(actionKey)}
                      />
                    )}
                    {actions[actionKey].default && ( 
                      <FaEye onClick={() => toggleHidden(actionKey)} />
                    )}
                  </div>
                </ListGroup.Item>
                {actionInputs(actionKey)}
              </React.Fragment>
            ))}
            
          </ListGroup>
          <button
              style={{
                border: "2px dashed rgb(178, 175, 175)",
                textAlign: "center",
                padding: "12px",
                fontSize: "10pt",
                fontWeight: "bold",
                cursor: "pointer",
                width:"100%"
              }}
              onClick={addAction}
              className="mt-2"
            >
              Add Action
            </button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default QuickActionsModal;
